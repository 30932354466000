import React from 'react';

const Applications = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_85_56)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.75 8C11.75 10.0711 10.0711 11.75 8 11.75V13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3V4.25C10.0711 4.25 11.75 5.92893 11.75 8Z" fill="#495057"/>
      <circle cx="8" cy="8" r="7.375" stroke="#495057" strokeWidth="1.25"/>
    </g>
    <defs>
      <clipPath id="clip0_85_56">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default Applications;
