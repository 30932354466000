import React, { useState } from 'react';
import * as _ from 'lodash';
import { Button, Collapse } from 'react-bootstrap';
import { PartnerSummary } from '../../../api/queries/partners/usePartners';
import { CollapsableIcon } from '../../CollapsableIcon';
import useExpandableState from '../../../utils/useExpandableState';
import NewPartnerModal from '../../Modals/NewPartnerModal';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../../../pages/Search/NotebookTable';
import InlineSortableTh from '../../InlineSortableTh';

function PartnersTable ({ partners }: { partners: Array<PartnerSummary> }) {
  const [sortConfig, setSortConfig] = useState({ column: 'shortName', direction: 'asc' });

  const sortedPartners = _.orderBy(
    partners,
    [sortConfig.column],
    [sortConfig.direction as 'asc' | 'desc']
  );

  const handleSort = (column: string) => {
    setSortConfig(prevConfig => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return <NotebookTable>
    <NotebookTableHeader>
      <InlineSortableTh sortBy='shortName' onSort={() => handleSort('shortName')} sortConfig={sortConfig}>Name</InlineSortableTh>
      <InlineSortableTh sortBy='agentPricingPaymentType' onSort={() => handleSort('agentPricingPaymentType')} sortConfig={sortConfig}>Pricing Type</InlineSortableTh>
      <InlineSortableTh sortBy='waitListEnabled' onSort={() => handleSort('waitListEnabled')} sortConfig={sortConfig}>Live</InlineSortableTh>
      <InlineSortableTh sortBy='cardStatus' onSort={() => handleSort('cardStatus')} sortConfig={sortConfig}>Card Status</InlineSortableTh>
      <InlineSortableTh sortBy='agentCount' onSort={() => handleSort('agentCount')} sortConfig={sortConfig}>Agents #</InlineSortableTh>
      <InlineSortableTh sortBy='loanApplicationsCount' onSort={() => handleSort('loanApplicationsCount')} sortConfig={sortConfig}>Applications #</InlineSortableTh>
      <InlineSortableTh sortBy='loansCount' onSort={() => handleSort('loansCount')} sortConfig={sortConfig}>Loans #</InlineSortableTh>
    </NotebookTableHeader>

    <tbody>
    {
      sortedPartners.map(partner => (
        <NotebookTableRow key={partner._id} to={`/system/partners/${partner._id}/info`}>
          <td>{partner.shortName}</td>
          <td>{partner.agentPricingPaymentType || '-'}</td>
          <td>{partner.waitListEnabled ? 'No' : 'Yes'}</td>
          <td>{partner.cardStatus}</td>
          <td>{partner.agentCount}</td>
          <td>{partner.loanApplicationsCount}</td>
          <td>{partner.loansCount}</td>
        </NotebookTableRow>
      ))
    }
    </tbody>
  </NotebookTable>;
}

export default function Partners ({ partners, showAddNew }: { partners: Array<PartnerSummary>, showAddNew: boolean }) {
  const [open, setOpen] = useState(false);
  const newPartnerModal = useExpandableState();

  return (
    <div className='mb-4 d-flex flex-column'>
      {
        showAddNew && (
          <Button
            className='btn btn-primary ml-auto h-100 mt-n5 mb-2'
            onClick={ () => newPartnerModal.showExpand() }>
            Add partner
          </Button>
        )
      }
      <PartnersTable partners={partners.filter((partner) => partner.active)}/>

      <h5 onClick={() => setOpen(!open)} className='cursor-pointer ml-4'>
        Inactive Partners
        <CollapsableIcon open={open}/>
      </h5>
      <Collapse in={open}>
        <div>
          <PartnersTable partners={partners.filter((partner) => !partner.active)}/>
        </div>
      </Collapse>

      <NewPartnerModal
        expandable={newPartnerModal}
        partners={partners}
      />
    </div>
  );
}
