import React from 'react';
import VerificationBadge from '../VerificationBadge';
import { Col, Row } from 'react-bootstrap';
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../../../../api/queries/loan-applications/useUnderwritingFacts';
import InlineButton from '../../../InlineButton';
import { useExpandableState } from '../../../../utils';
import EditPropertyOwnershipFactModal, { PropertyOwnershipData } from '../../../Modals/EditPropertyOwnershipFactModal';
import { FactProps } from '../FactsTable';

export default function PropertyOwnedByFact ({ fact, showEditButton, onUpdate }: { fact: Fact } & FactProps) {
  const PropertyOwnedByModal = useExpandableState();

  return (
    <Row className='border-top py-3' data-qa='fact-row'>
      <Col sm={4} className='font-weight-bold pl-0'>{fact.human_name}</Col>
      <Col sm={4}>{fact.value}</Col>
      <Col sm={4} className='pr-0'>
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
        { showEditButton &&
          <InlineButton onClick={PropertyOwnedByModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
      { PropertyOwnedByModal.isExpanded &&
        <EditPropertyOwnershipFactModal
          expand={PropertyOwnedByModal}
          fact={fact as Fact<PropertyOwnershipData>}
          onSubmit={onUpdate}
          submitUrl='/property-owned-by'
        />
      }
    </Row>
  );
}
