import React, { useContext } from 'react';
import SystemLayout from '../../Layouts/SystemLayout';
import Partners from '../../../components/System/Partners/Partners';
import AdminContext from '../../../adminContext';
import useExpandableState from '../../../utils/useExpandableState';
import { Button } from 'react-bootstrap';
import NewPartnerModal from '../../../components/Modals/NewPartnerModal';
import { usePartners } from '../../../api/queries/partners/usePartners';
import { Header } from '../../Layouts/Layout';
import styles from './PartnersPage.module.scss';

export default function PartnersPage () {
  const adminContext = useContext(AdminContext);
  const partnersRequest = usePartners();
  const newPartnerModal = useExpandableState();

  if (!partnersRequest.data) {
    return null;
  }
  const partners = partnersRequest.data;

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
              <li className="breadcrumb-item">System</li>
              <li className="breadcrumb-item active" aria-current="page">Partners</li>
              <div className="d-flex ml-auto">
                <Button
                  className={`${styles.buttonLink} btn-sm`}
                  variant='outline-dark'
                  onClick={() => newPartnerModal.showExpand()}
                >
                  Add partner
                </Button>
              </div>
            </ol>
          </nav>
        </Header>
        <Partners partners={partners} showAddNew={false}/>

        <NewPartnerModal
          expandable={newPartnerModal}
          partners={partners}
        />
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='partners'>
        <Partners partners={partners} showAddNew={true}/>
      </SystemLayout>
    );
  }
}
