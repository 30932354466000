import React, { useContext } from 'react';
import useState from '../../../api/queries/states/useState';
import { useParams } from 'react-router-dom';
import StateShow from '../../../components/System/States/StateShow';
import SystemLayout from '../../Layouts/SystemLayout';
import AdminContext from '../../../adminContext';
import { Header } from '../../Layouts/Layout';

export default function StateShowPage () {
  const adminContext = useContext(AdminContext);
  const { stateId } = useParams<{ stateId: string }>();

  const statesRequest = useState(stateId);
  if (!statesRequest.data) {
    return null;
  }

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          {statesRequest.data.name}
        </Header>
        <StateShow state={statesRequest.data}/>
      </div>
    );

  } else {
    return (
      <SystemLayout selectedTab='states' heading={statesRequest.data.name}>
        <StateShow state={statesRequest.data}/>
      </SystemLayout>
    );
  }
}
