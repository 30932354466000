import React, { useState } from 'react';
import { Card, Col, Collapse, Row, Table } from 'react-bootstrap';
import { Partner, State, StateAprCap } from '../../../api/queries/states/useState';
import LabelValueGrouping from '../../LabelValueGrouping';
import formatDollars from '../../../utils/formatDollars';
import * as _ from 'lodash';
import { CollapsableIcon } from '../../CollapsableIcon';
import moment from 'moment';
import { Link } from 'react-router-dom';

const MAX_INT4 = 2147483647;

function StateAprCapTable ({ stateAprCaps, showCreatedAt }: { stateAprCaps: Array<StateAprCap>, showCreatedAt?: boolean }) {
  if (stateAprCaps.length === 0) {
    return <p>No Caps</p>;
  }

  return (
    <Table>
      <thead>
      <tr>
        <th>Min Loan Amount</th>
        <th>Max Loan Amount</th>
        <th>APR Cap</th>
        { showCreatedAt ? <th>Created At</th> : null }
      </tr>
      </thead>

      <tbody>
      {
        stateAprCaps.map((stateAprCap) => (
          <tr key={stateAprCap._id}>
            <td>{formatDollars(stateAprCap.minCreditLimitCents, { cents: true })}</td>
            <td>
              {
                stateAprCap.maxCreditLimitCents >= MAX_INT4 ?
                  '∞' :
                  formatDollars(stateAprCap.maxCreditLimitCents, { cents: true })
              }
            </td>
            <td>
              {
                stateAprCap.aprBpsCap >= MAX_INT4 ?
                  'No Cap' :
                  `${stateAprCap.aprBpsCap / 100}%`
              }
            </td>
            { showCreatedAt ? <td>{moment(stateAprCap.createdAt).format('lll')}</td> : null }
          </tr>
        ))
      }
      </tbody>
    </Table>
  );
}

function Partners ({ partners }: { partners: Array<Partner> }) {
  return <>
    {
      partners.map((partner, index) => (
        <span key={partner._id}>
          <Link to={`/system/partners/${partner._id}/info`}>{partner.name}</Link>
          {partners.length === index + 1 ? null : ', '}
        </span>
      ))
    }
  </>;
}

export default function StateShow ({ state }: { state: State }) {
  const [open, setOpen] = useState(false);

  const stateAprCaps = _.sortBy(state.stateAprCaps, ['minCreditLimitCents', 'createdAt']);

  const activeStateAprCaps = stateAprCaps.filter(cap => cap.active);
  const inactiveStateAprCaps = stateAprCaps.filter(cap => !cap.active);
  const inactiveStateAprCapsByDate = _.groupBy(inactiveStateAprCaps, cap => moment(cap.createdAt).format('lll'));

  return (
    <section className='mb-4 mt-4 px-4'>
      <Card>
        <Card.Header>
          State Info
        </Card.Header>

        <Card.Body>
          <Row>
            <Col>
              <LabelValueGrouping
                data={{
                  'Name': state.name,
                  'Code': state.code,
                  'Active Partners': <Partners partners={state.partners}/>,
                }}
                condensed={ true }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col>
          <Card className='mt-4'>
            <Card.Header>
              APR Caps by Loan Size
            </Card.Header>

            <Card.Body>
              <StateAprCapTable stateAprCaps={activeStateAprCaps}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <br/>
      <h4 onClick={() => setOpen(!open)} className='cursor-pointer'>
        Inactive Configs
        <CollapsableIcon open={open}/>
      </h4>
      <Collapse in={open}>
        <Row>
          <Col>
            <Card className='mt-4'>
              <Card.Header>
                Historical APR Caps by Loan Size
              </Card.Header>

              <Card.Body>
                {
                  Object.keys(inactiveStateAprCapsByDate).map(date => (
                    <StateAprCapTable
                      stateAprCaps={inactiveStateAprCapsByDate[date]}
                      showCreatedAt={true}
                      key={date}
                    />
                  ))
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Collapse>
    </section>
  );
}
