import React from 'react';
import { Card } from 'react-bootstrap';
import { Address } from '../../api/queries/users/useAddresses';
import StaticAddress from '../StaticAddress';
import InlineButton from '../InlineButton';
import useExpandableState from '../../utils/useExpandableState';
import EditMailingAddressModal from '../Modals/EditMailingAddressModal';

type MailingAddressCardProps = {
  applicationId: string;
  address?: Address;
  className?: string;
};

export default function MailingAddressCard ({ applicationId, address, className }: MailingAddressCardProps) {
  const editMailingAddressModal = useExpandableState();
  
  if (!address) { return null; }

  return (<>
    <Card className={className}>
      <Card.Header>
        Mailing Address
        <InlineButton onClick={ editMailingAddressModal.showExpand } className='float-right'>
          Edit
        </InlineButton>
      </Card.Header>
      <Card.Body>
        <StaticAddress address={address}/>
      </Card.Body>
    </Card>
    <EditMailingAddressModal
      expand={ editMailingAddressModal }
      onSubmit={() => window.location.reload()}
      applicationId={ applicationId }
      address={ address }
    />
  </>);
}
