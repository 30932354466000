import React, { useState } from 'react';
import { useExpandableState } from '../../../../utils';
import { ServicingActivity, ServicingActivityBaseType } from '../../../../api/queries/loans/useActivity';
import { putAPIEndpoint } from '../../../../utils/useAdminAPIData';
import ConfirmModal from '../../../Modals/ConfirmModal/ConfirmModal';
import { RefundDetailContent } from './RefundDetailContent';
import useRefundDetail from '../../../../api/queries/loans/useRefundDetail';
import { ModifyRefundRequestModal } from '../../../Modals/RefundRequestModal/ModifyRefundRequestModal';
import { TimelineListLoader } from '../TimelineTab';
import { LedgerImpactsLoader } from '../LedgerImpacts';

type Props = {
  loanId: string;
  activity: ServicingActivity;
  onChange: () => void;
};

export const RefundDetail: React.FC<Props> = ({ loanId, activity, onChange }) => {
  const [updating, setUpdating] = useState(false);
  const approveModal = useExpandableState();
  const cancelModal = useExpandableState();
  const editModal = useExpandableState();

  const refundId = activity.base_type === ServicingActivityBaseType.REFUND_REQUEST ? activity.base_id : activity.originating_request_id;
  const detailRequest = useRefundDetail(loanId, refundId);

  const handleConfirmApprove = async () => {
    approveModal.hideExpand();
    setUpdating(true);
    await putAPIEndpoint(`/notebook/loans/${ loanId }/refunds/${ refundId }/approve`, {});
    setUpdating(false);
    detailRequest.mutate();
    onChange();
  }

  const handleConfirmCancel = async () => {
    cancelModal.hideExpand();
    setUpdating(true);
    await putAPIEndpoint(`/notebook/loans/${ loanId }/refunds/${ refundId }/cancel`, {});
    setUpdating(false);
    detailRequest.mutate();
    onChange();
  }

  const handleConfirmEdit = async () => {
    editModal.hideExpand();
    detailRequest.mutate();
    onChange();
  }

  const handleApprove = async () => {
    approveModal.showExpand();
  };

  const handleCancel = async () => {
    cancelModal.showExpand();
  };

  const handleEdit = async () => {
    editModal.showExpand();
  }

  if (!detailRequest.data) {
    return <>Loading…</>;
  }

  return (
    <>
      <RefundDetailContent
        refund={ detailRequest.data }
        onCancel={ handleCancel }
        onApprove={ handleApprove }
        onEdit={ handleEdit }
        updating={ updating }
      />
      <TimelineListLoader
        scopeId={ loanId }
        entityId={ refundId }
      />
      <LedgerImpactsLoader
        sourceId={ refundId }
      />
      <ConfirmModal
        title='Please Confirm'
        expand={ approveModal }
        onConfirm={ handleConfirmApprove }
      >
        <p>Are you sure you want to approve and process this refund?</p>
      </ConfirmModal>
      <ConfirmModal
        title='Please Confirm'
        expand={ cancelModal }
        onConfirm={ handleConfirmCancel }
        confirmButtonVariant='danger'
      >
        <p>Are you sure you want to cancel this refund?</p>
      </ConfirmModal>
      <ModifyRefundRequestModal
        modal={ editModal }
        loanId={ loanId }
        refund={ detailRequest.data }
        onSubmit={ handleConfirmEdit }
      />
    </>
  );
};
