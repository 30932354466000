import React from 'react';
import VerificationBadge from '../VerificationBadge';
import { Col, Row } from 'react-bootstrap';
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../../../../api/queries/loan-applications/useUnderwritingFacts';
import InlineButton from '../../../InlineButton';
import { useExpandableState } from '../../../../utils';
import EditFicoFactModal from '../../../Modals/EditFicoFactModal';
import { FactProps } from '../FactsTable';

export type FicoFactData = {
  fico: string;
};

function FicoFact ({ fact, showEditButton, onUpdate }: { fact: Fact<FicoFactData> } & FactProps) {
  const editFactModal = useExpandableState();

  return (
    <Row className='border-top py-3' data-qa='fact-row'>
      <Col sm={4} className='font-weight-bold pl-0'>{fact.human_name}</Col>
      <Col sm={4}>{fact.value}</Col>
      <Col sm={4} className='pr-0'>
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
        {
          showEditButton &&
          <InlineButton onClick={editFactModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
      <EditFicoFactModal
        expand={editFactModal}
        fact={fact}
        onSubmit={onUpdate}
        submitUrl='/fico'
      />
    </Row>
  );
}

export default FicoFact;
