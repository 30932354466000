import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import Form from '../../../components/Form/Form';
import Field from '../../../components/Form/Field';
import ButtonWithSpinner from '../../../components/ButtonWithSpinner';
import { PartnerSummary } from '../../../api/queries/partners/usePartners';
import { ALL_US_STATES } from '../../../utils/constants';
import useMutateSimulator, { SimulatorResponse } from '../../../api/mutations/partners/useMutateSimulator';
import { Table } from 'react-bootstrap';
import { ProductConfigsTable } from '../../../components/System/Products/ProductShow';
import styles from './Simulator.module.scss';

type FieldValues = {
  partnerId: string;
  fico: string;
  homeState: string;
  listingState: string;
  homeMsa: string;
  listingMsa: string;
};

export default function Simulator ({ partners }: { partners: Array<PartnerSummary> }) {
  const schema = yup.object().shape({
    partnerId: yup.string().required('Please select a partner'),
    fico: yup.string().required('Please enter a FICO score'),
    homeState: yup.string().required('Please enter a home state'),
    listingState: yup.string().required('Please enter a listing state'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      partnerId: '',
      fico: '',
      homeState: '',
      listingState: '',
      homeMsa: '',
      listingMsa: '',
    },
    resolver: yupResolver(schema),
  });

  const { watch } = methods;
  const partnerId = watch('partnerId');
  const { mutate, isLoading } = useMutateSimulator(partnerId);
  const [products, setProducts] = useState<SimulatorResponse>();

  async function handleSubmit(data: FieldValues) {
    const response = await mutate({
      fico: data.fico,
      home_state: data.homeState,
      listing_state: data.listingState,
      home_msa: data.homeMsa,
      listing_msa: data.listingMsa,
    });
    setProducts(response.data);
  }

  return (
    <section className='mb-4 mt-4'>
      <div className={styles.simulatorForm}>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='partnerId' label='Partner' controlProps={{ as: 'select' }}>
            <option value='' disabled hidden>- Please select -</option>
            {
              partners.map(partner => (
                <option key={partner._id} value={partner._id}>{partner.shortName}</option>
              ))
            }
          </Field>
          <Field name='fico' label='FICO Score' placeholder='Enter fico score' controlProps={{ type: 'number' }} />
          <Field name='homeState' label='Home State' controlProps={{ as: 'select' }}>
            <option value='' disabled hidden>- Please select -</option>
            {
              ALL_US_STATES.map(state => (
                <option key={state.abbr} value={state.abbr}>{state.name}</option>
              ))
            }
          </Field>
          <Field name='listingState' label='Listing State' controlProps={{ as: 'select' }}>
            <option value='' disabled hidden>- Please select -</option>
            {
              ALL_US_STATES.map(state => (
                <option key={state.abbr} value={state.abbr}>{state.name}</option>
              ))
            }
          </Field>
          <Field name='homeMsa' label='Home MSA (optional)' placeholder='Enter msa' controlProps={{ type: 'number' }} />
          <Field name='listingMsa' label='Listing MSA (optional)' placeholder='Enter listing msa' controlProps={{ type: 'number' }} />
          <ButtonWithSpinner variant="primary" type="submit" loading={isLoading}>
            Run
          </ButtonWithSpinner>
        </Form>
      </div>

      <ConfigsSection products={products} />

    </section>
  );
}

function ConfigsSection ({ products }: { products?: SimulatorResponse }) {
  const product = products?.[0]; // Assuming there is only one product for now
  const [selectedPortfolio, setSelectedPortfolio] = useState<string>(product?.portfolios[0]?.key || '');

  if (!product) {
    return null;
  }

  return (
    <div className='mt-4'>
      <Table>
        <thead>
        <tr>
          <th>Portfolio</th>
          <th>Priority</th>
        </tr>
        </thead>

        <tbody>
        {
          product.portfolios.map(portfolio => (
            <tr
              key={portfolio.key}
              className={`cursor-pointer hover:shaded-grey ${portfolio.key === selectedPortfolio ? 'bg-blue' : ''}`}
              onClick={ () => setSelectedPortfolio(portfolio.key) }
            >
              <td>{portfolio.key}</td>
              <td>{portfolio.priority}</td>
            </tr>
          ))
        }
        </tbody>
      </Table>

      { selectedPortfolio &&
        <ProductConfigsTable
          productConfigs={product.portfolios.find(portfolio => portfolio.key === selectedPortfolio)?.configs || [] }
          creditMode={product.creditMode}
        />
      }
    </div>
  );
}
