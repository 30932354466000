import React, { useState } from 'react';
import VerificationBadge from '../VerificationBadge';
import formatDollars from '../../../../utils/formatDollars';
import { Col, Collapse, Row } from 'react-bootstrap';
import Decimal from 'decimal.js';
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../../../../api/queries/loan-applications/useUnderwritingFacts';
import InlineButton from '../../../InlineButton';
import EditAmountFactModal from '../../../Modals/EditAmountFactModal';
import { useExpandableState } from '../../../../utils';
import { CollapsableIcon } from '../../../CollapsableIcon';
import { FactProps } from '../FactsTable';

type Breakdown = {
  total_reported_cents: number;
  mortgage_cents_from_cr: number;
  mortgage_cents_from_source: number;
  other_liabilities_cents: number;
  excluded_debts_cents: number;
  deferred_student_loans_cents: number;
};

export type MonthlyDebtFactData = {
  value_in_cents: number;
  breakdown: Breakdown;
};

function LineItem ({ label, value }: { label: string, value: string }) {
  return (
    <Row>
      <Col sm={4} className='font-weight-bold'>{label}</Col>
      <Col sm={4}>{value}</Col>
    </Row>
  );
}

function TotalFromCreditReportLineItem ({ breakdown }: { breakdown: Breakdown }) {
  let totalReportedCents = new Decimal(breakdown.total_reported_cents);
  if (breakdown.mortgage_cents_from_cr) {
    totalReportedCents = totalReportedCents.minus(breakdown.mortgage_cents_from_cr);
  }

  return <LineItem label='Total From Credit Report' value={formatDollars(totalReportedCents, { cents: true })}/>
}

function BetterMortgageLineItem ({ breakdown }: { breakdown: Breakdown }) {
  const betterMortgageCents = Decimal.max(
    breakdown.mortgage_cents_from_cr || 0,
    breakdown.mortgage_cents_from_source,
  );

  return <LineItem label='Better Mortgage' value={formatDollars(betterMortgageCents, { cents: true })}/>;
}

function MonthlyDebtFact ({ fact, showEditButton, onUpdate }: { fact: Fact<MonthlyDebtFactData> } & FactProps) {
  const monthlyDebtModal = useExpandableState();
  const [open, setOpen] = useState(false);
  const breakdown = fact.data ? fact.data.breakdown : null;

  return ( <>
    <Row className='border-top py-3' data-qa='fact-row'>
      <Col sm={4} className='font-weight-bold cursor-pointer  pl-0' onClick={() => setOpen(!open)}>{fact.human_name}</Col>
      <Col sm={4} className='cursor-pointer' onClick={() => setOpen(!open)}>{fact.value}</Col>

      <Col sm={3} className='cursor-pointer pr-0' onClick={() => setOpen(!open)}>
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
        <CollapsableIcon open={open}/>
      </Col>
      <Col sm={1} className='pr-0'>
        { showEditButton &&
          <InlineButton onClick={monthlyDebtModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
      <EditAmountFactModal
        expand={monthlyDebtModal}
        fact={fact}
        onSubmit={onUpdate}
        submitUrl='/monthly-debt'
        admitsZero={true}
      />
    </Row>
    <Collapse in={open}>
      <div className='pt-1 pb-3' id='collapsable-breakdown'>
        {
          breakdown &&
            <>
              <TotalFromCreditReportLineItem breakdown={breakdown}/>
              <BetterMortgageLineItem breakdown={breakdown}/>
              <LineItem label='Other Liabilities' value={formatDollars(breakdown.other_liabilities_cents, { cents: true })}/>
              <LineItem label='Deferred Student Loans' value={formatDollars(breakdown.deferred_student_loans_cents, { cents: true })}/>
              <LineItem label='Excluded Debts' value={formatDollars(breakdown.excluded_debts_cents, { cents: true })}/>
            </>
        }
      </div>
    </Collapse>
    </>
  );
}

export default MonthlyDebtFact;
