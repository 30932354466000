import useQuery from "../../shared/useQuery";

export type Lockbox = {
  name: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
  };
};

export type InternalBankAccount = {
  _id: string;
  label: string;
  institution: string;
  lastFour: string;
  mainLockbox: Lockbox;
  expeditedLockbox: Lockbox;
  collectionPortfolios: Array<string>;
}

export function useInvestorInternalBankAccounts (investorKey: string) {
  return useQuery<Array<InternalBankAccount>>(`/notebook/investors/${ investorKey }/bank-accounts`);
}
