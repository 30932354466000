import React, { MouseEventHandler } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

export default function InlineSortableTh ({ children, sortBy, onSort, sortConfig }: {
  children: React.ReactNode,
  sortBy: string,
  onSort: MouseEventHandler,
  sortConfig: { column: string, direction: string },
}) {
  return <th onClick={onSort} className='cursor-pointer'>
    {children}
    <span>&nbsp;&nbsp;</span>
    {sortBy === sortConfig.column && sortConfig.direction === 'asc' && <ChevronUp/>}
    {sortBy === sortConfig.column && sortConfig.direction === 'desc' && <ChevronDown/>}
  </th>;
}
