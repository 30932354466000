import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { titleCase } from '../../../utils/string';
import { Portfolio, usePortfolios } from '../../../api/queries/investors/usePortfolios';
import AdminContext from '../../../adminContext';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../../Search/NotebookTable';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowInfoPage () {
  const adminContext = useContext(AdminContext);
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const portfolios = usePortfolios(investorKey);

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          {investorKey}
        </Header>
        <ShowInvestorLayout
          investorKey={ investorKey }
          selectedTab='portfolios'
        >
          { portfolios.data ? <div>
            <PortfoliosTable portfolios={portfolios.data} investorKey={investorKey} />
          </div> : <div>Loading…</div> }
        </ShowInvestorLayout>
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
        <ShowInvestorLayout
          investorKey={ investorKey }
          selectedTab='portfolios'
        >
          { portfolios.data ? <div>
            <PortfoliosTable portfolios={portfolios.data} investorKey={investorKey} />
          </div> : <div>Loading…</div> }
        </ShowInvestorLayout>
      </SystemLayout>
    );
  }
}

function PortfoliosTable ({ portfolios, investorKey }: { portfolios: Array<Portfolio>, investorKey: string }) {
  return <NotebookTable>
    <NotebookTableHeader>
      <th>Name</th>
    </NotebookTableHeader>
    <tbody>
      {
        portfolios.map(portfolio => (
          <NotebookTableRow key={portfolio.key} to={`/system/investors/${investorKey}/portfolios/${portfolio.key}`}>
            <td>{portfolio.name}</td>
          </NotebookTableRow>
        ))
      }
    </tbody>
  </NotebookTable>
}
