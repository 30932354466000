import React from 'react';

const Disbursements = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.00001 6.375C8.34519 6.375 8.62501 6.65482 8.62501 7V10.1578L9.22474 9.55806C9.46881 9.31398 9.86454 9.31398 10.1086 9.55806C10.3527 9.80214 10.3527 10.1979 10.1086 10.4419L8.44195 12.1086C8.19787 12.3527 7.80215 12.3527 7.55807 12.1086L5.8914 10.4419C5.64732 10.1979 5.64732 9.80214 5.8914 9.55806C6.13548 9.31398 6.53121 9.31398 6.77529 9.55806L7.37501 10.1578V7C7.37501 6.65482 7.65483 6.375 8.00001 6.375Z" fill="#495057"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.85517 5.65574C4.02195 4.72176 4.83425 4.04167 5.783 4.04167H10.217C11.1657 4.04167 11.978 4.72176 12.1449 5.6557L13.4053 12.7143C13.5831 13.7103 12.8175 14.625 11.8056 14.625H4.1944C3.18262 14.625 2.41685 13.7103 2.5947 12.7143L3.85517 5.65574C3.85517 5.65574 3.85517 5.65574 3.85517 5.65574ZM5.783 5.29167C5.43984 5.29167 5.14603 5.53766 5.0857 5.87548L3.82524 12.9341C3.78419 13.1639 3.96092 13.375 4.1944 13.375H11.8056C12.0391 13.375 12.2158 13.1639 12.1748 12.934L10.9143 5.87552C10.854 5.53765 10.5601 5.29167 10.217 5.29167H5.783Z" fill="#495057"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.708344 3.34392C0.708344 2.26118 1.58047 1.375 2.66668 1.375H13.3333C14.4196 1.375 15.2917 2.26119 15.2917 3.34392V6.03175C15.2917 6.35544 15.2717 6.75053 15.1263 7.10092C14.9617 7.49768 14.6471 7.81115 14.157 7.9383C13.8229 8.02499 13.4817 7.82441 13.395 7.49029C13.3084 7.15618 13.5089 6.81505 13.8431 6.72836C13.9013 6.71324 13.9228 6.69581 13.9299 6.68944C13.9379 6.68222 13.9536 6.66546 13.9717 6.62193C14.0159 6.51538 14.0417 6.33428 14.0417 6.03175V3.34392C14.0417 2.9422 13.7199 2.625 13.3333 2.625H2.66668C2.28013 2.625 1.95834 2.9422 1.95834 3.34392V6.03175C1.95834 6.33429 1.98412 6.51539 2.02832 6.62193C2.04638 6.66546 2.06207 6.68222 2.07014 6.68944C2.07724 6.69581 2.09868 6.71324 2.15697 6.72836C2.49108 6.81505 2.69167 7.15617 2.60498 7.49029C2.5183 7.82441 2.17717 8.02499 1.84305 7.9383C1.35296 7.81115 1.03833 7.49768 0.873732 7.10092C0.728366 6.75052 0.708344 6.35543 0.708344 6.03175V3.34392Z" fill="#495057"/>
  </svg>
);

export default Disbursements;
