import React from 'react';
import useAdminAPICall from '../utils/useAdminAPICall';
import OutboundButton from './OutboundButton';
import ComponentWithSpinner from './ComponentWithSpinner';

type Props = {
  kustomerId?: string;
  syncEndpoint: string;
  className?: string;
  block?: boolean;
  onSyncToKustomer: () => void;
}

export default function KustomerLink (props: Props) {
  const { kustomerId, syncEndpoint, onSyncToKustomer, className } = props;
  const { callAPI, loadPending } = useAdminAPICall({ endpoint: syncEndpoint, method: 'POST' });

  const onSyncClick = async () => {
    await callAPI();
    await onSyncToKustomer();
  }

  if (!kustomerId) {
    return (
      <ComponentWithSpinner
        component={OutboundButton}
        onClick={onSyncClick}
        loading={loadPending}
        className={className}
        size="sm"
      >
        Sync to Kustomer
      </ComponentWithSpinner>
    )
  } else return (
    <OutboundButton
      href={ `https://slatelending.kustomerapp.com/app/customers/${kustomerId}` }
      block={ props.block }
      className={className}
      size="sm"
    >
      Kustomer
    </OutboundButton>
  );
}

