import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { LegacyLoanApplication } from "../../../api/queries/users/useLoanApplications";
import { useExpandableState } from '../../../utils';
import InlineButton from '../../InlineButton';
import LabelValueGrouping from '../../LabelValueGrouping';
import EditAgentModal from '../../Modals/EditAgentModal';
import { useNavigate } from 'react-router-dom';
import styles from './AgentInfo.module.scss';

export default function AgentInfo ({ className, loanApplication }: { className: string, loanApplication: LegacyLoanApplication}) {
  const editAgent = useExpandableState();
  const navigate = useNavigate();
  const agent = loanApplication.agent;

  const dataForValueGrouping: Record<string, React.ReactNode> = {
    'Agent Name': <>
      <span
        className={`cursor-pointer ${styles.agentLink}`}
        onClick={() => navigate(`/agents/${agent?._id}`)}
      >
        {`${agent?.displayName}`}
      </span>
      <InlineButton onClick={editAgent.showExpand}>
        &nbsp;Edit
      </InlineButton>
    </>,
    'Agent Phone': agent?.phone,
    'Agent Email': agent?.email,
  };

  return (
    <>
      <Card className={className}>
        <Card.Header>
          Agent
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <LabelValueGrouping
                data = {dataForValueGrouping}
                condensed
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <EditAgentModal
        expand={editAgent}
        loanApplication={loanApplication}
        onSubmit={() => window.location.reload()}
      />
    </>
  );
}
