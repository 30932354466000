import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import { Fact } from '../../api/queries/loan-applications/useUnderwritingFacts';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { FicoFactData } from '../BorrowerTabs/UnderwritingTab/facts/FicoFact';
import { FactEditModalProps } from '../BorrowerTabs/UnderwritingTab/FactsTable';

type FieldValues = {
  fico: number;
};

type Props = {
  fact: Fact<FicoFactData>;
};

export default function EditFicoFactModal (props: Props & FactEditModalProps) {
  const { expand, onSubmit, fact, submitUrl } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${fact.loan_application_id}${submitUrl}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        fico: data.fico.toString(),
      },
    });
    await onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    fico: yup.number().required('Please enter a value'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      fico: Number(fact.data.fico),
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide} data-qa='edit-fact-modal'>
      <Modal.Header>
        <Modal.Title>
          {fact.human_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='fico' label='Value' controlProps={{ type: 'number' }}/>

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
