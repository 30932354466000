import React, { useContext } from 'react';
import AdminContext from '../adminContext';
import InlineButton, { Props } from './InlineButton';

/**
 * Inline-styled edit button that checks for admin permission
 * to edit system settings.
 * @param props - passthrough to InlineButton
 */
export default function EditSystemSettingsLink (props: Optional<Props, 'children'>) {
  const adminContext = useContext(AdminContext);
  if (!adminContext.canEditSystemSettings) {
    return null;
  }
  return (
    <InlineButton {...props}>
      { props.children ?? 'Edit' }
    </InlineButton>
  );
}
