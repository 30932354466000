import useQuery from '../../shared/useQuery';

export enum FLAG_STATUS {
  DENY = 'deny',
  DISREGARD = 'disregard',
  NEEDS_REVIEW = 'needs_review',
}

export type Flag<TData = unknown> = {
  _id: string;
  type: string;
  human_name: string;
  loan_application_id: string;
  value: string;
  verified: boolean;
  data: TData;
  flag_status: FLAG_STATUS;
  deny_reasons: Array<string>;
  deny_reasons_options_for_select: {
    [key: string]: string,
  };
}

export default function useUnderwritingFlags (loanApplicationId?: string) {
  return useQuery<Array<Flag>>(loanApplicationId ? `/notebook/loan-applications/${ loanApplicationId }/underwriting-flags` : null);
}
