import React from 'react';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import useExpandableState from '../../utils/useExpandableState';
import AddressFields from '../Form/AddressFields';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { Address } from '../../api/queries/users/useAddresses';

type FieldValues = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
};

type Props = {
  expand: ReturnType<typeof useExpandableState>;
  onSubmit: () => void;
  address: Address;
  applicationId: string;
};

export default function EditMailingAddressModal (props: Props) {
  const { expand, onSubmit, address, applicationId } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${ applicationId }/mailing-address`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        addressLine1: data.addressLine1.trim(),
        addressLine2: data.addressLine2.trim(),
        city: data.city.trim(),
        state: data.state.trim(),
        zip: data.zip.trim(),
      },
    });
    onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    addressLine1: yup.string().required('required'),
    addressLine2: yup.string().nullable(),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      addressLine1: address.address_line1,
      addressLine2: address.address_line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={ expand.isExpanded } onHide={ onModalHide }>
      <Modal.Header>
        <Modal.Title>
          Edit Mailing Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={ handleSubmit }>
          <AddressFields
            addressLine1FieldName='addressLine1'
            addressLine2FieldName='addressLine2'
            cityFieldName='city'
            stateFieldName='state'
            zipFieldName='zip'
          />
          <SubmitError/>

          <div className='d-flex justify-content-end mt-4'>
            <Button variant='secondary' onClick={ onModalHide } className='mr-2'>
              Cancel
            </Button>
            <ButtonWithSpinner variant='primary' type='submit' disabled={ !isDirty || isSubmitting } loading={ isSubmitting }>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
