import React from 'react';
import { Badge } from 'react-bootstrap';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import copy from 'clipboard-copy';
import styles from './EntityID.module.scss';
import ExplainerPopover from './ExplainerPopover';

const URL_MAPPING = {
  user: 'usersview',
  loan: false,
  loanApplication: false,
  purchaseMethod: 'purchasemethodsview',
};

type Props = {
  id: string;
  type?: keyof typeof URL_MAPPING;
  inline?: boolean;
  reveal?: boolean;
  link?: boolean;
};

type State = {
  timeout?: NodeJS.Timeout;
  isAnimating: boolean;
};

class EntityID extends React.Component<Props, State> {
  private _isMounted: boolean;

  constructor (props: Props) {
    super(props);
    this.state = {
      isAnimating: false
    };
    this._isMounted = false;
    this._copyHandler = this._copyHandler.bind(this);
    this._setIsAnimating = this._setIsAnimating.bind(this);
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  _copyHandler () {
    this._setIsAnimating(true);
    copy(this.props.id);
  }

  _setIsAnimating (isAnimating: boolean) {
    clearTimeout(this.state.timeout);
    let timeout;
    if (isAnimating) {
      timeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({ isAnimating: false });
        }
      }, 2000);
    }
    this.setState({ isAnimating, timeout });
  }

  render () {
    const { id, type, inline, reveal, link } = this.props;
    const entityPath = type ? URL_MAPPING[type] : null;

    let url;

    if (!entityPath && link !== false) {
      throw new Error(`Unsupported entity type ${type}`);
    } else if (link !== false) {
      url = `/admin/${entityPath}/edit/?id=${id}`;
    }

    const classes = [styles.scope, 'text-info text-monospace border border-info rounded'];
    if (inline) {
      classes.push(styles.inline);
    }

    if (reveal !== false) {
      classes.push(styles.reveal);
    }

    const Icon = this.state.isAnimating ? CheckCircleIcon : FileCopyIcon;

    return (
      <ExplainerPopover delay={ 2000 } explanation='Internal ID of the underlying record in the database.'>
        <Badge
          className         = { classes.join(' ') }
          onMouseEnter      = { () => this._setIsAnimating(false) }
          data-is-animating = { this.state.isAnimating }
        >
          <Icon
            className = {`${ styles.copyButton } mr-2`}
            fontSize  = 'inherit'
            onClick   = { this._copyHandler }
            role = 'button'
          />
          <a href={ url } className='text-info'>
            id<span className={ styles.details }>: {id}</span>
          </a>
        </Badge>
      </ExplainerPopover>
    );
  }
}

export default EntityID;
