import React from "react";
import { LOAN_APPLICATION_STATUSES } from "../../../utils/constants";
import UnderwritingFacts from "./UnderwritingFacts";
import { LegacyLoanApplication, UNDERWRITING_VERSIONS } from '../../../api/queries/users/useLoanApplications';
import UnderwritingSummaryV2 from './v2/UnderwritingSummary';
import UnderwritingRuns from './v4/UnderwritingRuns';
import { UNDERWRITING_RUN_OUTCOMES } from './constants';
import UnderwritingSummary from './UnderwritingSummary';
import useUnderwritingFacts from "../../../api/queries/loan-applications/useUnderwritingFacts";
import useUnderwritingFlags from "../../../api/queries/loan-applications/useUnderwritingFlags";
import useUnderwritingSummary from "../../../api/queries/loan-applications/useUnderwritingSummary";
import useLatestPreQualificationRun from "../../../api/queries/underwriting-runs/useLatestPreQualificationRun";
import useLatestStrictRun from "../../../api/queries/underwriting-runs/useLatestStrictRun";
import useLatest from "../../../api/queries/underwriting-decisions/useLatest";

export type Rule = {
  _id: string;
  category: string;
  notebookGroup: string;
  criteria: string;
  name: string;
  value: string;
  outcome: string;
  required: boolean;
}

export type Product = {
  name: string;
  maxCreditAmount: string;
  availableCreditAmount: string;
  requestedCreditAmount: string;
  selected: boolean;
  preQualDecision: string;
  strictDecision: string;
}

export type UnderwritingRun = {
  _id: string;
  loanApplicationId: string;
  confirmedAt: string;
  decisionDate: string;
  type: string;
  portfolioUnderwritingResults: Array<PortfolioUnderwritingResult>;
}

export type PortfolioUnderwritingResult = {
  _id: string;
  outcome: UNDERWRITING_RUN_OUTCOMES;
  product: PortfolioUnderwritingResultProduct;
  portfolio: PortfolioUnderwritingResultPortfolio;
  underwritingRules: Array<Rule>;
}

type PortfolioUnderwritingResultProduct = {
  _id: string;
  name: string;
}

type PortfolioUnderwritingResultPortfolio = {
  key: string;
  name: string;
}

export default function UnderwritingTab ({ loanApplication }: { loanApplication: LegacyLoanApplication }) {
  const factsReq = useUnderwritingFacts(loanApplication._id);
  const flagsReq = useUnderwritingFlags(loanApplication._id);
  const summaryReq = useUnderwritingSummary(loanApplication._id);
  const latestPrequalRunReq = useLatestPreQualificationRun(loanApplication._id);
  const latestFinalUWRunReq = useLatestStrictRun(loanApplication._id);
  const decisionReq = useLatest(loanApplication._id);

  const onUpdate = async () => {
    await Promise.all([
      factsReq.mutate(),
      flagsReq.mutate(),
      summaryReq.mutate(),
      latestPrequalRunReq.mutate(),
      latestFinalUWRunReq.mutate(),
      decisionReq.mutate(),
    ]);
  }

  return (
    <div>
      <UnderwritingSummary
        loanApplication={loanApplication}
        underwritingSummary={summaryReq.data}
        underwritingDecision={decisionReq.data}
      />
      <UnderwritingFacts
        allowFactsEdit={loanApplication.status === LOAN_APPLICATION_STATUSES.PENDING && !loanApplication.markDeniedAt}
        facts={factsReq.data}
        flags={flagsReq.data}
        onUpdate={onUpdate}
      />
      {
        loanApplication.underwritingVersion === UNDERWRITING_VERSIONS.V2 && (
          <UnderwritingSummaryV2 loanApplication={loanApplication} />
        )
      }
      {
        loanApplication.underwritingVersion === UNDERWRITING_VERSIONS.V4 && (
          <UnderwritingRuns
            latestPrequalRun={latestPrequalRunReq.data}
            latestFinalUWRun={latestFinalUWRunReq.data}
          />
        )
      }
    </div>
  );
}
