import React from 'react';
import LoanPartnerBadge from './Badges/LoanPartnerBadge';
import { formatDollars } from '../utils';
import { LOAN_APPLICATION_SOURCES, LOAN_APPLICATION_STATUSES, UNDERWRITING_OUTCOMES } from '../utils/constants';
import { Card } from 'react-bootstrap';
import { LegacyLoanApplication } from '../api/queries/users/useLoanApplications';
import LoanApplicationStatusBadge from './Badges/LoanApplicationStatusBadge';
import useLatest from '../api/queries/underwriting-decisions/useLatest';
import LoanApplicationUnderwritingDecisionBadge from './Badges/LoanApplicationUnderwritingDecisionBadge';
import { get } from 'lodash';
import LoanApplicationFromAgentBadge from './Badges/LoanApplicationFromAgentBadge';
import PausedBadge from './Badges/PausedBadge';
import { useNavigate } from 'react-router-dom';
import styles from './LoanSummaryCard.module.scss';
import POABadge from './Badges/POABadge';

type Props = {
  loanApplication: LegacyLoanApplication;
}

export default function LoanApplicationSummaryCard (props: Props) {
  const { loanApplication } = props;
  const navigate = useNavigate();
  const { data: underwritingDecision } = useLatest(loanApplication._id, loanApplication.status === LOAN_APPLICATION_STATUSES.CLOSED);
  const ineligibleLoanApp = underwritingDecision && underwritingDecision.outcome === UNDERWRITING_OUTCOMES.INELIGIBLE;
  const agentLoanApp = get(loanApplication,'underwritingResult.LOAN_APPLICATION_FROM_AGENT.failed', false);

  return (
    <Card>
      <Card.Body className='cursor-pointer p-3' onClick={() => navigate(`/loan-applications/${loanApplication._id}`)}>
        <div>
          <Card.Title as="span">
            { loanApplication.firstName } { loanApplication.lastName }
          </Card.Title>
        </div>
        <div className='d-flex align-items-center mt-1'>
          <div className={styles.badges}>
            <LoanPartnerBadge partnerId={ loanApplication.partner._id } source={loanApplication.isSourceBetter ? LOAN_APPLICATION_SOURCES.BETTER : LOAN_APPLICATION_SOURCES.STATED} />
            <LoanApplicationStatusBadge status={ loanApplication.status } />
            {ineligibleLoanApp &&
              <LoanApplicationUnderwritingDecisionBadge outcome={underwritingDecision.outcome} />
            }
            {agentLoanApp &&
              <LoanApplicationFromAgentBadge />
            }
            { loanApplication.isUserPaused && <PausedBadge/> }
            { loanApplication.isPOA && <POABadge /> }
          </div>
          <span className={`ml-2 ${styles.principal}`}>
            { loanApplication.principal ? `${formatDollars(loanApplication.principal, { precision: 0 })}` : '' }
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}
