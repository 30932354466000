import { numberFormat } from 'humanize';
import Decimal from 'decimal.js';
import validator from 'validator';

export function validateBoolean (rawValue: any) {
  return rawValue === true || rawValue === false;
}

export function validateSSN (rawValue: string) {
  return /^\d{3}-\d{2}-\d{4}$/.test(rawValue) && rawValue[0] !== '9';
}

export function cleanSSN (raw: string, lastFourOnly = false) {
  const digits = raw.replace(/[^\d]/g, '');
  let ssn = '';

  if (lastFourOnly) {
    return `***-**-${ digits.slice(0, 4) }`;
  }

  ssn += digits.slice(0, 3);
  if (digits.length > 3) ssn += '-';
  ssn += digits.slice(3, 5);
  if (digits.length > 5) ssn += '-';
  ssn += digits.slice(5, 9);

  return ssn;
}

// TODO: share this with web's copy in form-utilities.js
export function cleanDollarAmount (rawValue: Decimal.Value | null | undefined, pretty=false) {
  if (!rawValue && rawValue !== 0) {
    return '';
  }
  // Strip anything non-numeric (usually , or $)
  let _value: any = rawValue.toString().replace(/[^\d.-]+/g,'');

  // Grab the first decimal number up to two decimal places (to strip excess
  // fractional precision or decimal points). Allows negative values.
  _value = _value.match(/(^-{0,1}\d{0,})(\.\d{0,2})?/)
  if (_value) {
    let dollars = _value[1] ? parseInt(_value[1]).toString() : ''; // strip leading 0s
    let cents = _value[2] || '';
    if (cents && !dollars) {
      dollars = '0'; // add leading 0 for fraction-only values
    }
    if (pretty) {
      dollars = numberFormat(dollars, 0);
    }
    _value = `${dollars}${cents}`;
  } else {
    _value = '';
  }
  return _value;
}

export function validateDOB (rawValue: string) {
  const value = rawValue.trim();
  return isValidDOB(value);
}

function latestValidDOB () {
  const today = new Date();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();
  const latestValidYear = today.getFullYear() - 18;
  return new Date(latestValidYear, todayMonth, todayDay);
}

// Confirm that the value is the expected date format and
// at least 18 years in the past.
function isValidDOB (dobStr: string) {
  dobStr = dobStr.replace(/\s+/g,''); // Safari: remove spaces
  const dob = new Date(dobStr);
  if (!dobStr || isNaN(dob as any)) {
    return false;
  }
  // (Cannot simply subtract 18 years in milliseconds because of
  // leap years, etc.)
  const latestValid = latestValidDOB();
  return dob <= latestValid && dob.getFullYear() >= 1000;
}

const ZIP_REGEX = /^\d{5}(?:-\d{4})?$/;
export function validateZIPCode (rawValue: string) {
  const value = rawValue.trim();
  return ZIP_REGEX.test(value);
}

export function validateEmail (rawValue: string) {
  return validator.isEmail(rawValue);
}
