import useQuery from '../../shared/useQuery';

type Response = {
  due_date: string;
  issue_date: string;
  expiration_date: string;
  doc_type: string;
  loan_id: string;
  pdf_key: string;
  file_name: string;
  created_by_id: string;
  doc_link: string;
  is_expired: boolean;
  is_stale: boolean;
} | null;

export function useLatestInvoice (loanId: string) {
  return useQuery<Response>(`/notebook/loans/${ loanId }/invoices/latest`);
}
