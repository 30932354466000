import React, { useContext, useState } from 'react';
import SystemLayout from '../../Layouts/SystemLayout';
import useInvestors, { InvestorSummary, useInvestor } from '../../../api/queries/investors/useInvestors';
import { formatCents } from '../../../utils';
import AdminContext from '../../../adminContext';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../../Search/NotebookTable';
import { Header } from '../../Layouts/Layout';
import * as _ from 'lodash';
import InlineSortableTh from '../../../components/InlineSortableTh';

function InvestorRow ({ investor }: { investor: InvestorSummary }) {
  const details = useInvestor(investor.key);
  return (
    <NotebookTableRow key={investor.key} to={`/system/investors/${ investor.key }/info`}>
      <td>{ investor.key }</td>
      <td>{ investor.purchase_agreement_effective_date || '—' }</td>
      <td className='text-right'>
        { details.data ? (details.data.numLoans || '—') : '…' }
      </td>
      <td className='text-right'>
        {
          details.data
            ? (
              details.data.outstandingPrincipalCents
                ? formatCents(details.data.outstandingPrincipalCents)
                : '—'
            )
            : '…'
        }</td>
    </NotebookTableRow>
  );
}

function Investors ({ investors }: { investors: Array<InvestorSummary> }) {
  const [sortConfig, setSortConfig] = useState({ column: 'key', direction: 'asc' });
  const sortedInvestors = _.orderBy(
    investors,
    [sortConfig.column],
    [sortConfig.direction as 'asc' | 'desc']
  );
  const handleSort = (column: string) => {
    setSortConfig(prevConfig => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <NotebookTable>
      <NotebookTableHeader>
        <InlineSortableTh sortBy='key' onSort={() => handleSort('key')} sortConfig={sortConfig}>Investor Key</InlineSortableTh>
        <InlineSortableTh sortBy='purchase_agreement_effective_date' onSort={() => handleSort('purchase_agreement_effective_date')} sortConfig={sortConfig}>Purchase Agreement Date</InlineSortableTh>
        <th className='text-right'>Num Loans Purchased</th>
        <th className='text-right'>Outstanding Principal Balance</th>
      </NotebookTableHeader>
      <tbody>
      {
        sortedInvestors.map(investor => (
          <InvestorRow investor={ investor } key={ investor.key } />
        ))
      }
      </tbody>
    </NotebookTable>
  );
}

export default function InvestorsPage () {
  const adminContext = useContext(AdminContext);
  const investors = useInvestors();

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
              <li className="breadcrumb-item">System</li>
              <li className="breadcrumb-item active" aria-current="page">Investors</li>
            </ol>
          </nav>
        </Header>
        { investors.data ? <Investors investors={investors.data}/> : 'Loading…' }
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='investors'>
        { investors.data ? <Investors investors={investors.data}/> : 'Loading…' }
      </SystemLayout>
    );
  }
}
