import { CREDIT_MODE, Config } from '../../queries/products/useProduct';
import useMutation from '../../shared/useMutation';

export type SimulatorResponse = Array<Product>;

type Product = {
  _id: string;
  creditMode: CREDIT_MODE;
  portfolios: Array<Portfolio>;
}

type Portfolio = {
  _id: string;
  key: string;
  priority: number;
  configs: Array<Config>;
}

type Payload = {
  fico: string;
  home_state: string;
  listing_state: string;
  home_msa: string;
  listing_msa: string;
}

export default function useMutateSimulator (partnerId: string) {
  return useMutation<Payload, SimulatorResponse>('GET', `/notebook/partners/${partnerId}/simulator`);
}
