import useQuery from '../../shared/useQuery';

export type LoanList = Array<Loan>;

export type Loan = {
  _id: string;
  loanApplicationId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  principalCents: string;
  partnerShortName: string;
  createdAt: string;
  acceptedAt: string;
  authorizedAt: string;
  completedAt: string;
  expiredAt: string;
  repaidDate: string;
};

type SortOptions = {
  sortBy?: string;
  sortDirection?: string;
};

export default function useLoans (status?: string, options: SortOptions = {}) {
  const urlParams = new URLSearchParams(options);
  return useQuery<Array<Loan>>(status ? `/notebook/loans/${status}?${urlParams.toString()}` : null);
}
