import React from 'react';
import style from './UnderwritingTab.module.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Flag } from '../../../api/queries/loan-applications/useUnderwritingFlags';
import BaseFlag from './facts/BaseFlag';

function FlagRow ({ flag, onUpdate, showEditButton }: { flag: Flag, onUpdate: () => Promise<void>, showEditButton?: boolean }) {
  return <BaseFlag flag={flag} showEditButton={!!showEditButton} onUpdate={onUpdate} />;
}

function FlagsTable ({ title, description, flags, onUpdate, allowFactsEdit }: { title: string, description?: string, flags: Array<Flag>, onUpdate: () => Promise<void>, allowFactsEdit?: boolean }) {
  return (
    <Card className='mb-5'>
      <Card.Header>
        {title}
      </Card.Header>
      <Card.Body>
        { description && <p className={style.description}>{description}</p> }
        <div className='pt-2 px-3'>
          <Row className='pb-3'>
            <Col sm={4} className='font-weight-bold pl-0'>Fact</Col>
            <Col sm={4} className='font-weight-bold'>Value</Col>
            <Col sm={4} className='font-weight-bold'>Verification Status</Col>
          </Row>
          { flags.map(flag => <FlagRow key={flag.type} flag={flag} showEditButton={allowFactsEdit} onUpdate={onUpdate} />) }
        </div>
      </Card.Body>
    </Card>
  );
}

export default FlagsTable;
