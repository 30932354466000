import React, { useEffect, useState } from 'react';
import useExpandableState from '../../utils/useExpandableState';
import GenericModal from './GenericModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../Form/Form';
import SubmitError from '../Form/SubmitError';
import { Card, Col, Container, FormText, Row } from 'react-bootstrap';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { isTodayOrFutureDate } from '../../utils/datetime';
import Field from '../Form/Field';
import { formatCents } from '../../utils';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { Loan } from '../../api/queries/types';
import InlineLoadingIndicator from '../InlineLoadingIndicator';
import useMutateInvoiceTotals from '../../api/mutations/useMutateInvoiceTotals';
import { formatDateCommon } from '../../utils/formatDate';
import { useLatestInvoice } from '../../api/queries/invoices/useInvoices';
import ExplainerPopover from '../ExplainerPopover';
import payoffStatementIcon from '../../images/payoff-statement-icon.png';

type FieldValues = {
  payoffDate: string;
};

type Props = {
  expandable: ReturnType<typeof useExpandableState>;
  loan: Loan;
  onSubmit: () => void;
};

function downloadFile (filename: string, dataBlob: Blob) {
  const el = document.createElement('a');
  el.href = window.URL.createObjectURL(dataBlob);
  el.download = filename;
  el.target = '_blank';
  el.click();
}

function GenerateInvoiceModalContent (props: Props) {
  const { expandable, loan } = props;
  const [totalsLoading, setTotalsLoading] = useState(false);
  const {
    data: invoiceTotals,
    mutate: mutateInvoiceTotals,
    isLoading: loadPending,
  } = useMutateInvoiceTotals(loan._id);

  const downloadInvoiceReq = useAdminAPICall({
    endpoint: `/notebook/loans/${ loan._id }/invoices`,
    method: 'POST',
    onSuccess: (data) => {
      downloadFile(`${ loan.partner.brand_name } Payoff Statement - Loan ID ${ loan.slug }.pdf`, data);
    },
  });

  const existingInvoiceReq = useLatestInvoice(loan._id);
  const [generateNewInvoice, setGenerateNewInvoice] = useState(false);

  const schema = yup.object().shape({
    payoffDate: yup.string().test('payoffDate', 'Please enter a valid payoff date.', value => !value || isTodayOrFutureDate(value)),
  });

  const form = useForm<FieldValues>({
    defaultValues: {
      payoffDate: '',
    },
    resolver: yupResolver(schema),
  });

  const watchPayoffDate = form.watch('payoffDate');
  useEffect(() => {
    setTotalsLoading(true);
    mutateInvoiceTotals({ payoffDate: watchPayoffDate })
    setTimeout(() => { setTotalsLoading(false); }, 1000);
  }, [watchPayoffDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (form: FieldValues) => {
    await downloadInvoiceReq.callAPI({
      responseType: 'blob',
      data: {
        type: 'invoice',
        payoffDate: form.payoffDate,
      },
    });
    expandable.hideExpand();
  };

  const loading = totalsLoading || loadPending || !invoiceTotals || existingInvoiceReq.isLoading;

  if (!generateNewInvoice && existingInvoiceReq.data) {
    return (
      <Container className='p-3'>
        <Row>
          <Col>
            <p>
              A payoff statement already exists, view it below.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-3">
              <Card.Body className="bg-grey">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-1 mr-3">
                    <img style={{ width: 50 }} src={ payoffStatementIcon } alt="" />
                  </div>
                  <div className="flex-grow-1">
                    <strong>Payoff Statement</strong><br />
                    {
                      existingInvoiceReq.data!.is_expired ? (
                        <ExplainerPopover explanation="The quoted payoff date and amount is in the past and no longer valid.">
                          <span className="text-danger">Expired</span>
                        </ExplainerPopover>
                      ) : existingInvoiceReq.data!.is_stale ? (
                        <ExplainerPopover explanation="New ledger activity has occurred since the payoff statement was generated.">
                          <span className="text-danger">Stale</span>
                        </ExplainerPopover>
                      ) : (
                        <span>Due date { formatDateCommon(existingInvoiceReq.data!.due_date) }</span>
                      )
                    }
                    <br />
                    <span className="text-muted small">Issued on { formatDateCommon(existingInvoiceReq.data.issue_date) }</span>
                  </div>
                  <a href={ existingInvoiceReq.data.doc_link } target='_blank' rel='noreferrer'>
                    View
                  </a>
                </div>

              </Card.Body>

            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonWithSpinner variant='outline-primary' loading={ false } block onClick={ () => setGenerateNewInvoice(true) }>
              Generate New Payoff Statement
            </ButtonWithSpinner>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Form { ...form } onSubmit={ handleSubmit }>
      <Container className='p-3'>
        <Row>
          <Col>
            <Field name='payoffDate' label='Payoff Date' controlProps={{ type: 'date' }} className='m-0'/>
            <FormText>
              Set an alternate payoff date to use for future interest
              calculations instead of the maturity date.
            </FormText>
          </Col>
        </Row>
      </Container>
      <Container className='border-top border-bottom p-3 bg-light'>
        { loading ? <InlineLoadingIndicator /> :
          <>
            <Row>
              <Col>Due Date</Col>
              <Col className='text-right'>{ formatDateCommon(invoiceTotals!.dueDate) }</Col>
            </Row>
            <Row>
              <Col>Principal</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.creditUsageCents, '—') }</Col>
            </Row>
            <Row>
              <Col>Interest</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.accruedInterestCents + invoiceTotals!.totalExpectedFutureInterestCents, '—') }</Col>
            </Row>
            <Row>
              <Col>Fees</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.feesCents, '—') }</Col>
            </Row>
            <Row className='font-weight-bold'>
              <Col>Total Payoff</Col>
              <Col className='text-right'>{ formatCents(invoiceTotals!.payoffAmountCents, '—') }</Col>
            </Row>
          </>
        }
        <Row>
          <Col>
            <SubmitError/>
          </Col>
        </Row>
      </Container>
      <Container className='d-block p-3'>
        <ButtonWithSpinner block variant='primary' type='submit' disabled={ loading } loading={ downloadInvoiceReq.loadPending }>
          Generate Payoff Statement
        </ButtonWithSpinner>
      </Container>
    </Form>
  )
}

export default function GenerateInvoiceModal (props: Props) {
  const { expandable } = props;
  return (
    <GenericModal expandable={ expandable } title='Generate Payoff Statement' bodyClass='p-0'>
      { expandable.isExpanded && <GenerateInvoiceModalContent {...props} /> }
    </GenericModal>
  );
}
