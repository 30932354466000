import useQuery from '../../shared/useQuery';
import { DisbursementRequest } from './usePending';

type SortOptions = {
  sortBy?: string;
  sortDirection?: string;
};

export default function useDisbursementRequests (status?: string, options: SortOptions = {}) {
  const urlParams = new URLSearchParams(options);
  return useQuery<Array<DisbursementRequest>>(status ? `/notebook/disbursement-requests/v2/${status}?${urlParams.toString()}` : null);
}
