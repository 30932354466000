import React from 'react';
import { Flag } from '../../../../api/queries/loan-applications/useUnderwritingFlags';
import { useExpandableState } from '../../../../utils/index';
import EditUnderwritingFlagModal from '../../../Modals/EditUnderwritingFlagModal';
import { Col, Row } from 'react-bootstrap';
import VerificationBadge from '../VerificationBadge';
import { VERIFICATION_RESULTS } from '../constants';
import InlineButton from '../../../InlineButton';
import CreditReportCollectionsFlagDetails, {
  CreditReportCollectionsFlagData
} from './flagDetails/CreditReportCollectionsFlagDetails';
import CreditReportChargeoffFlagDetails, {
  CreditReportChargeoffFlagData
} from './flagDetails/CreditReportChargeoffFlagDetails';
import ExcessiveNewTradelinesFlagDetails, {
  ExcessiveNewTradelinesFlagData
} from './flagDetails/ExcessiveNewTradelinesFlagDetails';
import MatchingIpInAnotherApplicationFlagDetails, {
  MatchingIpInAnotherApplicationFlagData
} from './flagDetails/MatchingIpInAnotherApplicationFlagDetails';
import SameListingAddressFlagsDetails, {
  SameListingAddressFlagsData
} from './flagDetails/SameListingAddressFlagDetails';
import SettledTradelinesFlagDetails, { SettledTradelinesFlagData } from './flagDetails/SettledTradelinesFlagDetails';
import AbuseScoreFlagDetails, { AbuseScoreFlagData } from './flagDetails/AbuseScoreFlagDetails';
import IdTheftScoreFlagDetails, { IdTheftScoreFlagData } from './flagDetails/IdTheftScoreFlagDetails';
import PreviousNonPerformingLoansFlagDetails, { PreviousNonPerformingLoansFlagData } from './flagDetails/PreviousNonPerformingLoansFlagDetails';

function BaseFlag ({ flag, showEditButton, onUpdate }: { flag: Flag, showEditButton: boolean, onUpdate: () => Promise<void> }) {
  const editModal = useExpandableState();
  const values = flag.value?.split('\n') ?? [];

  return (
    <>
      <Row className='border-top pt-3' data-qa='fact-row'>
        <Col sm={4} className='font-weight-bold pl-0'>{flag.human_name}</Col>
        <Col sm={4}>
          {
            values.length === 1 ?
              values[0] :
              values.map(val => <Row key={val} className='pl-3'>{val}</Row>)
          }
        </Col>
        <Col sm={showEditButton ? 3 : 4}>
          <VerificationBadge
            verificationResult={flag.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          />
        </Col>
        <Col sm={1} className='pr-0'>
          {
            showEditButton && editModal &&
            <InlineButton onClick={editModal.showExpand} className='float-right'>
              Edit
            </InlineButton>
          }
        </Col>
      </Row>
      <Row className='pb-3'>
        <FlagDetails flag={flag}/>
      </Row>
      <EditUnderwritingFlagModal
        expand={editModal}
        flag={flag}
        onSubmit={onUpdate}
      />
    </>
  );
}

function FlagDetails ({ flag }: { flag: Flag }) {
  if (flag.type === 'credit_report_collections_flag') {
    return <CreditReportCollectionsFlagDetails flag={flag as Flag<CreditReportCollectionsFlagData>}/>;
  } else if (flag.type === 'charge_off_debt_flag') {
    return <CreditReportChargeoffFlagDetails flag={flag as Flag<CreditReportChargeoffFlagData>}/>;
  } else if (flag.type === 'new_tradelines_flag_fact') {
    return <ExcessiveNewTradelinesFlagDetails flag={flag as Flag<ExcessiveNewTradelinesFlagData>}/>;
  } else if (flag.type === 'matching_ip_in_another_application_flag') {
    return <MatchingIpInAnotherApplicationFlagDetails flag={flag as Flag<MatchingIpInAnotherApplicationFlagData>}/>;
  } else if (flag.type === 'duplicate_listing_address_flag' || flag.type === 'reapplying_with_higher_list_price_flag') {
    return <SameListingAddressFlagsDetails flag={flag as Flag<SameListingAddressFlagsData>} />
  } else if (flag.type === 'settled_tradelines_flag') {
    return <SettledTradelinesFlagDetails flag={flag as Flag<SettledTradelinesFlagData>}/>;
  } else if (flag.type === 'abuse_score_flag') {
    return <AbuseScoreFlagDetails flag={flag as Flag<AbuseScoreFlagData>}/>;
  } else if (flag.type === 'id_theft_score_flag') {
    return <IdTheftScoreFlagDetails flag={flag as Flag<IdTheftScoreFlagData>}/>;
  } else if (flag.type === 'previous_non_performing_loans_flag') {
    return <PreviousNonPerformingLoansFlagDetails flag={flag as Flag<PreviousNonPerformingLoansFlagData>}/>;
  } else {
    return null;
  }
}

export default BaseFlag;
