import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InlineError } from '../../components';
import ApplicationAndLoanSummaryCards from '../../components/ApplicationAndLoanSummaryCards';
import InlineWarning from '../../components/InlineWarning';
import useLoanApplications from '../../api/queries/agents/useLoanApplications';
import useLoansSummaries from '../../api/queries/agents/useLoansSummaries';
import useAgent from '../../api/queries/agents/useAgent';
import AdminContext from '../../adminContext';
import { Header } from '../Layouts/Layout';
import formatPhone from '../../utils/formatPhone';
import { AgentHubspotSyncSwitch, AgentLandingPageCell } from '../System/Partners/PartnerShowAgentsPage';
import { Agent } from '../../api/queries/agents/useAgents';
import { mutate } from 'swr';
import { usePartner } from '../../api/queries/partners/usePartner';
import styles from './AgentShowPage.module.scss';
import KustomerLink from '../../components/KustomerLink';

export default function AgentShowPage () {
  const adminContext = useContext(AdminContext);
  const { agentId } = useParams<{ agentId: string }>();
  const agentReq = useAgent(agentId);
  const loanApplicationsReq = useLoanApplications(agentId);
  const loansReq = useLoansSummaries(agentId);

  useEffect(() => {
    if (agentReq.data && agentReq.data.firstName) {
      document.title = `${agentReq.data.firstName} ${agentReq.data.lastName} | Notebook`
    }
  }, [agentReq.data]);

  if (agentReq.error) {
    if (agentReq.error?.request?.status === 404) {
      return <InlineWarning>No such Agent. Check the ID in the URL.</InlineWarning>;
    }
    return <InlineError>
      { agentReq.error.request ? `(${ agentReq.error.request.status })` : null } Error loading agent details.
    </InlineError>;
  }

  const agent = agentReq.data;
  if (!agent) {
    return <div className='text-muted'>Loading…</div>;
  }

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage'>
        <Header>
          <div className='d-flex align-items-center'>
            <div>
              {`${agent.firstName} ${agent.lastName}`}
            </div>
            <div className='ml-auto'>
              <KustomerLink
                block={ true }
                kustomerId={agent.kustomerId}
                syncEndpoint={`/notebook/agents/${agent._id}/sync-kustomer-profile`}
                onSyncToKustomer={ agentReq.mutate }
                className={`${styles.buttonLink} btn-link border-0 bg-transparent`}
              />
            </div>
          </div>
        </Header>
        <div className='d-flex'>
          <div className='flex-grow-1 mb-5'>
            <ApplicationAndLoanSummaryCards loanApplicationsReq={loanApplicationsReq} loansReq={loansReq} />
          </div>
          <AgentDetails agent={agent} />
        </div>
      </div>
    );
  } else {
    return (
      <div className='pt-2'>
        <div className='d-flex'>
          <h4 className='m-0'>{`${agent.firstName} ${agent.lastName}`}</h4>
        </div>
        <hr/>
        <ApplicationAndLoanSummaryCards loanApplicationsReq={loanApplicationsReq} loansReq={loansReq} />
      </div>
    );
  }
}

function AgentDetails ({ agent }: { agent: Agent }) {
  const onChange = () => mutate(`/notebook/agents/${agent._id}`);
  const { data: partner } = usePartner(agent.partnerId);
  if (!partner) { return null; }

  return (
    <div className={`p-4 ${styles.agentDetail}`}>
      <AgentDetailItem label='Primary Phone' value={formatPhone(agent.primaryPhone) || '--'} />
      <AgentDetailItem label='Secondary Phone' value={formatPhone(agent.secondaryPhone) || '--'} />
      <AgentDetailItem label='Email' value={agent.email} />
      <AgentDetailItem label='Partner' value={partner.name} />
      <AgentDetailItem
        label='Landing Page'
        value={
          <AgentLandingPageCell
            agent={ agent }
            partner={ partner }
            onChange={onChange}
          />
        }
      />
      <AgentDetailItem
        label='Sync to Hubspot'
        value={
          <AgentHubspotSyncSwitch
            agent={ agent }
            onChange={onChange}
            onError={() => {}}
          />
        }
      />
    </div>
  );
}

function AgentDetailItem ({ label, value }: { label: string, value: React.ReactNode }) {
  return (
    <div className='mb-4'>
      <div className='text-muted mb-1'>{label}</div>
      <span className='text-break'>{value}</span>
    </div>
  );
}
