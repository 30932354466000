import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './TabLayout.module.scss';

function TabContentLayout ({ heading, children }: { heading?: string, children: React.ReactNode }) {
  return (
    <>
      {
        heading && (
          <div className='mt-3 px-4'>
            <h3>{heading}</h3>
          </div>
        )
      }
      {children}
    </>
  );
}

type TabNames = Record<string, string | null>;

export default function TabLayout ({
  tabs,
  id,
  selectedTab,
  children,
  navigateTo,
  heading,
}: {
  id: string;
  tabs: TabNames,
  selectedTab: keyof TabNames,
  children: React.ReactNode,
  heading?: string,
  navigateTo: (selected: string) => string
}) {
  const navigate = useNavigate();

  return (
    <div>
      <Tabs
        className={`mx-0 px-4 custom-tabs sticky-top ${styles.tabs}`}
        defaultActiveKey={selectedTab}
        id={ id }
        onSelect={(selected: string) => navigate(navigateTo(selected), { replace: true }) }
      >
        {
          Object.entries(tabs).map(([key, value]) => (
            value ?
              <Tab key={key} eventKey={key} title={value} tabClassName='pb-3'>
                { key === selectedTab && <TabContentLayout heading={heading}>
                  {children}
                </TabContentLayout> }
              </Tab>
            : null
          ))
        }
      </Tabs>
    </div>
  );
}
