import React from 'react';
import { useExpandableState } from '../../../utils';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import { PanelWithActions, MainPanel, SecondaryPanel } from '../../ExpandableCard';
import { LOAN_STATES, PURCHASE_METHODS } from '../../../utils/constants';
import InlineError from '../../InlineError';
import AchModal from '../../Modals/AchModal';
import Cards from './Cards';
import BankAccounts from './BankAccounts';
import { Loan } from '../../../api/queries/types';
import { PurchaseMethod } from '../../../utils/transactionUtils';

export default function CardsBankAccountsContent ({ loan, purchaseMethodsReq }: { loan: Loan, purchaseMethodsReq: any }) {
  const createAchModal = useExpandableState();
  const purchaseMethods = purchaseMethodsReq.loadSuccess ? purchaseMethodsReq.data.slice() : [];

  const isCard = (purchaseMethod: PurchaseMethod) => (
    purchaseMethod.type === PURCHASE_METHODS.VIRTUAL_CARD || purchaseMethod.type === PURCHASE_METHODS.PHYSICAL_CARD
  )
  const cards = purchaseMethods.filter(isCard);
  const bankAccounts = purchaseMethods.filter((purchaseMethod: PurchaseMethod) => !isCard(purchaseMethod));

  // Accounts may be added post-funding to support repayments or refunds.
  const canAddBankAccount = [
    LOAN_STATES.AUTHORIZED,
    LOAN_STATES.FUNDED,
    LOAN_STATES.COMPLETED,
    LOAN_STATES.REPAID,
  ].includes(loan.status);

  const canReissueCards = [
    LOAN_STATES.AUTHORIZED,
    LOAN_STATES.FUNDED,
  ].includes(loan.status);

  return (
    <>
      <PanelWithActions>
        <MainPanel>
          <Row className='mb-5'>
            <Col>
              <h4 className='mb-3'>Cards</h4>
              <Cards
                cards={ cards }
                purchaseMethodsReq={ purchaseMethodsReq }
                userId={ loan.userId }
                allowReissue={ canReissueCards }
              />
            </Col>
          </Row>
        </MainPanel>
      </PanelWithActions>
      <PanelWithActions>
        <MainPanel>
          <Row className='mb-5 mr-3'>
            <Col>
              <h4 className='mb-3'>Bank Accounts</h4>
              {
                purchaseMethodsReq.loadPending ? (
                  'Loading...'
                ) : purchaseMethodsReq.loadError ? (
                  <InlineError>Error loading bank accounts</InlineError>
                ) : bankAccounts.length > 0 ? (
                  <BankAccounts
                    bankAccounts={ bankAccounts }
                    loan={ loan }
                    onChange={ purchaseMethodsReq.loadData }
                  />
                ) : null
              }
            </Col>
          </Row>
        </MainPanel>
        <SecondaryPanel>
          <div>
            { canAddBankAccount && (
                <ButtonGroup vertical className='d-block mb-3'>
                  <Button variant='secondary' block={ true } onClick={ createAchModal.showExpand }>
                    Add Bank Account
                  </Button>
                  <AchModal modal={createAchModal} loan={loan} onChange={purchaseMethodsReq.loadData} />
                </ButtonGroup>
              )
            }
          </div>
        </SecondaryPanel>
      </PanelWithActions>
    </>
  );
}
