import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import { useInvestor } from '../../../api/queries/investors/useInvestors';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { LabelValueGrouping } from '../../../components';
import { titleCase } from '../../../utils/string';
import AdminContext from '../../../adminContext';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowInfoPage () {
  const adminContext = useContext(AdminContext);
  const params = useParams<{ investorKey: string }>();
  const investorKey = params.investorKey as string;
  const investor = useInvestor(investorKey);

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          {investorKey}
        </Header>
        <ShowInvestorLayout
          investorKey={ investorKey }
          selectedTab='info'
        >
          { investor.data ? <div className='px-4'>
            <LabelValueGrouping condensed data={investor.data} />
          </div> : <div className='px-4'>Loading…</div> }
        </ShowInvestorLayout>
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
        <ShowInvestorLayout
          investorKey={ investorKey }
          selectedTab='info'
        >
          { investor.data ? <div>
            <LabelValueGrouping condensed data={investor.data} />
          </div> : <div>Loading…</div> }
        </ShowInvestorLayout>
      </SystemLayout>
    );
  }
}
