import React from 'react';
import { UnderwritingRun } from '../index';
import { Card } from 'react-bootstrap';
import PortfolioUnderwritingResults from './PortfolioUnderwritingResults';

function UnderwritingCard ({ title, children }: { title: string, children: React.ReactNode }) {
  return <Card className="mt-4">
    <Card.Header>
      {title}
    </Card.Header>
    <Card.Body className='pb-0'>
      {children}
    </Card.Body>
  </Card>;
}

function UnderwritingRunSummary ({ underwritingRun, title }: { underwritingRun: UnderwritingRun, title: string }) {
  return <div className={`mb-5 ${underwritingRun.type}`}>
    <div className="border-bottom">
      <h4>{title}</h4>
    </div>
    <UnderwritingCard title="Portfolio Evaluations">
      <PortfolioUnderwritingResults portfolioUnderwritingResults={underwritingRun.portfolioUnderwritingResults || []} />
    </UnderwritingCard>
  </div>;
}

export default function UnderwritingRuns ({
  latestFinalUWRun,
  latestPrequalRun,
}: {
  latestFinalUWRun: UnderwritingRun | undefined,
  latestPrequalRun: UnderwritingRun | undefined,
}) {
  return (
    <>
      {
        latestFinalUWRun && <UnderwritingRunSummary underwritingRun={latestFinalUWRun} title='Final Underwriting' />
      }
      {
        latestPrequalRun && <UnderwritingRunSummary underwritingRun={latestPrequalRun} title='Pre Qualification' />
      }
    </>
  );
}
