import React from 'react';
import { Badge } from 'react-bootstrap';
import { Portfolio } from '../../api/queries/partners/usePortfolios';

export default function LoanPortfolioBadge ({ portfolio }: { portfolio: Portfolio }) {
  const style = {
    backgroundColor: '#495057'
  };

  return (
    <Badge pill variant={ 'primary' } style={ style }>
      { `${portfolio.name} Portfolio` }
    </Badge>
  );
}
