import React, { useState } from 'react';
import { PaymentDetailContent } from './PaymentDetailContent';
import { useExpandableState } from '../../../../utils';
import usePaymentDetail from '../../../../api/queries/loans/usePaymentDetail';
import ConfirmModal from '../../../Modals/ConfirmModal/ConfirmModal';
import { putAPIEndpoint } from '../../../../utils/useAdminAPIData';
import { ServicingActivity } from '../../../../api/queries/loans/useActivity';
import { TimelineListLoader } from '../TimelineTab';
import { LedgerImpactsLoader } from '../LedgerImpacts';

type Props = {
  loanId: string;
  activity: ServicingActivity;
  onChange: () => void;
};

export const PaymentDetail: React.FC<Props> = ({ loanId, activity, onChange }) => {
  const [updating, setUpdating] = useState(false);
  const actionModal = useExpandableState();

  // For scheduled payments, base_id is the payment ID and originating_request_id is undefined.
  // For queued (and beyond) payments, originating_request_id is the payment ID.
  const operativeId = activity.originating_request_id || activity.base_id;

  const detailRequest = usePaymentDetail(loanId, operativeId);

  const handleConfirmCancel = async () => {
    actionModal.hideExpand();
    setUpdating(true);
    await putAPIEndpoint(`/notebook/loans/${loanId}/payment/${operativeId}/cancel`, {});
    setUpdating(false);
    detailRequest.mutate();
    onChange();
  }

  const handleCancel = async () => {
    actionModal.showExpand();
  };

  if (!detailRequest.data) {
    return null;
  }

  return (
    <>
      <PaymentDetailContent
        data={detailRequest.data}
        onCancel={handleCancel}
        loading={updating}
      />
      <TimelineListLoader
        scopeId={ loanId }
        entityId={ operativeId }
      />
      <LedgerImpactsLoader
        sourceId={ operativeId }
      />
      <ConfirmModal
        title="Please Confirm"
        expand={actionModal}
        onConfirm={handleConfirmCancel}
        confirmButtonVariant="danger"
      >
        <p>Are you sure you want to cancel this ACH Repayment?</p>
      </ConfirmModal>
    </>
  );
};
