import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import SystemLayout from '../../Layouts/SystemLayout';
import ShowInvestorLayout from '../../Layouts/ShowInvestorLayout';
import { titleCase } from '../../../utils/string';
import { Card, Col, Row } from 'react-bootstrap';
import { Portfolio, usePortfolio } from '../../../api/queries/portfolios/usePortfolio';
import { useExpandableState } from '../../../utils';
import EditActiveStatesModal from '../../../components/Modals/EditActiveStatesModal';
import { State } from '../../../api/queries/partners/usePartner';
import { mutate } from 'swr';
import { LabelValueGrouping } from '../../../components';
import { InternalBankAccount } from '../../../api/queries/investors/useInternalBankAccounts';
import EntityID from '../../../components/EntityID';
import EditSystemSettingsLink from '../../../components/EditSystemSettingsLink';
import AdminContext from '../../../adminContext';
import { Header } from '../../Layouts/Layout';

export default function InvestorsShowInfoPage () {
  const adminContext = useContext(AdminContext);
  const params = useParams<{ investorKey: string, portfolioKey: string }>();
  const investorKey = params.investorKey as string;
  const portfolioKey = params.portfolioKey as string;
  const portfolio = usePortfolio(portfolioKey);

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          {investorKey}
        </Header>
        { portfolio.data ? <>
          <ShowInvestorLayout
            investorKey={ investorKey }
            selectedTab='portfolios'
            heading={ ' ' }
          >
            <PortfolioInfo portfolio={portfolio.data} />
          </ShowInvestorLayout>
        </> : <div>Loading…</div> }
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='investors' heading={titleCase(investorKey)}>
        { portfolio.data ? <>
          <ShowInvestorLayout
            investorKey={ investorKey }
            selectedTab='portfolios'
            heading={ ' ' }
          >
            <PortfolioInfo portfolio={portfolio.data} />
          </ShowInvestorLayout>
        </> : <div>Loading…</div> }
      </SystemLayout>
    );
  }
}

function PortfolioInfo ({ portfolio }: { portfolio: Portfolio }) {
  const editActiveStates = useExpandableState();
  return (<div className='px-4'>
    <Row className='mb-4'>
      <Col>
        <Card>
          <Card.Body>
            <LabelValueGrouping
              condensed
              data = {{
                'Key': portfolio.key,
                'Name': portfolio.name,
                'External Name': portfolio.externalName || '—',
                'Description': portfolio.description || '—',
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className='mb-4'>
      <Col>
        <Card>
          <Card.Header>
            Loan product
          </Card.Header>
          <Card.Body>
            <LabelValueGrouping
              condensed
              data = {{
                'Draw Periods': portfolio.drawPeriods ?? '—',
                'Fallback Portfolio': portfolio.fallbackPortfolio?.key,
                'Active States': <div className='d-flex'>
                  <div className='flex-grow-1 pr-3'>
                    <StateCodes states={portfolio.states}/>
                  </div>
                  <div data-qa='states'>
                    { portfolio.states.length }<br />
                    <EditSystemSettingsLink onClick={editActiveStates.showExpand}/>
                  </div>
                </div>,
              }}
            />

            <EditActiveStatesModal
              expand={editActiveStates}
              states={portfolio.states}
              endpoint={`/notebook/portfolios/${portfolio.key}/states`}
              onSubmit={() => mutate(`/notebook/portfolios/${portfolio.key}`)}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className='mb-4'>
      <Col>
        <Card>
          <Card.Header>
            Portfolio transfer
          </Card.Header>
          <Card.Body>
            <LabelValueGrouping
              condensed
              data = {{
                'Automated Portfolio Transfer Enabled': portfolio.automatedPortfolioTransferEnabled ?? '—',
                'Transfer Review Days': portfolio.transferReviewDays ?? '—',
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className='mb-4'>
      <Col>
        <Card>
          <Card.Header>
            Disbursements
          </Card.Header>
          <Card.Body>
            <LabelValueGrouping
              condensed
              data = {{
                'Disbursement Account': <InternalAccount account={ portfolio.disbursementAccount } />,
                'Check Disbursement Account': <InternalAccount account={ portfolio.checkDisbursementAccount } />,
                'Card Prefunding Start Date': portfolio.cardPrefundingStartDate ?? '—',
              }}
            />
            <hr />
            <LabelValueGrouping
              condensed
              data = {{
                'Fallback Disbursement Account': <InternalAccount account={ portfolio.fallbackPortfolio?.disbursementAccount } />,
                'Fallback Check Disbursement Account': <InternalAccount account={ portfolio.fallbackPortfolio?.checkDisbursementAccount } />,
                'Fallback Card Prefunding Start Date': portfolio.fallbackPortfolio?.cardPrefundingStartDate ?? '—',
              }}
            />
            <p className='text-muted small'>
              The fallback portfolio holds the loan until the transfer is executed. Disbursements may begin prior to the transfer,
              so it is important the fallback portfolio's accounts are the same unless specifically required to be different.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className='mb-4'>
      <Col>
        <Card>
          <Card.Header>
            Collections
          </Card.Header>
          <Card.Body>
            <LabelValueGrouping
              condensed
              data = {{
                'Automated Investor Remittances Enabled': portfolio.automatedInvestorRemittancesEnabled ?? '—',
                'Collection Account': <InternalAccount account={ portfolio.collectionAccount } />,
                'Fallback Collection Account': <InternalAccount account={ portfolio.fallbackPortfolio?.collectionAccount } />,
              }}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className='mb-4'>
      <Col>
        <Card>
          <Card.Header>
            Purchases
          </Card.Header>
          <Card.Body>
            <LabelValueGrouping
              condensed
              data = {{
                'Automated Investor Purchases Enabled': portfolio.automatedInvestorPurchasesEnabled ?? '—',
                'Origination Fee Sale Strategy': portfolio.originationFeeSaleStrategy ?? '—',
                'Purchase Premium BPS': portfolio.purchasePremiumBps ?? '—',
                'Servicing Fee APR BPS': portfolio.servicingFeeAprBps ?? '—',
                'Wire Account': <InternalAccount account={ portfolio.wireAccount } />,
              }}
            />
            <p className='text-muted small'>
              The wire account is the account listed on the Purchase Summary PDF as the account for
              the investor to wire the funds for purchase of draws. It generally is the same as the
              disbursement account.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div>);
}

function InternalAccount ({ account }: { account: InternalBankAccount | null | undefined }) {
  return (
    <>
      { account ? <>
        { account.institution } **{account.lastFour} <EntityID inline link={ false } id={ account._id } />
        </>: '—' }
    </>
  );
}

function StateCodes ({ states }: { states: Array<State> }) {
  return <>
    {
      states.map((state, index) => (
        <span key={state.code}>
          <Link to={`/system/states/${state._id}`}>{state.code}</Link>
          {states.length === index + 1 ? null : ', '}
        </span>
      ))
    }
  </>;
}
