import React from 'react';
import { Col, Row, ProgressBar } from 'react-bootstrap';
import useLedgerSummary from '../../../api/queries/loans/useLedgerSummary';
import InlineLoadingIndicator from '../../InlineLoadingIndicator';
import InlineError from '../../InlineError';
import { formatCents } from '../../../utils/index';
import Decimal from 'decimal.js';
import moment from 'moment';
import styles from './Overview.module.scss';

type Loan = {
  _id: string;
  annualInterestRateBps: number;
  termDescription: string;
  originationDate: string;
  maturityDate: string;
}

export default function UsageOverview ({ loan }: { loan: Loan }) {
  const { data, error } = useLedgerSummary(loan._id);
  if (!data) { return <InlineLoadingIndicator /> }
  if (error) { return <InlineError>Error loading the ledger summary.</InlineError> }

  const progress = new Decimal(data.utilizationRatio).times(100).toNumber();
  return (
    <>
      <h5>Usage Overview</h5>
        <Row className='border rounded mb-4 mx-0'>
          <Col sm={8}>
            <Row className='py-2'>
              <Col sm={3}>
                <div><b>Credit Limit</b></div> { formatCents(data.principalCents) }
              </Col>
              <Col sm={3}>
                <div><b>Used</b></div> { formatCents(data.settledDisbursedCents) }
              </Col>
              <Col sm={3}>
                <div><b>Available</b></div> { formatCents(data.availableFundsCents) }
              </Col>
              <Col sm={3} className='text-secondary'>
                <div><b>Pending</b></div> { formatCents(data.unsettledDisbursedCents) }
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='align-self-center'>
                <ProgressBar now={progress} className={styles.progressBar}></ProgressBar>
              </Col>
              <Col md='auto' className='text-secondary pl-0 small'>{`${progress.toFixed(1)}% used`}</Col>
            </Row>
          </Col>
          <Col sm={4} className='py-2 border-left'>
            <div><b>Rate:</b> { new Decimal(loan.annualInterestRateBps).dividedBy(100).toNumber()}%</div>
            <div><b>Term:</b> { loan.termDescription }</div>
            <div><b>Originated On:</b> { moment(loan.originationDate).format('ll') }</div>
            <div><b>Matures On:</b> { moment(loan.maturityDate).format('ll') }</div>
          </Col>
        </Row>
    </>
  );
}
