import React from 'react';
import LoanApplicationShowLayout from '../Layouts/LoanApplicationShowLayout';
import { useParams } from 'react-router-dom';
import useLoan from '../../api/queries/loans/useLoan';
import useLoanApplication from '../../api/queries/loan-applications/useLoanApplication';
import DocumentsTab from '../../components/BorrowerTabs/DocumentsTab';

export default function DocumentsPage () {
  const { loanApplicationId } = useParams<{ loanApplicationId: string }>();
  const loanApplicationReq = useLoanApplication(loanApplicationId);
  const loanReq = useLoan(loanApplicationReq?.data?.loan?._id);

  if (!loanApplicationReq.data) { return <div className='text-muted ml-4 mt-4'>Loading…</div> }

  const loan = loanReq.data;
  const loanApplication = loanApplicationReq.data;


  return <LoanApplicationShowLayout selectedTab='documents' loan={loan} loanApplication={loanApplication}>
    <DocumentsTab loanApplicationId={loanApplication._id} />
  </LoanApplicationShowLayout>
}
