import React, { useContext } from 'react';
import States from '../../../components/System/States/States';
import SystemLayout from '../../Layouts/SystemLayout';
import AdminContext from '../../../adminContext';
import { Header } from '../../Layouts/Layout';

export default function StatesPage () {
  const adminContext = useContext(AdminContext);

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
              <li className="breadcrumb-item">System</li>
              <li className="breadcrumb-item active" aria-current="page">States</li>
            </ol>
          </nav>
        </Header>
        <States/>
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='states'>
        <States/>
      </SystemLayout>
    );
  }
}
