import React from 'react';
import {
  CONFIG_SOURCE_TYPE,
  CREDIT_MODE,
  Config,
  FicoConfig,
  MsaConfig,
  Portfolio,
  Product,
  StateConfig
} from '../../../api/queries/products/useProduct';
import { Card, Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import LabelValueGrouping from '../../LabelValueGrouping';
import moment from 'moment';
import formatDollars from '../../../utils/formatDollars';
import * as _ from 'lodash';
import { InfoOutlined } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

function SourceValue ({ productConfig, nextProductConfig }: { productConfig: FicoConfig | StateConfig | MsaConfig | Config, nextProductConfig: FicoConfig | StateConfig | MsaConfig | Config }) {
  if (productConfig.sourceType === CONFIG_SOURCE_TYPE.FICO) {
    return <>{(productConfig as FicoConfig).minFicoBucket} - {nextProductConfig ? (nextProductConfig as FicoConfig).minFicoBucket - 1 : '∞'}</>;
  } else if (productConfig.sourceType === CONFIG_SOURCE_TYPE.HOME_STATE || productConfig.sourceType === CONFIG_SOURCE_TYPE.LISTING_STATE) {
    return <>{(productConfig as StateConfig).state}</>;
  } else {
    return <>{(productConfig as MsaConfig).msa}</>;
  }
}

function OriginationFeeValue ({ productConfig }: { productConfig: FicoConfig | StateConfig | MsaConfig | Config }) {
  if (_.isNil(productConfig.fixedOriginationFeeCents)) { return <>-</>; }

  const fixedOriginationFee = productConfig.fixedOriginationFeeCents ? `${formatDollars(productConfig.fixedOriginationFeeCents, { cents: true, precision: 0 })}` : null;
  const originationFeeRate = productConfig.originationFeeRateBps ? `${productConfig.originationFeeRateBps / 100}% of balance` : null;
  return <>
    {
      [fixedOriginationFee, originationFeeRate].filter(Boolean).join(' + ') || '$0'
    }
    {
      productConfig.originationFeeStrategy === 'monthly' && ` per month`
    }
    {
      !_.isNil(productConfig.originationFeeCapCents) && ` (capped to ${formatDollars(productConfig.originationFeeCapCents, { cents: true, precision: 0 })})`
    }
  </>;
}

function LoanBalanceValue ({ productConfig, nextProductConfig }: { productConfig: FicoConfig | StateConfig | MsaConfig | Config, nextProductConfig: FicoConfig | StateConfig | MsaConfig | Config }) {
  const getSource = (productConfig: FicoConfig | StateConfig | MsaConfig | Config) => {
    if (productConfig.sourceType === CONFIG_SOURCE_TYPE.FICO) {
      return (productConfig as FicoConfig).minFicoBucket;
    } else if (productConfig.sourceType === CONFIG_SOURCE_TYPE.HOME_STATE || productConfig.sourceType === CONFIG_SOURCE_TYPE.LISTING_STATE) {
      return (productConfig as StateConfig).state;
    } else {
      return (productConfig as MsaConfig).msa;
    }
  }

  return <>
    {formatDollars(productConfig.minBalanceCentsBucket, { cents: true })}
    &nbsp;-&nbsp;
    {nextProductConfig && getSource(nextProductConfig) === getSource(productConfig) ? formatDollars(nextProductConfig.minBalanceCentsBucket - 1, { cents: true }) : '∞'}
  </>;
}

export function ProductConfigsTable ({ productConfigs, creditMode, sourceTitle }: { productConfigs: Array<FicoConfig | StateConfig | MsaConfig | Config>, creditMode: CREDIT_MODE, sourceTitle?: string }) {
  return <>
    <Table>
      <thead>
      <tr>
        { sourceTitle && <th>{sourceTitle}</th> }
        <th>Loan Balance</th>
        <th>Min Allowed FICO</th>
        <th>Origination Fee</th>
        <th>OF accrues interest</th>
        <th>Annual Interest</th>
        <th>Min Credit</th>
        <th>Max Credit</th>
        {
          creditMode === CREDIT_MODE.DTI &&
          <>
            <th>Max DTI</th>
            <th>Max UDTI</th>
          </>
        }
        {
          creditMode === CREDIT_MODE.LTV &&
          <th>Max List Price</th>
        }
      </tr>
      </thead>
      <tbody>
      {
        productConfigs.map((productConfig, index) => (
          <tr key={index}>
            { sourceTitle && <td><SourceValue productConfig={productConfig} nextProductConfig={productConfigs[index + 1]}/></td> }
            <td><LoanBalanceValue productConfig={productConfig} nextProductConfig={productConfigs[index + 1]}/></td>
            <td>{_.isNil(productConfig.minAllowedFico) ? '-' : productConfig.minAllowedFico}</td>
            <td><OriginationFeeValue productConfig={productConfig}/></td>
            <td>{_.isNil(productConfig.originationFeeInterestAccrual) ? '-' : productConfig.originationFeeInterestAccrual ? 'Yes' : 'No'}</td>
            <td>{_.isNil(productConfig.annualInterestRateBps) ? '-' : `${productConfig.annualInterestRateBps / 100}%`}</td>
            <td>{_.isNil(productConfig.minCreditLimitCents) ? '-' : formatDollars(productConfig.minCreditLimitCents, { cents: true })}</td>
            <td>{_.isNil(productConfig.maxCreditLimitCents) ? '-' : formatDollars(productConfig.maxCreditLimitCents, { cents: true })}</td>
            { creditMode === CREDIT_MODE.DTI &&
              <>
                <td>{_.isNil(productConfig.maxDTI) ? '-' : `${productConfig.maxDTI * 100}%`}</td>
                <td>{_.isNil(productConfig.maxUDTI) ? '-' : `${productConfig.maxUDTI * 100}%`}</td>
              </>
            }
            { creditMode === CREDIT_MODE.LTV &&
              <td>{_.isNil(productConfig.maxListingPriceCents) ? '-' : formatDollars(productConfig.maxListingPriceCents, { cents: true, precision: 0 })}</td>
            }
          </tr>
        ))
      }
      </tbody>
    </Table>
    { !productConfigs.length && <div className='text-center mt-2 w-100'>There are no {sourceTitle} overrides for this product</div> }
  </>;
}

function CardBlock ({ children }: { children: React.ReactNode }) {
  return (
    <Row>
      <Col>
        <Card className='mt-4'>
          {children}
        </Card>
      </Col>
    </Row>
  );
}

function CardHeaderWithTooltip ({ title, tooltipTitle, tooltipId }: { title: string, tooltipTitle: string, tooltipId: string }) {
  return (
    <Card.Header>
      <OverlayTrigger
        overlay={
          <Tooltip id={tooltipId}>{tooltipTitle}</Tooltip>
        }
      >
        <span>{title} <span className="pl-1"><InfoOutlined fontSize='inherit' className="mb-1"/></span></span>
      </OverlayTrigger>
    </Card.Header>
  );
}

function hasConfigs (portfolio: Portfolio) {
  return portfolio.ficoConfigs && portfolio.ficoConfigs.length > 0;
}

function PortfoliosTable ({ portfolios, currentPortfolio }: { portfolios: Array<Portfolio>, currentPortfolio?: Portfolio }) {
  const navigate = useNavigate();

  function maybeNavigateToPortfolio (portfolio: Portfolio) {
    if (hasConfigs(portfolio)) {
      navigate(`/system/products/${portfolio.productId}/portfolios/${portfolio._id}`)
    }
  }

  return (
    <Table>
      <thead>
      <tr>
        <th>Portfolio</th>
        <th>Priority</th>
      </tr>
      </thead>

      <tbody>
      {
        portfolios.map((portfolio) => (
          <tr
            key={portfolio._id}
            className={`${hasConfigs(portfolio) ? 'cursor-pointer hover:shaded-grey' : ''} ${portfolio._id === currentPortfolio?._id ? 'bg-blue' : ''}`}
            onClick={ () => maybeNavigateToPortfolio(portfolio) }
          >
            <td>{portfolio.key}</td>
            <td>{portfolio.priority}</td>
          </tr>
        ))
      }
      </tbody>
    </Table>
  );
}

export default function ProductShow ({ product, currentPortfolio }: { product: Product, currentPortfolio?: Portfolio }) {
  let ficoConfigs;
  let homeStateConfigs;
  let listingStateConfigs;
  let homeMsaConfigs;
  let listingMsaConfigs;
  const portfolios = _.orderBy(product.portfolios, ['priority'], ['asc']);

  if (currentPortfolio && hasConfigs(currentPortfolio)) {
    ficoConfigs = _.orderBy(currentPortfolio.ficoConfigs, ['minFicoBucket', 'minBalanceCentsBucket'], ['asc', 'asc']);
    homeStateConfigs = _.orderBy(currentPortfolio.homeStateConfigs, ['state', 'minBalanceCentsBucket'], ['asc', 'asc']);
    listingStateConfigs = _.orderBy(currentPortfolio.listingStateConfigs, ['state', 'minBalanceCentsBucket'], ['asc', 'asc']);
    homeMsaConfigs = _.orderBy(currentPortfolio.homeMsaConfigs, ['msa', 'minBalanceCentsBucket'], ['asc', 'asc']);
    listingMsaConfigs = _.orderBy(currentPortfolio.listingMsaConfigs, ['msa', 'minBalanceCentsBucket'], ['asc', 'asc']);
  } else {
    ficoConfigs = _.orderBy(product.ficoConfigs, ['minFicoBucket', 'minBalanceCentsBucket'], ['asc', 'asc']);
    homeStateConfigs = _.orderBy(product.homeStateConfigs, ['state', 'minBalanceCentsBucket'], ['asc', 'asc']);
    listingStateConfigs = _.orderBy(product.listingStateConfigs, ['state', 'minBalanceCentsBucket'], ['asc', 'asc']);
    homeMsaConfigs = _.orderBy(product.homeMsaConfigs, ['msa', 'minBalanceCentsBucket'], ['asc', 'asc']);
    listingMsaConfigs = _.orderBy(product.listingMsaConfigs, ['msa', 'minBalanceCentsBucket'], ['asc', 'asc']);
  }

  return (
    <section className='mb-4 mt-4 px-4'>
      <Card>
        <Card.Header>
          Product Info
        </Card.Header>

        <Card.Body>
          <Row>
            <Col>
              <LabelValueGrouping
                data={{
                  'Name': product.name,
                  'Active': product.active ? 'Yes' : 'No',
                  'Partner': product.partner,
                  'Credit Mode': product.creditMode,
                  'Available Equity Ratio': product.availableEquityRatio ?? '--',
                  'Available Equity Haircut Ratio': product.availableEquityHaircutRatio ?? '--',
                  'Property Value Ratio': product.propertyValueRatio ?? '--',
                  'Autopay Discount': `${product.autopayDiscountRateBps / 100}%`,
                  'Promo Discount': `${product.promoDiscountBps / 100}%`,
                  'Promo Discount Periods': product.promoDiscountPeriods,
                  'I/O Periods': product.interestOnlyPeriods,
                  'Periods Without Payments Due': product.zeroPaymentDuePeriods,
                  'Period Frequency': product.periodFrequency,
                  'Term Periods': product.termPeriods,
                  'Default Max Listing Price': _.isNil(product.defaultMaxListingPriceCents) ? '-' : formatDollars(product.defaultMaxListingPriceCents, { cents: true, precision: 0 }),
                  'Min FICO for Stated Verification': product.minFicoForStatedVerification,
                  'Offered #': product.loanApplicationsCount,
                  'Activated': moment(product.activatedAt).format('lll'),
                }}
                condensed={ true }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <CardBlock>
        <Card.Header>
          Subscribed Portfolios
        </Card.Header>

        <Card.Body>
          <PortfoliosTable portfolios={portfolios} currentPortfolio={currentPortfolio} />
        </Card.Body>
      </CardBlock>

      <h2 className='mt-4'>
        { currentPortfolio ? currentPortfolio.key : 'Product' } Configs
      </h2>


      { product.creditMode === CREDIT_MODE.LTV &&
        <CardBlock>
          <CardHeaderWithTooltip
            title="Listing MSA Configs"
            tooltipTitle="Listing MSA configs will override the configs below for a given borrower if their listing address is in a MSA with a config"
            tooltipId="listing-msa-config"
          />

          <Card.Body>
            <ProductConfigsTable productConfigs={listingMsaConfigs} creditMode={product.creditMode} sourceTitle={'MSA'}/>
          </Card.Body>
        </CardBlock>
      }

      <CardBlock>
        <CardHeaderWithTooltip
          title="Home MSA Configs"
          tooltipTitle="Home MSA configs will override the State and Fico configs for a given borrower if their home address is in a MSA with a config"
          tooltipId="home-msa-config"
        />

        <Card.Body>
          <ProductConfigsTable productConfigs={homeMsaConfigs} creditMode={product.creditMode} sourceTitle={'MSA'}/>
        </Card.Body>
      </CardBlock>

      { product.creditMode === CREDIT_MODE.LTV &&
        <CardBlock>
          <CardHeaderWithTooltip
            title="Listing State Configs"
            tooltipTitle="Listing State configs will override the configs below for a given borrower if their listing address is in a state with a config"
            tooltipId="listing-state-config"
          />
          <Card.Body>
            <ProductConfigsTable productConfigs={listingStateConfigs} creditMode={product.creditMode} sourceTitle={'State'}/>
          </Card.Body>
        </CardBlock>
      }

      <CardBlock>
        <CardHeaderWithTooltip
          title="Home State Configs"
          tooltipTitle="Home State configs will override the Fico configs for a given borrower if their home address is in a state with a config"
          tooltipId="home-state-config"
        />
        <Card.Body>
          <ProductConfigsTable productConfigs={homeStateConfigs} creditMode={product.creditMode} sourceTitle={'State'}/>
        </Card.Body>
      </CardBlock>

      <CardBlock>
        <Card.Header>
          FICO Configs
        </Card.Header>

        <Card.Body>
          <ProductConfigsTable productConfigs={ficoConfigs} creditMode={product.creditMode} sourceTitle={'FICO'}/>
        </Card.Body>
      </CardBlock>
    </section>
  );
}
