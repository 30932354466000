import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import { Fact } from '../../api/queries/loan-applications/useUnderwritingFacts';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { PropertyListingAddressFactData } from '../BorrowerTabs/UnderwritingTab/facts/PropertyListingAddressFact';
import AddressFields from '../Form/AddressFields';
import { FactEditModalProps } from '../BorrowerTabs/UnderwritingTab/FactsTable';

type FieldValues = {
  verified: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
};

type Props = {
  fact: Fact<PropertyListingAddressFactData>;
};

export default function EditAddressFactModal (props: Props & FactEditModalProps) {
  const { expand, onSubmit, fact, submitUrl } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${fact.loan_application_id}${submitUrl}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        verified: data.verified === 'true',
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
      }});
    await onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    verified: yup.string(),
    addressLine1: yup.string().required('required'),
    addressLine2: yup.string().nullable(),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required'),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      verified: fact.verified ? 'true' : 'false',
      addressLine1: fact.data.address_line1,
      addressLine2: fact.data.address_line2,
      city: fact.data.city,
      state: fact.data.state,
      zip: fact.data.zip,
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, reset } = methods;

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide} data-qa='edit-fact-modal'>
      <Modal.Header>
        <Modal.Title>
          {fact.human_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='verified' label='Verification Status' controlProps={{ as: 'select' }}>
            <option value='true'>Verified</option>
            <option value='false'>Unverified</option>
          </Field>

          <AddressFields
            addressLine1FieldName='addressLine1'
            addressLine2FieldName='addressLine2'
            cityFieldName='city'
            stateFieldName='state'
            zipFieldName='zip'
          />
          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
