import React from 'react';

const Loans = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_85_25)">
      <circle cx="8" cy="8" r="4.375" stroke="#495057" strokeWidth="1.25"/>
      <circle cx="8" cy="8" r="7.375" stroke="#495057" strokeWidth="1.25"/>
    </g>
    <defs>
      <clipPath id="clip0_85_25">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default Loans;
