import React from 'react';
import { Flag } from '../../../../../api/queries/loan-applications/useUnderwritingFlags';
import useLoanApplicationUser from '../../../../../api/queries/loan-applications/useLoanApplicationUser';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export type MatchingIpInAnotherApplicationFlagData = {
  loanApplicationIds: Array<string>;
}

function MatchingUser ({ loanApplicationId }: { loanApplicationId: string }) {
  const { data: user } = useLoanApplicationUser(loanApplicationId);
  if (!user) {
    return null;
  }

  return (
    <Row>
      <Col>
        <Link to={`/users/${user._id}`} className='text-muted'>
          <u>{user.fullName}</u>
        </Link> { user.deletedAt && <span className='text-muted'> (archived)</span> }
      </Col>
    </Row>
  );
}

export default function MatchingIpInAnotherApplicationFlagDetails ({ flag }: { flag: Flag<MatchingIpInAnotherApplicationFlagData> }) {
  return (
    <>
      {
        flag.data.loanApplicationIds.map(loanApplicationId => (
          <MatchingUser loanApplicationId={loanApplicationId} key={loanApplicationId}/>)
        )
      }
    </>
  );
}
