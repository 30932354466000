import React, { useEffect } from 'react';
import TabLayout from './TabLayout';

export default function ShowInvestorLayout ({ investorKey, selectedTab, children, heading }: { investorKey: string, selectedTab: string, children: React.ReactNode, heading?: string }) {
  useEffect(() => {
    document.title = `Investor ${ investorKey } | System | Notebook`;
  }, [investorKey]);

  return (
    <div>
      <TabLayout
        id='investor-tabs'
        selectedTab={selectedTab}
        heading={heading}
        tabs={{
          info: 'Info',
          'pending-remits': 'Pending Remits',
          reports: 'Reports',
          portfolios: 'Portfolios',
          'bank-accounts': 'Bank Accounts',
        }}
        navigateTo = { (selected: string) => `/system/investors/${investorKey}/${selected}` }
      >
        <section className='mb-4 mt-4'>
          { children }
        </section>
      </TabLayout>
    </div>
  );
}
