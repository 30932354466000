import React from 'react';
import { LegacyLoanApplication } from '../../../api/queries/users/useLoanApplications';
import { Card, CardDeck, Col, Row } from 'react-bootstrap';
import ActionButtons from './ActionButtons';
import UnderwritingRunOutcomeBadge from './v4/UnderwritingRunOutcomeBadge';
import { UnderwritingStep as UnderwritingStepType, UnderwritingSummary as UnderwritingSummaryType } from '../../../api/queries/loan-applications/useUnderwritingSummary';
import { formatCents } from '../../../utils/index';
import { UNDERWRITING_DECISION_TYPES, UNDERWRITING_RUN_OUTCOMES } from './constants';
import { isNil } from 'lodash';
import { toPercentage } from '../../../utils/toPercentage';
import { UnderwritingDecision } from '../../../api/queries/underwriting-decisions/useLatest';

function UnderwritingStep ({ type, underwritingStep, title, className }: { type: string, underwritingStep: UnderwritingStepType, title: string, className?: string }) {
  const productSummaries = underwritingStep.productSummaries;

  return <Card className={`${className} ${type}`}>
    <Card.Body>
      <Row className="border-bottom pb-2 mx-0">
        <dt className="col px-0">{title}</dt>
        <dd className="col px-0">
          <div className="float-right">
            <UnderwritingRunOutcomeBadge outcome={underwritingStep.outcome as UNDERWRITING_RUN_OUTCOMES} />
          </div>
        </dd>
      </Row>
      {
        productSummaries.map(productSummary => (
          <div key={productSummary.productId}>
            {
              productSummaries.length > 1 &&
              <Row className="pt-3 mx-0">
                <dt className="col px-0">{productSummary.productName}</dt>
              </Row>
            }
            <Row className="pt-3 mx-0">
              <dt className="col px-0">Available Credit</dt>
              <dd className="col px-0">
                <div className="float-right">
                  {productSummary.availableCreditCents ? formatCents(productSummary.availableCreditCents) : '--'}
                </div>
              </dd>
            </Row>
            <Row className="mx-0">
              <dt className="col px-0">Max Credit</dt>
              <dd className="col px-0">
                <div className="float-right">
                  {productSummary.maxAllowedCreditCents ? formatCents(productSummary.maxAllowedCreditCents) : '--'}
                </div>
              </dd>
            </Row>
          </div>
        ))
      }
    </Card.Body>
  </Card>;
}

export default function UnderwritingSummary ({
  loanApplication,
  underwritingSummary,
  underwritingDecision,
}: {
  loanApplication: LegacyLoanApplication,
  underwritingSummary: UnderwritingSummaryType | undefined,
  underwritingDecision: UnderwritingDecision | undefined,
}) {

  if (!underwritingSummary) {
    return null;
  }

  return (
    <>
      <Card className="mb-4 underwriting-summary">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <div className="font-weight-bold">Requested Loan</div>
              <div>
                {
                  !isNil(underwritingSummary.requestedPrincipalCents) ?
                    <>
                      <span>{formatCents(underwritingSummary.requestedPrincipalCents)}</span> · <span>{underwritingSummary.selectedProductName} term</span>
                    </>
                    :
                    <>
                      <span>--</span>
                    </>
                }
              </div>
              <div>
                {
                  !isNil(underwritingSummary.aprBps) &&
                  <>
                    <span>{toPercentage(underwritingSummary.aprBps)} APR</span> · <span>{formatCents(underwritingSummary.originationFeeCents)} origination fee</span>
                  </>
                }
              </div>
            </Col>
            <Col xs='auto'>
              <ActionButtons loanApplication={ loanApplication } decision={ underwritingDecision }/>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <CardDeck className="mb-5">
        <UnderwritingStep type={UNDERWRITING_DECISION_TYPES.PRE_QUALIFICATION} underwritingStep={underwritingSummary.prequalificationStep} title="Prequalification" className='mr-4'/>
        <UnderwritingStep type={UNDERWRITING_DECISION_TYPES.STRICT_DECISION} underwritingStep={underwritingSummary.finalStep} title="Final Decision" className='ml-0'/>
      </CardDeck>
    </>
  );
}
