import React, { useState } from 'react';
import { Dropdown, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import CheckmarkIcon from '../CardCheckbox/CheckmarkIcon';

type Props = {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  options: Array<{ value: string, label: string, disabled?: boolean }>;
};

export default function MultiSelectField ({ name, label, placeholder, options, required }: Props) {
  const [show, setShow] = useState(false);

  const { control } = useFormContext();
  const { field: { value, onChange }, fieldState: { error } } = useController({ name, control });

  const add = (addValue: string) => onChange([...value, addValue]);
  const remove = (removeValue: string) => onChange(value.filter((old: string) => old !== removeValue));

  return <FormGroup>
    <FormLabel>{label}{required && <span className="text-danger p-1">*</span>}</FormLabel>

    <Dropdown show={show}>
      <Dropdown.Toggle onClick={() => setShow(!show)} variant="light" id={`${name}-dropdown`} className='w-100 d-flex justify-content-between align-items-center'>
        {value.length ? `${value.length} selected` : placeholder}
      </Dropdown.Toggle>

      <Dropdown.Menu className='w-100'>
        <div className='overflow-auto' style={ { maxHeight: '400px' } }>
          {options.map(option => {
            const isSelected = value.includes(option.value);

            return <Dropdown.Item disabled={option.disabled} className='p-0' key={option.value} onSelect={() => isSelected ? remove(option.value) : add(option.value)}>
              <fieldset className={`cardCheckbox-container blueBg-theme ${isSelected ? 'checked' : ''}`}>
                <label htmlFor={option.label}>{option.label}</label>
                <div className={`cardCheckbox-checkbox-wrapper`}><CheckmarkIcon/></div>
              </fieldset>
            </Dropdown.Item>
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>

    {error && error.message && <FormControl.Feedback type="invalid">{error.message}</FormControl.Feedback>}
  </FormGroup>;
}
