import React from 'react';
import FactsTable from './FactsTable';
import { FACT_CATEGORIES } from './constants';
import FlagsTable from './FlagsTable';
import { Fact } from '../../../api/queries/loan-applications/useUnderwritingFacts';
import { Flag } from '../../../api/queries/loan-applications/useUnderwritingFlags';

function byCategory (facts: Array<Fact>, category: string) {
  return facts.filter(fact => fact.category === category);
}

export default function UnderwritingFacts({
  allowFactsEdit,
  facts,
  flags,
  onUpdate,
}: {
  allowFactsEdit: boolean
  facts: Array<Fact> | undefined,
  flags: Array<Flag> | undefined,
  onUpdate: () => Promise<void>,
}) {

  const identityFacts = byCategory(facts || [], FACT_CATEGORIES.IDENTITY);
  const programFacts = byCategory(facts || [], FACT_CATEGORIES.PROGRAM);

  return (
    <div className='mb-5'>
      <div className='border-bottom'>
        <h4>Underwriting: Facts</h4>
      </div>
      <div className='mt-4'>
        <FactsTable
          title='Identity'
          facts={identityFacts}
          allowFactsEdit={allowFactsEdit}
          onUpdate={onUpdate}
        />
        <FactsTable
          title='Program'
          facts={programFacts}
          allowFactsEdit={allowFactsEdit}
          onUpdate={onUpdate}
        />
        <FlagsTable
          title='Suspicious Activity'
          flags={flags || []}
          allowFactsEdit={allowFactsEdit}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  );
}
