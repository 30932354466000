import _ from 'lodash';
import React from 'react';
import { Badge } from 'react-bootstrap';

export default function LoanSpecialProvisionsBadge ({ specialProvisions }: { specialProvisions?: string }) {
  if (!specialProvisions) { return null; }

  return (
    <Badge className="bg-dark" pill variant={ 'primary' }>
      { _.startCase(_.toLower(specialProvisions.replace(/_/g, ' '))) }
    </Badge>
  );
}
