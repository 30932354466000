import React from 'react';
import useAdminAPICall from "../../utils/useAdminAPICall";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Form from '../Form/Form';
import Field from '../Form/Field';
import SubmitError from '../Form/SubmitError';
import { Fact } from '../../api/queries/loan-applications/useUnderwritingFacts';
import ButtonWithSpinner from '../ButtonWithSpinner';
import CardCheckbox from '../Form/CardCheckbox';
import { ENTITY_TYPE } from '../../api/shared/types/LoanApplication';
import { FactEditModalProps } from '../BorrowerTabs/UnderwritingTab/FactsTable';

const BUSINESS_ENTITIES = [ENTITY_TYPE.LLC, ENTITY_TYPE.CORPORATION, ENTITY_TYPE.PARTNERSHIP];

enum CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY {
  YES = 'yes',
  NO = 'no',
  UNKNOWN = 'unknown',
}

type FieldValues = {
  verified: string;
  owner: string;
  has_poa: boolean;
  entity: string;
  can_act_on_behalf_of_entity: CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY;
};

export type PropertyOwnershipData = {
  is_direct_owner: boolean;
  has_poa: boolean;
  owner_relationship?: string;
  entity_type?: string;
  can_act_on_behalf_of_entity?: CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY;
}

type Props = {
  fact: Fact<PropertyOwnershipData>;
};

export default function EditPropertyOwnershipFactModal (props: Props & FactEditModalProps) {
  const { expand, onSubmit, fact, submitUrl } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/loan-applications/${fact.loan_application_id}${submitUrl}`,
    method: 'PUT',
  });

  async function handleSubmit(data: FieldValues) {
    await callAPI({
      data: {
        verified: data.verified === 'true',
        is_direct_owner: data.owner === 'borrower',
        has_poa: !!data.has_poa && data.owner !== 'borrower',
        owner_relationship: data.owner === 'borrower' ? null : data.owner,
        entity_type: data.entity === 'no' ? null : data.entity,
        can_act_on_behalf_of_entity: data.can_act_on_behalf_of_entity ?? null,
      },
    });
    await onSubmit();
    expand.hideExpand();
  }

  const schema = yup.object().shape({
    verified: yup.string(),
    owner: yup.string(),
    has_poa: yup.boolean(),
    entity: yup.string(),
    can_act_on_behalf_of_entity: yup.string(),
  });

  const methods = useForm<FieldValues>({
    defaultValues: {
      verified: fact.verified ? 'true' : 'false',
      owner: fact.data.is_direct_owner ? 'borrower' : fact.data.owner_relationship,
      has_poa: fact.data.has_poa,
      entity: fact.data.entity_type ? fact.data.entity_type : 'no',
      can_act_on_behalf_of_entity: fact.data.can_act_on_behalf_of_entity ?? CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY.UNKNOWN,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting, isDirty }, reset, watch } = methods;
  const [owner, entity] = watch(['owner', 'entity']);
  const showPoaField = owner !== 'borrower';
  const isBusinessEntity = BUSINESS_ENTITIES.includes(entity as ENTITY_TYPE);

  const onModalHide = () => {
    expand.hideExpand();
    reset();
  }

  return (
    <Modal show={expand.isExpanded} onHide={onModalHide} data-qa='edit-fact-modal'>
      <Modal.Header>
        <Modal.Title>
          {fact.human_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form {...methods} onSubmit={handleSubmit}>
          <Field name='verified' label='Verification Status' controlProps={{ as: 'select' }}>
            <option value='true'>Verified</option>
            <option value='false'>Unverified</option>
          </Field>

          <Field name='owner' label='Owner' controlProps={{ as: 'select' }} className={ showPoaField ? 'mb-0' : '' }>
            <option value='borrower'>Borrower</option>
            <option value='spouse'>Spouse or domestic partnership</option>
            <option value='family_member'>Other family member</option>
            <option value='business_partner'>Business associate</option>
            <option value='other'>Other</option>
          </Field>

          { showPoaField &&
            <CardCheckbox name='has_poa' label='Has POA' defaultTheme={false} className='mb-2' />
          }

          <Field name='entity' label='Entity' controlProps={{ as: 'select' }}>
            <option value='no'>No</option>
            <option value='corporation'>Corporation</option>
            <option value='llc'>LLC</option>
            <option value='trust'>Trust</option>
            <option value='estate'>Estate</option>
            <option value='partnership'>Partnership</option>
          </Field>

          { isBusinessEntity &&
            <Field name='can_act_on_behalf_of_entity' label='Can act on behalf of property entity?' controlProps={{ as: 'select' }}>
              <option value={CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY.YES}>Yes</option>
              <option value={CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY.NO}>No</option>
              <option value={CAN_ACT_ON_BEHALF_OF_PROPERTY_ENTITY.UNKNOWN}>Unknown</option>
            </Field>
          }

          <SubmitError/>

          <div className="d-flex justify-content-end mt-4">
            <Button variant="secondary" onClick={onModalHide} className="mr-2">
              Cancel
            </Button>
            <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
              Confirm
            </ButtonWithSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
