import React, { useContext } from 'react';
import { Collapse, Container } from 'react-bootstrap';
import Navbar from '../../components/Navbar';
import CONFIG from '../../config';
import { Link, useLocation, useParams } from 'react-router-dom';
import Search from '../../images/Search';
import Applications from '../../images/Applications';
import Loans from '../../images/Loans';
import Disbursements from '../../images/Disbursements';
import System from '../../images/System';
import AdminContext from '../../adminContext';
import styles from './Layout.module.scss';
import cx from 'classnames';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { useSWRConfig } from 'swr';
import ChevronDown from '../../images/ChevronDown';
import ChevronSide from '../../images/ChevronSide';
import Notable from '../../images/Notable';
import NotableRed from '../../images/NotableRed';

type Props = {
  selectedTab?: string;
  viewComponent: React.ReactNode;
};

export function Header ({ children }: { children: React.ReactNode }) {
  return (
    <div className={`${styles.header} py-4 border-bottom sticky-top bg-white d-flex align-items-center`}>
      <div className='px-4 w-100'>
        {children}
      </div>
    </div>
  );
}

function EnvBadge () {
  let badgeText = 'Local';
  switch (CONFIG.ENVIRONMENT) {
    case 'prod':
      badgeText = 'Prod';
      break;
    case 'dev':
      badgeText = 'Staging';
      break;
    case 'test':
      badgeText = 'Test';
      break;
  }

  return (
    <span className={cx(styles.headerBadge, 'text-muted')}>{badgeText}</span>
  );
}

function SidebarHeader () {
  return (
    <Link className={styles.sidebarHeader} to='/'>
      { CONFIG.ENVIRONMENT === 'prod' ? <Notable/> : <NotableRed/> }
      <div>
        <span className='mx-2'>Notebook</span>
        <EnvBadge/>
      </div>
    </Link>
  );
}

function NavLink ({ icon, to, selected, children, onClick }: { icon: JSX.Element, to?: string, selected: boolean, onClick?: () => void, children: React.ReactNode }) {
  if (to) {
    return (
      <Link className={cx(styles.sidebarLink, selected ? styles.selected : '')} to={to} onClick={onClick}>
        <div className='d-flex align-items-center'>
          {icon}
          <div className='ml-2 d-flex align-items-center'>{children}</div>
        </div>
      </Link>
    );
  }
  return (
    <div className={cx(styles.sidebarLink, selected ? styles.selected : '', 'cursor-pointer')} onClick={onClick}>
      <div className='d-flex align-items-center'>
        {icon}
        <div className='ml-2 d-flex align-items-center'>{children}</div>
      </div>
    </div>
  );
}

function SubNavLink ({ to, selected, children }: { to: string, selected: boolean, children: React.ReactNode }) {
  return (
    <Link className={cx(styles.sidebarLink, selected ? styles.selected : '')} to={to}>
      <div className='d-flex align-items-center'>
        <div className='ml-4'>{children}</div>
      </div>
    </Link>
  );
}

function AdminProfile () {
  const { callAPI } = useAdminAPICall({ endpoint: '/notebook/logout', method: 'POST' });
  const { mutate } = useSWRConfig();

  const onLogout = async () => {
    await callAPI();
    mutate('/notebook/admins/self');
  };

  const { fullName, pictureUrl } = useContext(AdminContext);

  return (
    <div className='d-flex align-items-center mt-auto pl-2'>
      { pictureUrl &&
        <img src={pictureUrl} alt='Admin' className={styles.adminImage}/>
      }
      <div className={cx('ml-2', styles.adminName)}>
        <div>{fullName}</div>
        <div className='text-muted cursor-pointer' onClick={onLogout}>Sign out</div>
      </div>
    </div>
  );
}

function LoanApplicationsSubNav () {
  const location = useLocation();
  const pathName = location.pathname;
  const subNavPaths = ['/loan-applications/open', '/loan-applications/pending', '/loan-applications/closed'];
  const [open, setOpen] = React.useState(subNavPaths.includes(pathName));

  return (
    <>
      <NavLink icon={<Applications/>} selected={false} onClick={() => setOpen(!open)}>
        Applications
        <div className='ml-2 aligin-items-center d-flex'>
          { open ? <ChevronDown/> : <ChevronSide/> }
        </div>
      </NavLink>
      <Collapse in={open}>
        <div>
          <SubNavLink selected={pathName === '/loan-applications/open'} to='/loan-applications/open'>
            Open
          </SubNavLink>
          <SubNavLink selected={pathName === '/loan-applications/pending'} to='/loan-applications/pending'>
            Pending
          </SubNavLink>
          <SubNavLink selected={pathName === '/loan-applications/closed'} to='/loan-applications/closed'>
            Closed
          </SubNavLink>
        </div>
      </Collapse>
    </>
  );
}

function LoanSubNav () {
  const location = useLocation();
  const pathName = location.pathname;
  const { status } = useParams<{ status: string }>();
  const [open, setOpen] = React.useState(pathName === `/loans/${status}`);

  return (
    <>
      <NavLink icon={<Loans/>} selected={false} onClick={() => setOpen(!open)}>
        Loans
        <div className='ml-2 align-items-center d-flex'>
          { open ? <ChevronDown/> : <ChevronSide/> }
        </div>
      </NavLink>
      <Collapse in={open}>
        <div>
          <SubNavLink selected={pathName === '/loans/approved'} to='/loans/approved'>
            Approved
          </SubNavLink>
          <SubNavLink selected={pathName === '/loans/authorized'} to='/loans/authorized'>
            Authorized
          </SubNavLink>
          <SubNavLink selected={pathName === '/loans/funded'} to='/loans/funded'>
            Funded
          </SubNavLink>
          <SubNavLink selected={pathName === '/loans/completed'} to='/loans/completed'>
            Completed
          </SubNavLink>
          <SubNavLink selected={pathName === '/loans/repaid'} to='/loans/repaid'>
            Repaid
          </SubNavLink>
          <SubNavLink selected={pathName === '/loans/expired'} to='/loans/expired'>
            Expired
          </SubNavLink>
        </div>
      </Collapse>
    </>
  );
}

function DisbursementsSubNav () {
  const location = useLocation();
  const pathName = location.pathname;
  const { status } = useParams<{ status: string }>();
  const [open, setOpen] = React.useState(pathName === `/disbursements/${status}`);

  return (
    <>
      <NavLink icon={<Disbursements/>} selected={false} onClick={() => setOpen(!open)}>
        Disbursements
        <div className='ml-2 align-items-center d-flex'>
          { open ? <ChevronDown/> : <ChevronSide/> }
        </div>
      </NavLink>
      <Collapse in={open}>
        <div>
          <SubNavLink selected={pathName === '/disbursements/open'} to='/disbursements/open'>
            Open
          </SubNavLink>
          <SubNavLink selected={pathName === '/disbursements/pending'} to='/disbursements/pending'>
            Pending
          </SubNavLink>
          <SubNavLink selected={pathName === '/disbursements/fulfilled'} to='/disbursements/fulfilled'>
            Fulfilled
          </SubNavLink>
          <SubNavLink selected={pathName === '/disbursements/canceled'} to='/disbursements/canceled'>
            Canceled
          </SubNavLink>
          <SubNavLink selected={pathName === '/disbursements/hold'} to='/disbursements/hold'>
            Hold
          </SubNavLink>
        </div>
      </Collapse>
    </>
  );
}

function PartnersSubNav () {
  const location = useLocation();
  const pathName = location.pathname;
  const [open, setOpen] = React.useState(pathName.startsWith('/system'));

  return (
    <>
      <NavLink icon={<System/>} selected={false} onClick={() => setOpen(!open)}>
        System
        <div className='ml-2 align-items-center d-flex'>
          { open ? <ChevronDown/> : <ChevronSide/> }
        </div>
      </NavLink>
      <Collapse in={open}>
        <div>
          <SubNavLink selected={pathName === '/system/partners'} to='/system/partners'>
            Partners
          </SubNavLink>
          <SubNavLink selected={pathName === '/system/states'} to='/system/states'>
            States
          </SubNavLink>
          <SubNavLink selected={pathName === '/system/investors'} to='/system/investors'>
            Investors
          </SubNavLink>
          <SubNavLink selected={pathName === '/system/simulator'} to='/system/simulator'>
            Simulator
          </SubNavLink>
        </div>
      </Collapse>
    </>
  );
}

function Sidebar ({ selectedTab }: { selectedTab?: string }) {
  return (
    <div className={`${styles.sidebar} d-flex flex-column`}>
      <SidebarHeader />
      <div className='my-4'>
        <NavLink icon={<Search/>} selected={selectedTab === 'search'} to='/'>Search</NavLink>
        <LoanApplicationsSubNav/>
        <LoanSubNav/>
        <DisbursementsSubNav/>
        <PartnersSubNav/>
      </div>
      <AdminProfile />
    </div>
  );
}

export default function Layout ({ viewComponent, selectedTab }: Props) {
  const adminContext = useContext(AdminContext);
  if (adminContext.sidebarLayoutEnabled) {
    return (
      <>
        <Sidebar selectedTab={selectedTab}/>
        <div className={styles.viewComponent}>
          {viewComponent}
        </div>
      </>
    );
  }

  return (
    <Container className='rootContainer'>
      <Navbar/>
      {viewComponent}
    </Container>
  );
}
