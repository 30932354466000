import React from 'react';
import VerificationBadge from '../VerificationBadge';
import { Col, Row } from 'react-bootstrap';
import { VERIFICATION_RESULTS } from '../constants';
import { Fact } from '../../../../api/queries/loan-applications/useUnderwritingFacts';
import { useExpandableStateType } from '../../../../utils/useExpandableState';
import InlineButton from '../../../InlineButton';

type Props = {
  fact: Fact;
  showEditButton?: boolean;
  editModal?: useExpandableStateType;
}

function BaseFact ({ fact, showEditButton, editModal }: Props) {
  const values = fact.value?.split('\n') ?? [];
  return (<>
    <Row className='border-top py-3' data-qa='fact-row'>
      <Col sm={4} className='font-weight-bold pl-0'>{fact.human_name}</Col>
      <Col sm={4}>
        {
          values.length === 1 ?
            values[0] :
            values.map(val => <Row key={val} className='pl-3'>{val}</Row>)
        }
      </Col>
      <Col sm={showEditButton ? 3 : 4}>
        <VerificationBadge
          verificationResult={fact.verified ? VERIFICATION_RESULTS.VERIFIED : VERIFICATION_RESULTS.UNVERIFIED}
          unverifiedReasons={fact.unverified_reasons}
        />
      </Col>
      <Col sm={1} className='pr-0'>
        {
          showEditButton && editModal &&
          <InlineButton onClick={editModal.showExpand} className='float-right'>
            Edit
          </InlineButton>
        }
      </Col>
    </Row>
  </>);
}

export default BaseFact;
