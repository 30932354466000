import React from 'react';
import LoanPartnerBadge from './Badges/LoanPartnerBadge';
import LoanStatusBadge from './Badges/LoanStatusBadge';
import { formatDollars } from '../utils';
import LoanSlug from './LoanSlug';
import { LoanSummary } from '../api/queries/users/useLoansSummaries';
import { Card } from 'react-bootstrap';
import { LoanBadges } from './LoanOverview/LoanApplicationOverviewHeader';
import { useNavigate } from 'react-router-dom';
import styles from './LoanSummaryCard.module.scss';
import POABadge from './Badges/POABadge';

type Props = {
  loan: LoanSummary;
}

export default function LoanSummaryCard (props: Props) {
  const { loan } = props;
  const navigate = useNavigate();

  return (
    <Card>
      <Card.Body className='cursor-pointer p-3' onClick={() => navigate(`/loan-applications/${loan.loanApplicationId}/loan`)}>
        <div>
          <Card.Title as="span">
            { loan.user.name }
          </Card.Title>
        </div>
        <div className='d-flex align-items-center mt-1'>
          <div className={styles.badges}>
            <LoanPartnerBadge partnerId={ loan.partnerId } source={loan.loanApplicationSource} />
            <LoanStatusBadge status={ loan.status } />
            <LoanBadges loan={ loan } />
            { loan.isPOA && <POABadge/> }
          </div>
          <span className={`ml-2 ${styles.principal}`}>
            { formatDollars(loan.principle, { precision: 0 }) }
          </span>
          <LoanSlug className='text-muted ml-3'>{ loan.slug }</LoanSlug>
        </div>
      </Card.Body>
    </Card>
  );
}
