import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import _ from 'lodash';
import cx from 'classnames';
import ExpandableCard from './ExpandableCard';
import { MainPanel } from './ExpandableCard';
import TransactionStatusBadge from './Badges/TransactionStatusBadge';
import DisbursementTypeBadge from './Badges/DisbursementTypeBadge';
import LoanPartnerBadge from './Badges/LoanPartnerBadge';
import TransactionAmount from './TransactionAmount';
import useDisbursementRequest from '../api/queries/disbursement-requests/useDisbursementRequest';
import useAdminAPICall from '../utils/useAdminAPICall';
import TransactionDisbursementDetails from './TransactionDisbursementDetails';
import DisbursementVendorDetail from './DisbursementVendorDetail';
import QuickDepositBadge from './Badges/QuickDepositBadge';
import BankAccountNotAddedBadge from './Badges/BankAccountNotAddedBadge';
import NeedsReviewBadge from './Badges/NeedsReviewBadge';

import styles from './LedgerTransactionCard.module.scss';
import { getNeedsReview, DisbursementRequest, Loan } from '../utils/transactionUtils';
import ButtonWithSpinner from './ButtonWithSpinner';
import { useExpandableState } from '../utils';

import { ModifyDisbursementModal } from './Modals/DisbursementModals';
import { DisbursementRequest as PendingTransaction } from '../api/queries/disbursement-requests/usePending';
import PausedBadge from './Badges/PausedBadge';
import LoanPortfolioBadge from './Badges/LoanPortfolioBadge';

type LedgerTransactionActionsProps = {
  loan: Loan;
  transaction: PendingTransaction;
  disbursementRequest: DisbursementRequest;
  reloadData: () => void;
};

function LedgerTransactionActions(props: LedgerTransactionActionsProps) {
  const { loan, transaction, disbursementRequest } = props;
  const editModal = useExpandableState();

  const retryRequest = useAdminAPICall({
    endpoint: `/notebook/disbursement-requests/${disbursementRequest ? disbursementRequest._id : null}/retry`,
    method: 'POST',
  });

  const markAsReturnedRequest = useAdminAPICall({
    endpoint: `/notebook/disbursement-requests/${disbursementRequest ? disbursementRequest._id : null}/mark-as-returned`,
    method: 'POST',
  });

  const cancelRequest = useAdminAPICall({
    endpoint: `/notebook/disbursement-requests/${disbursementRequest._id}/action`,
    method: 'POST',
  });

  const handleCancelRequest = async () => {
    if (!window.confirm('Are you sure you want to cancel this request?')) {
      return;
    }
    await cancelRequest.callAPI({ data: { type: 'cancel' } });
    props.reloadData();
  };

  const handleRetryTransaction = async () => {
    if (!window.confirm('Are you sure you want to retry this transfer?')) {
      return;
    }
    await retryRequest.callAPI();
    props.reloadData()
  }

  const handleMarkAsReturned = async () => {
    if (!window.confirm('Are you sure you want to mark this as returned?')) {
      return;
    }
    await markAsReturnedRequest.callAPI();
    props.reloadData()
  }

  if (!transaction) {
    return null;
  }

  const finalizedDisbursement = transaction.disbursement_request_id &&
    transaction.type === 'ach' &&
    transaction.status === 'cleared';

  const allowReturn = finalizedDisbursement && transaction.amount > 0;
  const allowRetry = finalizedDisbursement && transaction.amount < 0;
  const isEditable = (disbursementRequest?.quick_deposit && !transaction.bank_account_verified) || disbursementRequest?.invoice_status === 'open';

  return (
    <div>
      {disbursementRequest && (
        <ModifyDisbursementModal
          loan={loan}
          disbursement={disbursementRequest}
          expand={editModal}
          onSubmit={props.reloadData}
        />
      )}
      {transaction.status === 'open' && (
        <div className="d-flex">
          {isEditable && (
            <Button
              className={cx(styles.actionButton, 'mr-2')}
              variant="outline-primary"
              onClick={editModal.showExpand}
            >
              Edit Request
            </Button>
          )}
          <ButtonWithSpinner
            block
            className={styles.actionButton}
            variant="danger"
            loading={cancelRequest.loadPending}
            onClick={handleCancelRequest}
            data-qa="LedgerTransactionDetails--cancelBtn"
          >
            Cancel Request
          </ButtonWithSpinner>
        </div>
      )}
      {allowRetry && (
        <ButtonWithSpinner
          block
          className={cx(styles.actionButton, 'mt-3')}
          loading={retryRequest.loadPending}
          variant="primary"
          onClick={handleRetryTransaction}
        >
          Retry Transfer
        </ButtonWithSpinner>
      )}
      {allowReturn && (
        <ButtonWithSpinner
          block
          className={cx(styles.actionButton, 'mt-3')}
          loading={markAsReturnedRequest.loadPending}
          variant="danger"
          onClick={handleMarkAsReturned}
        >
          Mark As Returned
        </ButtonWithSpinner>
      )}
    </div>
  );
}

type LedgerTransactionDetailsProps = {
  transaction: PendingTransaction;
  reloadData: () => void;
};

function LedgerTransactionDetails (props: LedgerTransactionDetailsProps) {
  const [transactionData, setTransactionData] = useState<NonNullable<ReturnType<typeof useDisbursementRequest>['data']> | null>(null)
  const peekRequest = useDisbursementRequest(props.transaction.disbursement_request_id);

  useEffect(() => {
    if (peekRequest.data && !_.isEqual(transactionData, peekRequest.data)) {
      setTransactionData(peekRequest.data);
    }
  }, [transactionData, peekRequest.data]);

  if (peekRequest.error) {
    return <Alert variant="danger">Error loading peek details!</Alert>;
  }

  if (!transactionData) {
    return <div>Loading&hellip;</div>;
  }

  const { purchaseMethod, disbursementRequest, loan } = transactionData;

  return (
    <>
      <MainPanel>
        <div>
          <TransactionDisbursementDetails
            purchaseMethod={purchaseMethod!}
            disbursementRequest={disbursementRequest}
            data-qa="transaction-disbursement-details"
            reloadData={() => {
              props.reloadData();
              peekRequest.mutate();
            }}
          />
          {(!props.transaction.quick_deposit && disbursementRequest) && (
            <DisbursementVendorDetail
              disbursementRequest={disbursementRequest}
              reloadData={() => {
                props.reloadData();
                peekRequest.mutate();
              }}
            />
          )}
        </div>

        <div className={styles.block}>
            <LedgerTransactionActions
              loan={loan}
              transaction={props.transaction}
              disbursementRequest={disbursementRequest}
              reloadData={() => {
                props.reloadData();
                peekRequest.mutate();
              }}
            />
        </div>
      </MainPanel>
    </>
  );
}

function BankAccountVerificationBadge ({ transaction }: { transaction: PendingTransaction }) {
  if (transaction.status === 'voided') {
    return null;
  }

  const isDirect = transaction.type === 'ach';
  if (isDirect && transaction.bank_account_not_added) {
    return <BankAccountNotAddedBadge />;
  }

  if (getNeedsReview(transaction)) {
    return <NeedsReviewBadge />;
  }
  return null;
}

export function TransactionBadges(props: { transaction: PendingTransaction }) {
  const { transaction } = props;
  return (
    <>
      <LoanPartnerBadge partnerId={ transaction.partner_id } source={ transaction.loan_application_source } />
      <LoanPortfolioBadge portfolio={ transaction.portfolio } />
      <TransactionStatusBadge status={ transaction.status } />
      <DisbursementTypeBadge type={ transaction.type } />
      { transaction.quick_deposit ? <QuickDepositBadge/> : null }
      { transaction.status === 'open' ? <BankAccountVerificationBadge transaction={transaction} /> : null }
      { transaction.is_user_paused ? <PausedBadge/> : null}
    </>
  );
}

type Props = {
  transaction: PendingTransaction;
  reloadData?: () => void;
  hideDetailsLink?: boolean;
};

export default function LedgerTransactionCard (props: Props) {
  const { transaction, reloadData } = props;

  return (
    <ExpandableCard
      data-qa     = 'LedgerTransactionCard'
      title       = { <>{ transaction.user_name }, { transaction.loan_slug }: <TransactionAmount amount={ transaction.amount } /></> }
      subtitle    = { new Date(transaction.updated_date) }
      detailsLink = { props.hideDetailsLink ? null : `/loan-applications/${transaction.loan_application_id}/servicing/activity` }
      badges      = {<TransactionBadges transaction={transaction} />}
      renderBody = {() => (
        <LedgerTransactionDetails
          transaction     = { transaction }
          reloadData      = { reloadData! }
        />
      )}
      linkInNewTab
    />
  );
}
