import _ from 'lodash';
import React from 'react';
import { Badge } from 'react-bootstrap';

type Props = {
  className?: string;
  outcome: string;
};

export default function LoanApplicationUnderwritingDecisionBadge (props: Props) {
  const pillVariant = 'dark';
  return (
    <Badge pill variant={ pillVariant } className={ `${props.className} UnderwritingDecisionBadge` }>
      { _.startCase(_.toLower(props.outcome)) }
    </Badge>
  );
}
