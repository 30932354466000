import { ENTITY_TYPE } from '../../shared/types/LoanApplication';
import useQuery from '../../shared/useQuery';
import { DISBURSEMENT_TYPE, VENDOR_TYPE } from '../loans/useDisbursementLimits';
import { PROGRAMS } from '../types';
import { Portfolio } from './usePortfolios';

export type State = {
  _id: string;
  code: string;
  isPrimary: boolean;
}

export enum AGENT_STORAGE_STRATEGY {
  COMPASS_API = 'compass_api',
  LOCAL = 'local',
}

export enum AGENT_PULL_STRATEGY {
  GOOGLE_SHEET = 'google_sheet',
  ANYWHERE_API = 'anywhere_api',
  CUSTOM = 'custom',
}

export type AgentPricing = {
  _id: string;
  rateCents: number;
  minListPriceCentsBucket: number;
};

export type InternalBankAccount = {
  _id: string;
  label: string;
  lastFour: string;
  lobAccount: string;
  postgridAccount: string;
}

export enum HOME_CLOSE_SOURCES {
  GOOGLE_SHEET = 'google_sheet',
  SEVEN_GABLES_API = 'seven_gables_api',
  CSV_SFTP = 'csv_sftp',
  VANGUARD_API = 'vanguard_api',
  BRIGGS_FREEMAN_API = 'briggs_freeman_api',
  ANYWHERE_API = 'anywhere_api',
  BROWN_HARRIS_STEVENS_API = 'brown_harris_stevens_api',
}

export type Partner = {
  _id: string;
  active: boolean;
  agentLandingPageEnabled: boolean;
  agentPricing: Array<AgentPricing>;
  agentPullStrategy: AGENT_PULL_STRATEGY;
  agentStorageStrategy: AGENT_STORAGE_STRATEGY;
  agentSupport: boolean;
  agentPricingPaymentType: string;
  agentsUrl: string;
  android192IconUrl: string;
  android512IconUrl: string;
  annualIncreasePercentage: number;
  agentRosterAnywhereBrandCode: string;
  appleTouchIconUrl: string;
  brand_name: string;
  cashBackEnabled: boolean;
  checkMailingVendor: string;
  contractRenewalDate: string;
  disallowedPropertyEntities: Array<ENTITY_TYPE>;
  disbursementAccount?: InternalBankAccount;
  emailDomain: string;
  emailHeaderLogoUrl: string;
  expressChecksEnabled: boolean;
  faviconUrl: string;
  favicon16Url: string;
  favicon32Url: string;
  forcedManualReview: boolean;
  funnelApplyScreenLogoUrl: string;
  funnelIntroBackground1Url: string;
  funnelIntroBackground2Url: string;
  funnelIntroBackground3Url: string;
  funnelSidebarLogoUrl: string;
  funnel_start_view: string;
  helpUrl: string;
  homeCloseSource: HOME_CLOSE_SOURCES;
  homeCloseGoogleSheetId: string;
  homeCloseSftpCsvFileName: string;
  homeCloseAnywhereBrandCode: string;
  hubspotAllowSync: boolean;
  hubspotCompanyDomain: string;
  hubspotId: string;
  landingPage: string;
  launchDate: string;
  learnMoreUrl: string;
  legal_name: string;
  marqetaApplicationId: string;
  marqetaCardLogoUrl: string;
  marqetaCardLogoName: string;
  marqetaCarrierCardPersonalizationEnabled: boolean;
  marqetaPhysicalCardProductToken: string;
  marqetaProgramSlug: string;
  marqetaVirtualCardProductToken: string;
  maxQuickDepositCents: number;
  name: string;
  paymentsPageEnabled: boolean;
  portalNavbarLogoUrl: string;
  portfolios: Array<Portfolio>;
  program: { key: PROGRAMS, name: string };
  promoStartDate: string;
  promoEndDate: string;
  remoteAgentGoogleSheetId: string;
  short_name: string;
  showFunnelIntro: string;
  slaResponseDays: number;
  slug: string;
  socialImageUrl: string;
  states: Array<State>;
  supportedPayTypes: Array<DISBURSEMENT_TYPE>;
  supportedVendorTypes: Array<VENDOR_TYPE>;
  supportEmail: string;
  supportPhoneNumber: string;
  supportsPhysicalCard: boolean;
  supportsVirtualCard: boolean;
  theNumberOrg: string;
  theNumberPortfolio: string;
  theNumberRepullOrg: string;
  trelloAccelerationNotConfirmedListId: string;
  trelloDeniedLoanAppListId: string;
  trelloIneligibleLoanAppListId: string;
  trelloUnderReviewLoanAppListId: string;
  url: string;
  subdomain: string;
  waitlistEnabled: boolean;
  weeklyOriginationReportEnabled: boolean;
  weeklyOriginationReportRecipients: Array<string>;
  theme: string;
  availableThemes: Array<string>;
  denialDelayEnabled: boolean,
};

type Response = Partner;

export function usePartner (partnerId?: string) {
  return useQuery<Response>(partnerId ? `/notebook/partners/${partnerId}` : null);
}
