import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useFormContext, useController } from "react-hook-form";
import CloseIcon from '@material-ui/icons/Close';
import DocumentIcon from '@material-ui/icons/Description';
import PhotoIcon from '@material-ui/icons/PhotoSizeSelectActual';
import ButtonWithSpinner from '../ButtonWithSpinner';
import useAdminAPICall from '../../utils/useAdminAPICall';
import cx from 'classnames';
import styles from './S3FileUploadField.module.scss';

export enum FILE_TYPES {
  IMAGE = 'Image',
  PDF = 'PDF',
  ANY = 'File',
};

export const ACCEPT_VALUES = {
  [FILE_TYPES.IMAGE]: 'image/*',
  [FILE_TYPES.PDF]: 'application/pdf',
  [FILE_TYPES.ANY]: '*',
};

export function UploadedFileRepresentation ({ type }: { type: string }) {
  switch (type) {
    case FILE_TYPES.IMAGE:
      return <PhotoIcon />;
    case FILE_TYPES.PDF:
    case FILE_TYPES.ANY:
    default:
      return <DocumentIcon />;
  }
}

type Props = {
  name: string;
  label?: string;
  subtitle?: string;
  required?: boolean;
  type?: FILE_TYPES;
  accept?: string;
  s3_path: string;
  remove?: () => void;
  removeInput?: boolean;
  onMultipleUpload?: (s3Filename?: string, originalFilename?: string) => void;
  onUploadFinish?: () => void;
  bucket: string;
  s3FieldKey?: string;
  filenameFieldKey?: string;
};

export default function S3FileUploadField ({
  name,
  label,
  subtitle,
  required,
  bucket,
  s3_path,
  onMultipleUpload,
  onUploadFinish,
  remove,
  removeInput,
  accept,
  type = FILE_TYPES.ANY,
  s3FieldKey = 's3Filename',
  filenameFieldKey = 'originalFilename'
 }: Props) {
  const { control, resetField, setError, getValues } = useFormContext();
  const { field: { onChange, value, ref }, fieldState: { error } } = useController({ name, control });

  const inputEl = React.useRef<HTMLInputElement | null>(null);
  const [isUploading, setUploading] = React.useState(false);

  const { callAPI } = useAdminAPICall({ endpoint: `/notebook/s3`, method: 'PUT' });

  const handleClickUpload = () => {
    inputEl.current && inputEl.current.click();
  };

  const uploadFileToS3 = async (file: File) => {
    // Read the file and convert it to a base64-encoded string.
    const bytes = new Uint8Array(await file!.arrayBuffer());
    const binary = [];
    for (const byte of bytes as any) { // Use loop instead of spread trick to allow for larger files, see: https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
      binary.push(String.fromCharCode(byte));
    }
    const { data: s3Filename } = await callAPI({
      data: {
        file: { type: file.type, name: file.name, content: window.btoa(binary.join('')) },
        s3_path,
        bucket,
      }
    });
    return s3Filename;
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const fileNames = [];
    if (files) {
      setUploading(true);
      try {
        for(let i = 0; i < files.length; i++){
          const file = files[i];
          if (i === 0) {
            resetField(name);
            onChange({ ...getValues(name), [filenameFieldKey]: file.name });
          }
          const s3Filename = await uploadFileToS3(file);
          fileNames.push({ [s3FieldKey]: s3Filename, [filenameFieldKey]: file.name });
        }
        for (let i = 0; i < files.length; i++) {
          if (i === 0) {
            onChange({ ...getValues(name), [s3FieldKey]: fileNames[i][s3FieldKey] });
          } else {
            onMultipleUpload?.(fileNames[i][s3FieldKey], fileNames[i][filenameFieldKey]);
          }
        }
        onUploadFinish?.();
      } catch (e: any) {
        window.console.error(e);
        let message;
        if (e?.request?.status === 413) {
          message = 'File too large. Please upload a smaller file.';
        } else if (e?.message) {
          message = e.message;
        } else {
          message = 'Upload error occurred.';
        }
        setError(name, { type: 'upload', message });
      }
      setUploading(false);
    }
  }

  return <Form.Group controlId={name}>
    {
      label && <Form.Label>
        {label}{required && <span className="text-danger p-1">*</span>}
      </Form.Label>
    }

    { subtitle && <p>{subtitle}</p> }

    <div className='d-flex align-items-center'>
      { value && value[s3FieldKey] ? <>
          <UploadedFileRepresentation type={type} />
          <span className={cx(styles.filename, 'font-size-2 ml-2')}>{value[filenameFieldKey]}</span>
        </> : <>
          <ButtonWithSpinner variant='light' loading={isUploading} onClick={handleClickUpload}>Upload { type }</ButtonWithSpinner>

          <input
            multiple
            name={name}
            type='file'
            accept={accept || ACCEPT_VALUES[type]}
            className='d-none form-control'
            ref={e => {
              ref(e);
              inputEl.current = e;
            }}
            onChange={handleInputChange}
          />
        </>
      }
      {(value && value[s3FieldKey]) || removeInput ?
        <Button variant='light' className='ml-auto' onClick={() => remove ? remove() : resetField(name)}><CloseIcon /></Button>
      : null}
    </div>
    {
      error && <div className="text-danger ml-1 mt-1 small">
        {
          error.message ||
          ((error as any)[s3FieldKey] && (error as any)[s3FieldKey].message) ||
          ((error as any)[filenameFieldKey] && (error as any)[filenameFieldKey].message)
        }
      </div>
    }

  </Form.Group>;
}
