import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import querystring from 'qs';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

function toggleSortDirection (sortDirection: string) {
  if (sortDirection?.toLowerCase() === 'asc') {
    return 'desc';
  } else if (sortDirection?.toLowerCase() === 'desc') {
    return 'asc';
  } else {
    return null;
  }
}

export default function SortableTh ({ children, sortBy }: { children: any, sortBy: string }) {
  const location = useLocation();
  const navigate = useNavigate();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });
  const urlSortBy = parsedParams.sortBy ? parsedParams.sortBy as string : null;
  const urlSortDirection = parsedParams.sortDirection ? parsedParams.sortDirection as string : 'asc';

  const handleClick = () => {
    const urlParams = new URLSearchParams(location.search);
    const sortDirection = sortBy === urlSortBy ? toggleSortDirection(urlSortDirection) : 'asc';

    urlParams.set('sortBy', sortBy);
    urlParams.set('sortDirection', sortDirection || 'asc');
    navigate(`?${urlParams.toString()}`);
  };

  return <th onClick={handleClick} className='cursor-pointer'>
    {children}
    <span>&nbsp;&nbsp;</span>
    {sortBy === urlSortBy && urlSortDirection?.toLowerCase() === 'asc' && <ChevronUp/>}
    {sortBy === urlSortBy && urlSortDirection?.toLowerCase() === 'desc' && <ChevronDown/>}
  </th>;
}
