import useQuery from '../../shared/useQuery';

type Response = {
  invoice_due_date: string;
  due_date_in_past: boolean;
  expiration_date: string;
  is_expired: boolean;
} | null;

export default function useInvoiceDueDate (loanId: string) {
  return useQuery<Response>(`/notebook/loans/${ loanId }/invoices/due-date`);
}
