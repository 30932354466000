import React, { useContext } from 'react';
import SystemLayout from '../../Layouts/SystemLayout';
import { usePartners } from '../../../api/queries/partners/usePartners';
import Simulator from './Simulator';
import AdminContext from '../../../adminContext';
import { Header } from '../../Layouts/Layout';

export default function PartnersPage () {
  const adminContext = useContext(AdminContext);
  const partnersRequest = usePartners();

  if (!partnersRequest.data) {
    return null;
  }

  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
              <li className="breadcrumb-item">System</li>
              <li className="breadcrumb-item active" aria-current="page">Simulator</li>
            </ol>
          </nav>
        </Header>
        <div className='px-4'>
          <Simulator partners={partnersRequest.data.filter(p => p.active)}/>
        </div>
      </div>
    );
  } else {
    return (
      <SystemLayout selectedTab='simulator'>
        <Simulator partners={partnersRequest.data.filter(p => p.active)}/>
      </SystemLayout>
    );
  }
}
