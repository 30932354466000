import React from 'react';
import { Badge } from 'react-bootstrap';
import { CollectionStatuses, COLLECTIONS_STATUS_NAMES } from '../../utils/constants'

const COLLECTIONS_STATUS_BG_COLORS = {
  PERFORMING: 'bg-success',
  NON_PERFORMING: 'bg-danger',
  THIRD_PARTY: 'bg-dark',
  LEGAL: 'bg-dark',
  PAYMENT_PLAN: 'bg-dark',
  NOT_STARTED: '',
};

export default function LoanCollectionsStatusBadge ({ collectionsStatus }: { collectionsStatus?: CollectionStatuses }) {
  if (!collectionsStatus || collectionsStatus === 'NOT_STARTED') { return null; }
  const text = COLLECTIONS_STATUS_NAMES[collectionsStatus][1];
  const className = COLLECTIONS_STATUS_BG_COLORS[collectionsStatus];

  return (
    <Badge className={className} pill variant={ 'primary' }>
      { text }
    </Badge>
  );
}
