import React, { useState } from 'react';
import useStates from '../../../api/queries/states/useStates';
import { State } from '../../../api/queries/states/useState';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../../../pages/Search/NotebookTable';
import * as _ from 'lodash';
import InlineSortableTh from '../../InlineSortableTh';

function StatesTable ({ states }: { states: Array<State> }) {
  const [sortConfig, setSortConfig] = useState({ column: 'name', direction: 'asc' });
  const sortedStates = _.orderBy(
    states,
    [sortConfig.column],
    [sortConfig.direction as 'asc' | 'desc']
  );
  const handleSort = (column: string) => {
    setSortConfig(prevConfig => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };
  return <NotebookTable>
    <NotebookTableHeader>
      <InlineSortableTh sortBy='name' onSort={() => handleSort('name')} sortConfig={sortConfig}>Name</InlineSortableTh>
    </NotebookTableHeader>

    <tbody>
    {
      sortedStates.map(state => (
        <NotebookTableRow key={state._id} to={`/system/states/${state._id}`}>
          <td>{state.name}</td>
        </NotebookTableRow>
      ))
    }
    </tbody>
  </NotebookTable>;
}

export default function States () {
  const statesRequest = useStates();
  if (!statesRequest.data) {
    return null;
  }

  return (
    <section>
      <StatesTable states={statesRequest.data}/>
    </section>
  );
}
