import * as _ from 'lodash';
import InlineError from '../../components/InlineError';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useLoans, { Loan, LoanList } from '../../api/queries/loans/useLoans';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from '../Search/NotebookTable';
import moment from 'moment/moment';
import formatDollars from '../../utils/formatDollars';
import { trim } from 'lodash';
import formatPhone from '../../utils/formatPhone';
import { Header } from '../Layouts/Layout';
import SortableTh from '../../components/SortableTh';
import querystring from 'qs';

export default function Loans () {
  const { status } = useParams<{ status: string }>();
  const location = useLocation();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });

  const { data: loans, error } = useLoans(status, {
    sortBy: parsedParams.sortBy as string || '',
    sortDirection: parsedParams.sortDirection as string || '',
  });

  return (
    <div className='searchPage mb-5'>
      <Header>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-white m-0 p-0 align-items-center">
            <li className="breadcrumb-item">Loans</li>
            <li className="breadcrumb-item active" aria-current="page">{_.capitalize(status)}</li>
          </ol>
        </nav>
      </Header>
      <div>
        {error && <InlineError>Error loading results. Please try again.</InlineError>}
        {!loans && <p className='text-muted ml-4 mt-3'>Loading...</p> }
        {
          loans &&
          <>
            {
              loans.length === 0 ? <p className='ml-4 mt-3'>No results</p> : <LoansTable loans={loans} />
            }
          </>
        }
      </div>
    </div>
  );
}

function TimestampThForStatus () {
  const { status } = useParams<{ status: string }>();

  if (status === 'accepted') {
    return <SortableTh sortBy='accepted_at'>Accepted At</SortableTh>;
  } else if (status === 'authorized') {
    return <SortableTh sortBy='authorized_at'>Authorized At</SortableTh>;
  } else if (status === 'completed') {
    return <SortableTh sortBy='completed_at'>Completed At</SortableTh>;
  } else if (status === 'expired') {
    return <SortableTh sortBy='expired_at'>Expired At</SortableTh>;
  } else if (status === 'repaid') {
    return <SortableTh sortBy='repaid_date'>Repaid Date</SortableTh>;
  } else {
    return <SortableTh sortBy='created_at'>Created At</SortableTh>;
  }
}

function TimestampTdForStatus ({ loan }: { loan: Loan }) {
  const { status } = useParams<{ status: string }>();

  if (status === 'accepted') {
    return <td>{loan.acceptedAt ? moment(loan.acceptedAt).format('M/D/YYYY [at] h:mm a') : '--'}</td>;
  } else if (status === 'authorized') {
    return <td>{loan.authorizedAt ? moment(loan.authorizedAt).format('M/D/YYYY [at] h:mm a') : '--'}</td>;
  } else if (status === 'completed') {
    return <td>{loan.completedAt ? moment(loan.completedAt).format('M/D/YYYY [at] h:mm a') : '--'}</td>;
  } else if (status === 'expired') {
    return <td>{loan.expiredAt ? moment(loan.expiredAt).format('M/D/YYYY [at] h:mm a') : '--'}</td>;
  } else if (status === 'repaid') {
    return <td>{loan.repaidDate ? moment(loan.repaidDate).format('M/D/YYYY') : '--'}</td>;
  } else {
    return <td>{loan.createdAt ? moment(loan.createdAt).format('M/D/YYYY [at] h:mm a') : '--'}</td>;
  }
}

function cleanValue(val: string | null) {
  return !val ? '--' : val;
}

function LoansTable ({ loans }: { loans: LoanList }) {
  return (
    <>
      <NotebookTable>
        <NotebookTableHeader>
          <SortableTh sortBy='User.last_name'>Name</SortableTh>
          <SortableTh sortBy='User.phone'>Phone</SortableTh>
          <SortableTh sortBy='User.email'>Email</SortableTh>
          <SortableTh sortBy='principal'>Amount</SortableTh>
          <SortableTh sortBy='Partner.short_name'>Partner</SortableTh>
          <TimestampThForStatus/>
        </NotebookTableHeader>
        <tbody>
        {
          loans.map(loan => (
            <NotebookTableRow
              key={loan._id}
              to={`/loan-applications/${loan.loanApplicationId}/loan`}
            >
              <td>{cleanValue(trim(`${loan.firstName ?? ''} ${loan.lastName ?? ''}`))}</td>
              <td>{cleanValue(formatPhone(loan.phone))}</td>
              <td>{cleanValue(loan.email)}</td>
              <td>{formatDollars(loan.principalCents, { precision: 0, cents: true })}</td>
              <td>{loan.partnerShortName}</td>
              <TimestampTdForStatus loan={loan}/>
            </NotebookTableRow>)
          )
        }
        </tbody>
      </NotebookTable>
    </>
  );
}
