import React from 'react';
import { Flag } from '../../../../../api/queries/loan-applications/useUnderwritingFlags';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type LoanData = {
  loanApplicationId: string;
  slug: string;
}

export type PreviousNonPerformingLoansFlagData = {
  nonPerformingLoans: Array<LoanData>;
}

function LoanLink ({ nonPerformingLoan }: { nonPerformingLoan: LoanData }) {
  return (
    <Row>
      <Col>
        <Link to={`/loan-applications/${nonPerformingLoan.loanApplicationId}/loan`} className='text-muted'>
          { nonPerformingLoan.slug }
        </Link>
      </Col>
    </Row>
  );
}

export default function PreviousNonPerformingLoansFlagDetails ({ flag }: { flag: Flag<PreviousNonPerformingLoansFlagData> }) {
  return (
    <>
      {
        flag.data.nonPerformingLoans.map(nonPerformingLoan => (
          <LoanLink nonPerformingLoan={nonPerformingLoan} key={nonPerformingLoan.loanApplicationId}/>)
        )
      }
    </>
  );
}
