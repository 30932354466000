import useQuery from '../../shared/useQuery';

export enum CREDIT_MODE {
  DTI = 'dti',
  LTV = 'ltv',
}

export enum CONFIG_SOURCE_TYPE {
  FICO = 'fico',
  HOME_STATE = 'state',
  LISTING_STATE = 'listing_state',
  HOME_MSA = 'msa',
  LISTING_MSA = 'listing_msa',
}

export type Config = {
  _id: string;
  sourceType: CONFIG_SOURCE_TYPE;
  annualInterestRateBps: number;
  fixedOriginationFeeCents: number;
  originationFeeRateBps: number;
  originationFeeCapCents: number;
  originationFeeStrategy: string;
  originationFeeInterestAccrual: boolean;
  minCreditLimitCents: number;
  maxCreditLimitCents: number;
  maxListingPriceCents: number;
  maxDTI: number;
  maxUDTI: number;
  minBalanceCentsBucket: number;
  minAllowedFico: number;
}

export type FicoConfig = {
  minFicoBucket: number;
} & Config;

export type StateConfig = {
  state: string;
} & Config;

export type MsaConfig = {
  msa: string;
} & Config;

export type Portfolio = {
  _id: string;
  productId: string;
  key: string;
  priority: number;
  ficoConfigs: Array<FicoConfig>;
  homeStateConfigs: Array<StateConfig>;
  listingStateConfigs: Array<StateConfig>;
  homeMsaConfigs: Array<MsaConfig>;
  listingMsaConfigs: Array<MsaConfig>;
}

export type Product = {
  _id: string;
  name: string;
  active: boolean;
  autopayDiscountRateBps: number;
  creditMode: CREDIT_MODE;
  availableEquityRatio: number;
  availableEquityHaircutRatio: number;
  interestOnlyPeriods: number;
  zeroPaymentDuePeriods: number;
  periodFrequency: number;
  partner: string;
  partnerId: string;
  termPeriods: number;
  minFicoForStatedVerification: number;
  defaultMaxListingPriceCents: number;
  activatedAt: string;
  loanApplicationsCount: number;
  ficoConfigs: Array<FicoConfig>;
  homeStateConfigs: Array<StateConfig>;
  listingStateConfigs: Array<StateConfig>;
  homeMsaConfigs: Array<MsaConfig>;
  listingMsaConfigs: Array<MsaConfig>;
  portfolios: Array<Portfolio>;
  promoDiscountBps: number;
  promoDiscountPeriods: number;
  propertyValueRatio: number;
};

type Response = Product;

export default function useProduct (productId?: string) {
  return useQuery<Response>(productId ? `/notebook/products/${productId}` : null);
}
