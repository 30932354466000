import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NotebookTable ({ children }: { children: React.ReactNode }) {
  return (
    <Table className='searchTable'>
      {children}
    </Table>
  );
}

export function NotebookTableHeader ({ children }: { children: React.ReactNode }) {
  return (
    <>
      <thead className='tableHeader'>
      <tr className='text-muted'>
        {children}
      </tr>
      </thead>
    </>
  );
}

export function NotebookTableRow ({ children, to }: { children: React.ReactNode, key: string, to: string }) {
  return (
    <Link
      className='cursor-pointer tableRow'
      to={to}
    >
      {children}
    </Link>
  );
}
