import useQuery from '../../shared/useQuery';

// TYPES DUPLICATED FROM api/src/serializer/notebook/shared/types
export enum ServicingActivityStatus {
  CLEARED = 'Cleared',
  NEEDS_REVIEW = 'Needs Review',
  PROCESSING = 'Processing',
  RETURNED = 'Returned',
  SCHEDULED = 'Scheduled',
  VOIDED = 'Voided',
}
export enum ServicingActivityType {
  DISBURSEMENT = 'Disbursement',
  REPAYMENT = 'Repayment',
  REFUND = 'Refund',
}
export enum ServicingActivityMoneyFlowDirection {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
  BOOK = 'book',
}
export enum ServicingActivityBaseType {
  // activity objects
  CARD_ACTIVITY = 'CardActivity',
  CHECK_ACTIVITY = 'CheckActivity',
  ACH_ACTIVITY = 'ACHActivity',
  EXTERNAL_ACTIVITY = 'ExternalActivity',
  STATEMENT_ADJUSTMENT = 'StatementAdjustment',
  FEE_ACTIVITY = 'FeeActivity',
  ACCOUNTING_ACTIVITY = 'AccountingActivity',
  // intent objects
  PAYMENT = 'Payment',
  REFUND_REQUEST = 'RefundRequest',
}

export interface ServicingActivity {
  base_type: ServicingActivityBaseType;
  base_id: string; // use this to load the drawer
  originating_request_id: string;
  key: string;
  date: string;
  type: ServicingActivityType,
  direction: ServicingActivityMoneyFlowDirection,
  label: string;
  cents: number;
  status: ServicingActivityStatus;
}


export default function useActivity (loanId: string) {
  return useQuery<Array<ServicingActivity>>(`/notebook/loans/${loanId}/activity`);
}
