import React from 'react';
import { HOME_CLOSE_SOURCES, Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';

type HomeCloseFields = {
  homeCloseGoogleSheetId: string;
  homeCloseSftpCsvFileName: string;
  homeCloseAnywhereBrandCode: string;
  homeCloseSource: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerHomeCloseSettings (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: HomeCloseFields) {
    await callAPI({
      data: {
        homeCloseGoogleSheetId: data.homeCloseGoogleSheetId,
        homeCloseSftpCsvFileName: data.homeCloseSftpCsvFileName,
        homeCloseAnywhereBrandCode: data.homeCloseAnywhereBrandCode,
        homeCloseSource: data.homeCloseSource,
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object().shape({
    homeCloseSource: yup.string(),
    homeCloseGoogleSheetId: yup.string().when('homeCloseSource', {
      is: HOME_CLOSE_SOURCES.GOOGLE_SHEET,
      then: s => s.required('Google Sheet ID is required'),
    }),
    homeCloseSftpCsvFileName: yup.string().when('homeCloseSource', {
      is: HOME_CLOSE_SOURCES.CSV_SFTP,
      then: s => s.required('SFTP CSV File Name is required'),
    }),
    homeCloseAnywhereBrandCode: yup.string().when('homeCloseSource', {
      is: HOME_CLOSE_SOURCES.ANYWHERE_API,
      then: s => s.required('Anywhere Brand Code is required'),
    }),
  });

  const methods = useForm<HomeCloseFields>({
    defaultValues: {
      homeCloseGoogleSheetId: partner.homeCloseGoogleSheetId,
      homeCloseSftpCsvFileName: partner.homeCloseSftpCsvFileName,
      homeCloseAnywhereBrandCode: partner.homeCloseAnywhereBrandCode,
      homeCloseSource: partner.homeCloseSource,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { formState: { isSubmitting, isDirty }, watch } = methods;
  const homeCloseSource = watch('homeCloseSource');

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <Field label='Home Close Source' name='homeCloseSource' controlProps={{ as: 'select' }}>
        <option value=''>None</option>
        <option value={ HOME_CLOSE_SOURCES.GOOGLE_SHEET }>Google Sheet</option>
        <option value={ HOME_CLOSE_SOURCES.SEVEN_GABLES_API }>Seven Gables API</option>
        <option value={ HOME_CLOSE_SOURCES.CSV_SFTP }>CSV SFTP</option>
        <option value={ HOME_CLOSE_SOURCES.VANGUARD_API }>Vanguard API</option>
        <option value={ HOME_CLOSE_SOURCES.BRIGGS_FREEMAN_API }>Briggs Freeman API</option>
        <option value={ HOME_CLOSE_SOURCES.ANYWHERE_API }>Anywhere API</option>
        <option value={ HOME_CLOSE_SOURCES.BROWN_HARRIS_STEVENS_API }>Brown Harris Stevens API</option>
      </Field>

      { homeCloseSource === HOME_CLOSE_SOURCES.GOOGLE_SHEET &&
        <Field name='homeCloseGoogleSheetId' label='Home Closing Google Sheet ID'/>
      }
      { homeCloseSource === HOME_CLOSE_SOURCES.CSV_SFTP &&
        <Field name='homeCloseSftpCsvFileName' label='Home Closing SFTP CSV File Name'/>
      }
      { homeCloseSource === HOME_CLOSE_SOURCES.ANYWHERE_API &&
        <Field name='homeCloseAnywhereBrandCode' label='Home Closing Anywhere Brand Code'/>
      }

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
