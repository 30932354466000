export const PURCHASE_METHODS = {
  VOUCHER: 'voucher',
  VIRTUAL_CARD: 'virtual_card',
  PHYSICAL_CARD: 'physical_card',
  INVOICE: 'invoice',
  DISBURSEMENT_TO_BORROWER: 'disbursement_to_borrower',
};

export const LOAN_APPLICATION_STATUSES = {
  PENDING: 'PENDING',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const LOAN_APPLICATION_SOURCES = {
  BETTER: 'better',
  STATED: 'stated',
  CREDIT_REPORT: 'credit report'
};

export const LOAN_APPLICATION_PROPERTY_OWNERSHIP_TYPE = {
  OWNED: 'owned',
  BUYING: 'buying',
  RENTAL: 'rental',
  SELLING: 'selling',
};

export const DISBURSEMENT_TYPE = {
  CHECK: 'check',
  ACH: 'ach',
};

export const LOAN_STATES = {
  ACCEPTED    : 'ACCEPTED',
  APPROVED    : 'APPROVED',
  AUTHORIZED  : 'AUTHORIZED',
  COMPLETED   : 'COMPLETED',
  EXPIRED     : 'EXPIRED',
  FUNDED      : 'FUNDED',
  OFFERED     : 'OFFERED',
  PASTDUE     : 'PASTDUE',
  PENDING     : 'PENDING',
  REPAID      : 'REPAID',
};


export const MarqetaKYCStatus = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  PENDING: 'pending',
}


export const PURCHASE_METHOD_LABELS = {
  [PURCHASE_METHODS.VOUCHER]: 'Voucher',
  [PURCHASE_METHODS.VIRTUAL_CARD]: 'Virtual Card',
  [PURCHASE_METHODS.PHYSICAL_CARD]: 'Physical Card',
  [PURCHASE_METHODS.INVOICE]: 'Invoice',
  [PURCHASE_METHODS.DISBURSEMENT_TO_BORROWER]: 'ACH Disbursement',
};

export const PURCHASE_METHOD_STATUS = {
  CREATED             : 'CREATED',
  ACTIVE              : 'ACTIVE',
  FAILED_TO_ACTIVATE  : 'FAILED_TO_ACTIVATE',
  SUSPENDED           : 'SUSPENDED',
  TERMINATED          : 'TERMINATED',
};

export const FULFILLMENT_STATUSES = {
  ISSUED: 'ISSUED',
  ORDERED: 'ORDERED',
  SHIPPED: 'SHIPPED',
  DIGITALLY_PRESENTED: 'DIGITALLY_PRESENTED',
  REJECTED: 'REJECTED'
};


export const STATED_DEBT_TYPE = {
  FIRST_MORTGAGE   : 'first_mortgage',
  HOME_EQUITY_LOAN : 'home_equity_loan',
  REVERSE_MORTGAGE : 'reverse_mortgage',
  HELOC            : 'heloc',
  OTHER            : 'other',
  UNSURE           : 'unsure',
};

export const STATED_DEBT_TYPE_LABELS = {
  [STATED_DEBT_TYPE.FIRST_MORTGAGE]: 'Conventional Mortgage',
  [STATED_DEBT_TYPE.HOME_EQUITY_LOAN]: 'Home Equity Loan',
  [STATED_DEBT_TYPE.HELOC]: 'HELOC',
  [STATED_DEBT_TYPE.REVERSE_MORTGAGE]: 'Reverse Mortgage',
  [STATED_DEBT_TYPE.OTHER]: 'Other Debt',
  [STATED_DEBT_TYPE.UNSURE]: 'Unsure',
};

export const DEBTOR_RELATIONSHIP_LABELS = {
  spouse: 'Spouse',
  family_member: 'Family Member',
  business_partner: 'Business Partner',
  other: 'Other',
};

export const REASON_NO_MORTGAGE_LABELS = {
  mortgage_paid_off: 'The mortgage has been paid off',
  cash_purchase: 'The property was purchased in cash',
};

export const TIMELINETYPE = {
  REPORTED_DEBT: 'tradeline', // aka REPORTED_DEBT (keeping as "tradeline" for backwards compatibility with what API stores)
  TRANSACTION: 'transaction',
  LIENREPORT: 'lienreport',
  MANUAL: 'manual_record',
  CORELOGIC: 'corelogic',
};

export const REPORTED_DEBT_TYPES_OF_INTEREST = new Set([
  'commercial_mortgage',
  'construction_loan',
  'conventional_real_estate_mortgage',
  'credit_line_secured',
  'fha_real_estate_mortgage',
  'fmha_real_estate_mortgage',
  'government_secured',
  'government',
  'home_equity_loan',
  'home_improvement',
  'line_of_credit',
  'partly_secured',
  'real_estate_junior_liens',
  'real_estate_mortgage',
  'real_estate',
  'second_mortgage',
  'secured_home_improvement',
  'secured',
  'time_shared_loan',
  'v_a_real_estate_mortgage',
]);

export const TRADELINE_HELOC_TYPES = [
  'credit_line_secured',
  'line_of_credit',
];

export const TRADELINE_HOME_EQUITY_LOAN_TYPES = ['home_equity_loan']; // _could_ be a HELOC, must also check portfolioType

export enum PROPERTY_DEBT_HELOC_CALCULATOR_KEYS {
  CURRENT_BALANCE = 'currentBalance',
  CREDIT_LIMIT = 'creditLimit',
};

export const UNDERWRITING_OUTCOMES = {
  APPROVED: 'APPROVED',
  COUNTEROFFER: 'COUNTEROFFER',
  DENIED: 'DENIED',
  DELAYED_DENIAL: 'DELAYED_DENIAL',
  INELIGIBLE: 'INELIGIBLE',
  UNDECIDED: 'UNDECIDED',
  INCOMPLETE: 'INCOMPLETE',
  PREQUALIFIED: 'PREQUALIFIED',
}

export const DENIAL_CODES_INDICATING_INELIGIBLE = {
  INVALID_OWNER_ENTITY_TYPE: {
    explanation: 'Properties held in a corporation such as an LLC or C-Corp are not currently included in the Concierge Capital program.',
    reason: 'Property held in a corporation',
  },
  NO_POWER_OF_ATTORNEY: {
    explanation: 'Applicants must be a direct owner of the property or have a Power of Attorney for the owner.',
    reason: 'Applicant is not a direct owner of the property/has no power of attorney',
  },
  APPLICANT_IS_A_DEVELOPER : {
    explanation: 'Properties held by real-estate developers are not currently included in the Concierge Capital program.',
    reason: 'Applicant is a developer',
  },
  LOCATION_NOT_SUPPORTED: {
    explanation: 'The property you wish to improve is in a location not supported by the program.',
    reason: 'MSA or Zip is not supported',
  },
  OUT_OF_BOUNDS_REGION: {
    explanation: 'The property you are listing must be located in a state supported by the program.',
    reason: 'State is unsupported',
  },
  OUTSIDE_OF_US: {
    explanation: 'Applicants must currently reside in the U.S. to participate in the Concierge Capital program.',
    reason: 'Residency is outside of the U.S.',
  },
  DUPLICATE_APPLICATION: {
    explanation: 'The property address you wish to improve is associated with another application we have on file. We only allow one loan or application per property address.',
    reason: 'Duplicate application',
  },
  ONE_ACTIVE_ONLY: {
    explanation: 'Only one Concierge Capital loan is allowed per borrower at a time.',
    reason: 'Concierge Capital loan already in progress',
  },
  ONE_ACTIVE_APP_ONLY: {
    explanation: 'Only one Concierge Capital loan application is allowed per borrower at a time.',
    reason: 'Concierge Capital application already in progress',
  },
  PROPERTY_IS_COMMERCIAL: {
    explanation: 'Commercial properties not currently included in the Concierge Capital program.',
    reason: 'Property is a commercial property',
  },
  LISTING_OVER_QUALIFIED_PRICE: {
    explanation: 'Properties with a list price of more than $3,000,000 ($4,000,000 in California) are not currently included in the Concierge Capital program.',
    reason: 'List price over $3M ($4M in CA)',
  },
  LOAN_APPLICATION_FROM_AGENT: {
    explanation: 'Compass Agents are no longer eligible for the Concierge Capital program. Please contact Compass for more information.',
    reason: 'Agent application',
  },
  APPLICANT_IS_NOT_A_TRUSTEE: {
    explanation: 'Applicants must be a trustee when a listed property is owned in a trust.',
    reason: 'Applicant is not a trustee.',
  }
};

export const USER_NOTIFICATION_TYPES = {
  ACCELERATION_CONFIRMATION_REMINDER: 'ACCELERATION_CONFIRMATION_REMINDER',
  ACCELERATION_HOME_CLOSE_INVOICE: 'ACCELERATION_HOME_CLOSE_INVOICE',
  REPAYMENT_REMINDER: 'REPAYMENT_REMINDER',
  REPAYMENT_REMINDER_LETTER: 'REPAYMENT_REMINDER_LETTER',
  TERM_REMINDER_0: 'TERM_REMINDER_0',
  TERM_REMINDER_30_14_5_3: 'TERM_REMINDER_30_14_5_3',
  TERM_REMINDER_90_60: 'TERM_REMINDER_90_60',
};

export const REPAYMENT_STATUS_NAMES = {
  NOT_DUE: ['default', 'Not Due'],
  GRACE_PERIOD: ['danger', 'Grace Period'],
  DELINQUENT: ['danger', 'Delinquent'],
  DEFAULTED: ['danger', 'Defaulted'],
};

export const COLLECTIONS_STATUS_NAMES = {
  NOT_STARTED: ['default', '—'],
  PERFORMING: ['success', 'Performing'],
  NON_PERFORMING: ['danger', 'Non-Performing'],
  THIRD_PARTY: ['warning', 'Sent to Third-Party collections'],
  LEGAL: ['warning', 'Sent to Legal'],
  PAYMENT_PLAN: ['warning', 'Payment Plan'],
};

export type CollectionStatuses = keyof typeof COLLECTIONS_STATUS_NAMES;

export const SPECIAL_PROVISIONS = {
  DO_NOT_CALL             : 'DO_NOT_CALL',
  LAWYER_REPRESENTATIVE   : 'LAWYER_REPRESENTATIVE',
  BANKRUPTCY              : 'BANKRUPTCY',
  BORROWER_DECEASED       : 'BORROWER_DECEASED',
}

export const SPECIAL_PROVISIONS_NAMES = {
  [SPECIAL_PROVISIONS.DO_NOT_CALL]: ['danger', 'Do Not Call'],
  [SPECIAL_PROVISIONS.LAWYER_REPRESENTATIVE]: ['muted', 'Lawyer Representative'],
  [SPECIAL_PROVISIONS.BANKRUPTCY]: ['warning', 'Bankruptcy'],
  [SPECIAL_PROVISIONS.BORROWER_DECEASED]: ['muted', 'Borrower Deceased'],
};

export const USE_OF_FUND_OPTIONS = [
  {
    label: 'Bathroom',
    value: 'BATHROOM',
  },
  {
    label: 'Kitchen',
    value: 'KITCHEN',
  },
  {
    label: 'Flooring',
    value: 'FLOORING',
  },
  {
    label: 'Landscaping',
    value: 'LANDSCAPING',
  },
  {
    label: 'Moving & Storage',
    value: 'MOVING_STORAGE',
  },
  {
    label: 'Painting',
    value: 'PAINTING',
  },
  {
    label: 'Staging',
    value: 'STAGING',
  },
  {
    label: 'Cleaning',
    value: 'CLEANING',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const ENTITY_TYPES = {
  LLC: 'llc',
  CORPORATION: 'corporation',
  TRUST: 'trust',
  ESTATE: 'estate',
  PARTNERSHIP: 'partnership',
};

export const ENTITY_TYPE_LABELS = {
  [ENTITY_TYPES.LLC]: 'LLC',
  [ENTITY_TYPES.CORPORATION]: 'Corporation',
  [ENTITY_TYPES.TRUST]: 'Trust',
  [ENTITY_TYPES.ESTATE]: 'Estate',
  [ENTITY_TYPES.PARTNERSHIP]: 'Partnership',
};

export const BUSINESS_ENTITIES = [ENTITY_TYPES.LLC, ENTITY_TYPES.CORPORATION, ENTITY_TYPES.PARTNERSHIP];

export const ALL_US_STATES = [
  { abbr: 'AL', name: 'Alabama' },
  { abbr: 'AK', name: 'Alaska' },
  { abbr: 'AZ', name: 'Arizona' },
  { abbr: 'AR', name: 'Arkansas' },
  { abbr: 'CA', name: 'California' },
  { abbr: 'CO', name: 'Colorado' },
  { abbr: 'CT', name: 'Connecticut' },
  { abbr: 'DE', name: 'Delaware' },
  { abbr: 'DC', name: 'District Of Columbia' },
  { abbr: 'FL', name: 'Florida' },
  { abbr: 'GA', name: 'Georgia' },
  { abbr: 'HI', name: 'Hawaii' },
  { abbr: 'ID', name: 'Idaho' },
  { abbr: 'IL', name: 'Illinois' },
  { abbr: 'IN', name: 'Indiana' },
  { abbr: 'IA', name: 'Iowa' },
  { abbr: 'KS', name: 'Kansas' },
  { abbr: 'KY', name: 'Kentucky' },
  { abbr: 'LA', name: 'Louisiana' },
  { abbr: 'ME', name: 'Maine' },
  { abbr: 'MD', name: 'Maryland' },
  { abbr: 'MA', name: 'Massachusetts' },
  { abbr: 'MI', name: 'Michigan' },
  { abbr: 'MN', name: 'Minnesota' },
  { abbr: 'MS', name: 'Mississippi' },
  { abbr: 'MO', name: 'Missouri' },
  { abbr: 'MT', name: 'Montana' },
  { abbr: 'NE', name: 'Nebraska' },
  { abbr: 'NV', name: 'Nevada' },
  { abbr: 'NH', name: 'New Hampshire' },
  { abbr: 'NJ', name: 'New Jersey' },
  { abbr: 'NM', name: 'New Mexico' },
  { abbr: 'NY', name: 'New York' },
  { abbr: 'NC', name: 'North Carolina' },
  { abbr: 'ND', name: 'North Dakota' },
  { abbr: 'OH', name: 'Ohio' },
  { abbr: 'OK', name: 'Oklahoma' },
  { abbr: 'OR', name: 'Oregon' },
  { abbr: 'PA', name: 'Pennsylvania' },
  { abbr: 'PR', name: 'Puerto Rico' },
  { abbr: 'RI', name: 'Rhode Island' },
  { abbr: 'SC', name: 'South Carolina' },
  { abbr: 'SD', name: 'South Dakota' },
  { abbr: 'TN', name: 'Tennessee' },
  { abbr: 'TX', name: 'Texas' },
  { abbr: 'UT', name: 'Utah' },
  { abbr: 'VT', name: 'Vermont' },
  { abbr: 'VA', name: 'Virginia' },
  { abbr: 'WA', name: 'Washington' },
  { abbr: 'WV', name: 'West Virginia' },
  { abbr: 'WI', name: 'Wisconsin' },
  { abbr: 'WY', name: 'Wyoming' },
];

export const SUPPORTING_DOCUMENT_TYPES = [
  { label: 'Driver License'           , value: 'driver_license' },
  { label: 'ID Card'                  , value: 'id_card' },
  { label: 'Utility Bill'             , value: 'utility_bill' },
  { label: 'Bank Statement'           , value: 'bank_statement' },
  { label: 'Current Rental Agreement' , value: 'current_rental_agreement' },
  { label: 'Lease Agreement'          , value: 'lease_agreement' },
  { label: 'Mortgage Statement'       , value: 'mortgage_statement' },
  { label: 'Insurance Statement'      , value: 'insurance_statement' },
  { label: 'Military ID Card'         , value: 'military_id_card' },
  { label: 'Passport'                 , value: 'passport' },
  { label: 'Social Security Card'     , value: 'social_security_card' },
  { label: 'W-2'                      , value: 'w_2' },
  { label: 'Form 1099'                , value: 'form_1099' },
];
