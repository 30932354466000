import React from 'react';
import { Flag } from '../../../../../api/queries/loan-applications/useUnderwritingFlags';

export type CreditReportChargeoffFlagData = {
  count: number;
}

export default function CreditReportChargeoffFlagDetails ({ flag }: { flag: Flag<CreditReportChargeoffFlagData> }) {
  return (
    <div className='text-secondary'>
      {flag.data.count} Chargeoff{flag.data.count !== 1 ? 's' : ''}
    </div>
  );
}
