import React from 'react';

const NotableRed = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="url(#paint0_linear_502_1488)"/>
    <path d="M17.6553 5.30225H14.9488V13.7671L8.98552 5.30225H6.34497V18.6976H9.05153V9.77463L15.4329 18.6976H17.6553V5.30225Z" fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_502_1488" x1="4.13793" y1="1.25581" x2="20.523" y2="23.8205" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B3154C"/>
        <stop offset="1" stopColor="#840E37"/>
      </linearGradient>
    </defs>
  </svg>
);

export default NotableRed;
