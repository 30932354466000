import useQuery from '../../shared/useQuery';
import { Portfolio } from '../portfolios/usePortfolio';
export type { Portfolio };



type Response = Array<Portfolio>;

export default function usePortfolios (partnerId?: string) {
  return useQuery<Response>(partnerId ? `/notebook/partners/${partnerId}/portfolios` : null);
}
