import React from 'react';
import { Badge } from 'react-bootstrap';

export default function LoanPastDueDaysBadge ({ days }: { days: number }) {
  const variant = days < 30 ? 'info' : 'danger';
  return (
    <Badge pill variant={ variant }>
      { days } { days > 1 ? 'Days' : 'Day' } Past Due
    </Badge>
  );
}
