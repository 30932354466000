import useQuery from '../../shared/useQuery';

export type ReportSummary = {
  _id: string;
  report_date: string;
  type: string;
  portfolio_key: string;
  investor_key: string;
  isDelivered: boolean;
  deliveredAt: string;
};

export type ReportDetail = ReportSummary & {
  files: Array<{
    _id: string;
    created_at: string;
    updated_at: string;
    filename: string;
    generated_at: string;
    delivered_at: string;
    archived_at: string;
    deliveries: Array<{
      _id: string;
      created_at: Date;
      updated_at: Date;
      delivery_type: string;
      destination: string;
      delivered_at: Date;
      failed_at: Date;
    }>;
  }>;
};


export function useInvestorReports (investor_key: string, page: number) {
  return useQuery<Array<ReportSummary>>(`/notebook/reports/?investor_key=${ investor_key }&page=${ page }`);
}

export function useReport (id: string) {
  return useQuery<ReportDetail>(`/notebook/reports/${ id }`);
}
