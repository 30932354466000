import React from 'react';
import { AGENT_PULL_STRATEGY, Partner } from '../../../api/queries/partners/usePartner';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAdminAPICall from '../../../utils/useAdminAPICall';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import SubmitError from '../../Form/SubmitError';
import { Button } from 'react-bootstrap';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import BooleanRadioField from '../../Form/BooleanRadioField';

type PartnerInfoFields = {
  agentSupport: string;
  agentPricingPaymentType: string;
  agentPullStrategy: string;
  agentRosterAnywhereBrandCode: string;
  promoStartDate: string;
  promoEndDate: string;
  remoteAgentGoogleSheetId: string;
  emailDomain: string;
  agentsUrl: string;
  agentLandingPageEnabled: string;
};

type Props = {
  partner: Partner;
  onSubmit: () => void;
  onHide: () => void;
}

export default function EditPartnerAgentSettings (props: Props) {
  const { partner, onSubmit, onHide } = props;

  const { callAPI } = useAdminAPICall({
    endpoint: `/notebook/partners/${partner._id}`,
    method: 'PUT',
  });

  async function handleSubmit(data: PartnerInfoFields) {
    await callAPI({
      data: {
        agentSupport: data.agentSupport === 'true',
        agentPricingPaymentType: data.agentPricingPaymentType,
        agentPullStrategy: data.agentPullStrategy,
        agentRosterAnywhereBrandCode: data.agentRosterAnywhereBrandCode,
        promoStartDate: data.promoStartDate,
        promoEndDate: data.promoEndDate,
        remoteAgentGoogleSheetId: data.remoteAgentGoogleSheetId,
        emailDomain: data.emailDomain,
        agentsUrl: data.agentsUrl,
        agentLandingPageEnabled: data.agentLandingPageEnabled === 'true',
      },
    });
    onSubmit();
    onHide();
  }

  const schema = yup.object();

  const methods = useForm<PartnerInfoFields>({
    defaultValues: {
      agentSupport: partner.agentSupport.toString(),
      agentPullStrategy: partner.agentPullStrategy,
      agentPricingPaymentType: partner.agentPricingPaymentType,
      agentRosterAnywhereBrandCode: partner.agentRosterAnywhereBrandCode,
      promoStartDate: partner.promoStartDate,
      promoEndDate: partner.promoEndDate,
      remoteAgentGoogleSheetId: partner.remoteAgentGoogleSheetId,
      emailDomain: partner.emailDomain,
      agentsUrl: partner.agentsUrl,
      agentLandingPageEnabled: partner.agentLandingPageEnabled.toString(),
    },
    resolver: yupResolver(schema),
  });
  const { formState: { isSubmitting, isDirty }, watch } = methods;

  return <div>
    <Form {...methods} onSubmit={handleSubmit}>
      <BooleanRadioField label='Supports Agents' name='agentSupport'/>
      <Field label='Agent Pull Strategy' name='agentPullStrategy' controlProps={{ as: 'select' }}>
        <option value=''>None</option>
        <option value={AGENT_PULL_STRATEGY.GOOGLE_SHEET}>Google Sheet</option>
        <option value={AGENT_PULL_STRATEGY.ANYWHERE_API}>Anywhere API</option>
        <option value={AGENT_PULL_STRATEGY.CUSTOM}>Custom</option>
      </Field>
      { watch('agentPullStrategy') === AGENT_PULL_STRATEGY.GOOGLE_SHEET &&
        <Field name='remoteAgentGoogleSheetId' label='Remote Agent Google Sheet ID'/>
      }
      { watch('agentPullStrategy') === AGENT_PULL_STRATEGY.ANYWHERE_API &&
        <Field name='agentRosterAnywhereBrandCode' label='Anywhere Brand Code'/>
      }
      <Field label='Agent Pricing Payment Type' name='agentPricingPaymentType' controlProps={{ as: 'select' }}>
        <option value=''>None</option>
        <option value='deferred'>Deferred</option>
        <option value='origination'>Origination</option>
        <option value='free_to_agent'>Free-To-Agent</option>
        <option value='no_fee'>No Fee</option>
      </Field>
      <Field name='promoStartDate' label='Promo Start Date' controlProps={{ type: 'date' }}/>
      <Field name='promoEndDate' label='Promo End Date' controlProps={{ type: 'date' }}/>
      <Field label='Email Domain' name='emailDomain'/>
      <Field name='agentsUrl' label='Agents URL'/>
      <BooleanRadioField label='Personalized Landing Pages' name='agentLandingPageEnabled'/>

      <SubmitError/>

      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={onHide} className="mr-2">
          Cancel
        </Button>
        <ButtonWithSpinner variant="primary" type="submit" disabled={!isDirty} loading={isSubmitting}>
          Confirm
        </ButtonWithSpinner>
      </div>
    </Form>
  </div>;
}
