import React from 'react';
import { Button } from 'react-bootstrap';
import './InlineButton.css';

export type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  'data-qa'?: string;
  disabled?: boolean;
};

export default function InlineButton (props: Props) {
  return (
    <Button
      tabIndex        = {0}
      role            = 'button'
      variant         = 'link'
      className       = {`-inline ${ props.className || '' }`}
      data-qa         = { props['data-qa'] }
      onClick         = { props.onClick }
      disabled        = { props.disabled }
    >{
        props.children
      }</Button>
  );
}
