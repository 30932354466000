import React from 'react';
import { Flag } from '../../../../../api/queries/loan-applications/useUnderwritingFlags';

export type SettledTradelinesFlagData = {
  amount: number;
  within_last_months: number;
}

export default function SettledTradelinesFlagDetails ({ flag }: { flag: Flag<SettledTradelinesFlagData> }) {
  return (
    <div className='text-secondary'>
      {`${flag.data.amount} in last ${flag.data.within_last_months} months`}
    </div>
  );
}
