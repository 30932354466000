import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Table, Pagination, InputGroup, FormControl, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import querystring from 'qs';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import InlineError from '../../components/InlineError';
import useAdminAPICall from '../../utils/useAdminAPICall';
import { useForm } from 'react-hook-form';
import formatDollars from '../../utils/formatDollars';
import AdminContext from '../../adminContext';
import { trim } from 'lodash';
import './Search.css';
import formatPhone from '../../utils/formatPhone';
import moment from 'moment';
import Close from '../../images/Close';
import SearchImage from '../../images/Search';
import NotebookTable, { NotebookTableHeader, NotebookTableRow } from './NotebookTable';
import { Header } from '../Layouts/Layout';
import useMutateNotebookVersion from '../../api/mutations/useMutateNotebookVersion';
import SortableTh from '../../components/SortableTh';

const SEARCH_PAGE_SIZE = 50;

type LoanApplication = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  partnerShortName: string;
  agentDisplayName: string;
  _id: string;
  principal: number;
  loanSlug: string | null;
  status: string;
  createdAt: string;
};

type NotebookSearch = {
  searchString: string;
  firstSearchedAt: Date;
  lastSearchedAt: Date;
}

function TooltipTd ({ content, bold }: { content: string, bold?: boolean }) {
  return <td>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={content}>{content}</Tooltip>}
      trigger='hover'
    >
      <span className={bold ? 'font-weight-strong' : ''}>{content}</span>
    </OverlayTrigger>
  </td>
}

type SearchPaginationProps = {
  activePage: number;
  nextPage: boolean;
  onClick: (b: boolean) => void;
};

function SearchPagination(props: SearchPaginationProps) {
  const { activePage, nextPage, onClick } = props;

  return (
    <Pagination className='searchPagination'>
      <Pagination.Prev onClick={() => onClick(false)} disabled={activePage === 1} />
      <Pagination.Next onClick={() => onClick(true)} disabled={!nextPage} />
    </Pagination>
  );
}

function cleanValue(val: string | null) {
  return !val ? '--' : val;
}

function SwitchNotebookVersionButton ({ enableV2 }: { enableV2: boolean }) {
  const { mutate } = useMutateNotebookVersion();
  const onSwitchNotebookVersion = async () => {
    await mutate({ enableV2 });
    window.location.reload();
  }

  return (
    <Button variant='outline-dark' className='switchButton btn-sm' onClick={onSwitchNotebookVersion}>Switch to Notebook v{enableV2 ? '2' : '1'}</Button>
  );
}

export default function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedParams = querystring.parse(location.search, { ignoreQueryPrefix: true });

  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState<Array<LoanApplication>>([]);
  const [activePage, setActivePage] = useState(1);
  const [recentSearches, setRecentSearches] = useState<Array<NotebookSearch>>([]);
  const [showRecentSearches, setShowRecentSearches] = useState(false);

  const { register, handleSubmit, setValue, resetField, setFocus, formState: { errors } } = useForm<{ searchText: string }>();
  const { callAPI, loadError, loadPending, loadComplete } = useAdminAPICall<Array<LoanApplication>>({
    endpoint: `/notebook/loan-applications/search`,
  });
  const { callAPI: getRecentSearches } = useAdminAPICall<Array<NotebookSearch>>({
    endpoint: '/notebook/loan-applications/recent-searches',
  });

  useEffect(() => { document.title = 'Home | Notebook' }, []);

  // eslint react-hooks/exhaustive-deps is disabled bc the effect should only fire when the url updates
  useEffect(() => {
    let canceled = false;
    const page = parsedParams.page ? parseInt(parsedParams.page as string) : 1;
    const text = parsedParams.searchText ? parsedParams.searchText as string : '';
    const sortBy = parsedParams.sortBy ? parsedParams.sortBy as string : undefined;
    const sortDirection = parsedParams.sortDirection ? parsedParams.sortDirection as string : undefined;
    const config = {
      params: {
        searchText: text,
        sortBy,
        sortDirection,
        page,
        perPage: SEARCH_PAGE_SIZE,
      }
    };

    if (text) {
      callAPI(config).then((resp) => {
        if (!canceled) { setSearchResults(resp.data) }
      });

    } else {
      setSearchResults([])
    }

    setValue('searchText', text);
    setSearchTerms(text as string);
    setActivePage(page);

    return () => { canceled = true }
  }, [parsedParams.searchText, parsedParams.page, parsedParams.sortBy, parsedParams.sortDirection]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSearchUrl = (searchText = '', page = 1, sortBy = '', sortDirection = '', clear = false) => {
    setShowRecentSearches(false);

    if (searchText) {
      const encodedSearchText = encodeURIComponent(searchText);
      const pageParam = page > 1 ? '&page=' + page : '';
      const sortByParam = sortBy ? `&sortBy=${sortBy}` : '';
      const sortDirectionParam = sortDirection ? `&sortDirection=${sortDirection}` : '';
      if (location.search !== `?searchText=${encodedSearchText}${pageParam}${sortByParam}${sortDirectionParam}`) {
        navigate(`?searchText=${encodedSearchText}${pageParam}${sortByParam}${sortDirectionParam}`);
      }
    } else if (clear) {
      navigate(``)
    }
  }

  const handlePagination = (nextPage: boolean) => {
    const newActivePage = nextPage ? activePage + 1 : activePage - 1;
    const sortBy = parsedParams.sortBy ? parsedParams.sortBy as string : undefined;
    const sortDirection = parsedParams.sortDirection ? parsedParams.sortDirection as string : undefined;
    setSearchUrl(searchTerms, newActivePage, sortBy, sortDirection);
  }

  const clearSearch = async () => {
    resetField('searchText');
    setSearchUrl('', 1, '', '', true);
    setSearchResults([]);
    setActivePage(1);
    setSearchTerms('');
    setFocus('searchText');
    await fetchAndDisplayRecentSearches(true);
  }

  const fetchAndDisplayRecentSearches = async (forceDisplay?: boolean) => {
    // Do not display recent searches if there are already search results
    if (!searchResults.length || forceDisplay) {
      setShowRecentSearches(true);
      const { data: searches } = await getRecentSearches();
      setRecentSearches(searches);
    }
  }

  // Upon loading the page, don't show recent searches if there is a search term in the URL
  // or if search bar is not empty
  const handleFocusOnSearchBar = async (event: any) => {
    if (!event.target.value && !parsedParams.searchText) {
      await fetchAndDisplayRecentSearches();
    }
  }

  const chooseRecentSearch = (searchString: string) => {
    setValue('searchText', searchString);
    setSearchUrl(searchString, 1, '', '', true);
  }

  const handleInputChange = async (event: any) => {
    setValue('searchText', event.target.value);
  }

  const getSearchTable = () => {
    return (
      <div className={`resultsTable mb-5`}>
        {searchResults.length === 0 ?
          <h4 data-emptyresults>
            {parsedParams.page ? 'No more results for ' : 'Sorry, nothing matches your search criteria: '}
            "{searchTerms}"
          </h4>
        : <>
            <p>Search results for "{searchTerms}"</p>
            <Table responsive striped bordered hover size="small">
              <thead>
                <tr>
                    <th>Loan Slug</th>
                    <th>Partner</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Agent Name</th>
                    <th>Principal Amount</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map(result => {
                  return (<tr
                    key={`searchResult-${ result.userId + '-' + result._id}`}
                    className='cursor-pointer'
                    onClick={() => { window.location.href = `/loan-applications/${result._id}${result.loanSlug ? '/loan' : ''}`; } }
                  >
                    <td>{cleanValue(result.loanSlug)}</td>
                    <td>{result.partnerShortName}</td>
                    <td>{result.status}</td>
                    <TooltipTd content={cleanValue(trim(`${result.firstName ?? ''} ${result.lastName ?? ''}`))}/>
                    <TooltipTd content={cleanValue(result.phone)}/>
                    <TooltipTd content={cleanValue(result.email)}/>
                    <TooltipTd content={cleanValue(result.agentDisplayName)}/>
                    <td>{formatDollars(result.principal)}</td>
                  </tr>);
                })}
              </tbody>
            </Table>
          </>
        }
        {(searchResults.length === SEARCH_PAGE_SIZE || parsedParams.page) &&
          <SearchPagination
            onClick={handlePagination}
            activePage={activePage}
            nextPage={searchResults.length === SEARCH_PAGE_SIZE}
          />
        }
      </div>
    );
  }

  const adminContext = useContext(AdminContext);
  if (adminContext.sidebarLayoutEnabled) {
    return (
      <div className='searchPage mb-5'>
        <Header>
          <form onSubmit={handleSubmit(data => setSearchUrl(data.searchText, 1, '', '', true))}>
            <Form.Group
              controlId="searchBar"
              className='mb-0'
            >
              <InputGroup>
                <InputGroup.Prepend className='align-items-center'>
                  <button className='searchSubmit' type='submit'>
                    <SearchImage />
                  </button>
                </InputGroup.Prepend>
                <FormControl
                  placeholder='Search Notebook...'
                  autoFocus
                  {...register('searchText', { required: true })}
                  className='searchInput'
                  onFocus={handleFocusOnSearchBar}
                  onChange={handleInputChange}
                />
                { searchTerms &&
                  <InputGroup.Append className='align-items-center cursor-pointer' onClick={clearSearch}>
                    <Close />
                  </InputGroup.Append>
                }
              </InputGroup>

              {
                !!errors.searchText && <Form.Text className="text-danger">
                  Please enter at least one term for search
                </Form.Text>
              }
            </Form.Group>
          </form>
        </Header>

        { showRecentSearches && recentSearches.length > 0 &&
          <div className='mt-4 mb-4 pl-4'>
            <div className='mb-2 font-weight-strong'>Recent searches</div>
            <div>
              {
                recentSearches.map(search => (
                  <div
                    className='recentSearchesRow'
                    key={ search.searchString }
                    onClick={ () => chooseRecentSearch(search.searchString) }
                  >
                    <SearchImage pathProps={{ fill: '#6c757d' }} /><span className='recentSearchesSpan'>{ search.searchString }</span>
                  </div>)
                )
              }
            </div>
          </div>
        }
        <div>
          {loadPending && <div className='mt-2 pl-4 text-muted'>Loading...</div> }
          {loadError && <InlineError className='pl-4'>Error loading search results. Please clear your search and try again.</InlineError>}
          {(loadComplete && searchTerms) &&
            <>
              {searchResults.length === 0 ?
                <div data-emptyresults className='mt-2 pl-4 text-muted'>
                  {parsedParams.page ? 'No more results for ' : 'No results for: '}
                  "{searchTerms}"
                </div>
              : <SearchTable searchResults={searchResults} />
              }
              {(searchResults.length === SEARCH_PAGE_SIZE || parsedParams.page) &&
                <SearchPagination
                  onClick={handlePagination}
                  activePage={activePage}
                  nextPage={searchResults.length === SEARCH_PAGE_SIZE}
                />
              }
            </>
          }
        </div>

        { searchResults.length === 0 &&
          <SwitchNotebookVersionButton enableV2={false} />
        }
      </div>
    );
  }

  return (
    <Container className={`searchPage mt-5 mb-5`}>
      <Row>
        <Col>
          <h2 className={`pt-3`}>Search</h2>
          <form className="pb-5" onSubmit={handleSubmit(data => setSearchUrl(data.searchText, 1, '', '', true))}>
            <Form.Group
              controlId="searchBar"
            >
              <InputGroup>
                <FormControl
                  className="form-control-lg"
                  placeholder='Search by loan slug, user id, first or last name, email, or phone'
                  autoFocus
                  {...register('searchText', { required: true })}
                />
                <InputGroup.Append>
                  <Button variant="secondary" onClick={clearSearch}>Clear</Button>
                  <Button variant="primary" type="submit">Search</Button>
                </InputGroup.Append>
              </InputGroup>

              {
                !!errors.searchText && <Form.Text className="text-danger">
                  Please enter at least one term for search
                </Form.Text>
              }
            </Form.Group>
          </form>
        </Col>
      </Row>
      <Row>
        <Col>
          {loadPending && <h4>Loading... <InlineLoadingIndicator /></h4> }
          {loadError && <InlineError>Error loading search results. Please clear your search and try again.</InlineError>}
          {(loadComplete && searchTerms) && getSearchTable() }
        </Col>
      </Row>

      { searchResults.length === 0 &&
        <SwitchNotebookVersionButton enableV2={true}/>
      }
    </Container>
  );
}

function SearchTable ({ searchResults }: { searchResults: Array<LoanApplication> }) {
  return (
    <NotebookTable>
      <NotebookTableHeader>
        <SortableTh sortBy='last_name'>Name</SortableTh>
        <SortableTh sortBy='phone'>Phone</SortableTh>
        <SortableTh sortBy='email'>Email</SortableTh>
        <SortableTh sortBy='principal'>Amount</SortableTh>
        <SortableTh sortBy='status'>Status</SortableTh>
        <SortableTh sortBy='Agent.last_name'>Agent</SortableTh>
        <SortableTh sortBy='Partner.short_name'>Partner</SortableTh>
        <SortableTh sortBy='created_at'>Created</SortableTh>
      </NotebookTableHeader>
      <tbody>
      {
        searchResults.map(result => (
          <NotebookTableRow
            key={`searchResult-${ result.userId + '-' + result._id}`}
            to={`/loan-applications/${result._id}${result.loanSlug ? '/loan' : ''}`}
          >
            <td className='font-weight-strong'>{cleanValue(trim(`${result.firstName ?? ''} ${result.lastName ?? ''}`))}</td>
            <td>{cleanValue(formatPhone(result.phone))}</td>
            <td>{cleanValue(result.email)}</td>
            <td>{formatDollars(result.principal, { precision: 0 })}</td>
            <td>{cleanValue(result.status)}</td>
            <td>{cleanValue(result.agentDisplayName)}</td>
            <td>{result.partnerShortName}</td>
            <td>{moment(result.createdAt).format('M/D/YYYY [at] h:mm a')}</td>
          </NotebookTableRow>)
        )
      }
      </tbody>
    </NotebookTable>
  );
}
