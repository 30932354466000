import useQuery from '../../shared/useQuery';
import { State } from '../partners/usePartner';
import { InternalBankAccount } from '../investors/useInternalBankAccounts';


export type Portfolio = {
  automatedInvestorPurchasesEnabled: boolean;
  automatedInvestorRemittancesEnabled: boolean;
  automatedPortfolioTransferEnabled: boolean;
  cardPrefundingStartDate: string;
  checkDisbursementAccount: InternalBankAccount | null;
  collectionAccount: InternalBankAccount | null;
  description: string;
  disbursementAccount: InternalBankAccount | null;
  drawPeriods: number;
  externalName: string;
  fallbackPortfolio: Portfolio | null;
  investor: { key: string };
  isFallbackPortfolio: boolean;
  key: string;
  name: string;
  originationFeeSaleStrategy: string;
  purchasePremiumBps: number;
  servicingFeeAprBps: number;
  states: Array<State>;
  transferReviewDays: number;
  wireAccount: InternalBankAccount | null;
}

export function usePortfolio (portfolioKey: string) {
  return useQuery<Portfolio>(`/notebook/portfolios/${ portfolioKey }`);
}
