import React from 'react';
import style from './UnderwritingTab.module.scss';
import MonthlyDebtFact, { MonthlyDebtFactData } from './facts/MonthlyDebtFact';
import BaseFact from './facts/BaseFact';
import { Card, Col, Row } from 'react-bootstrap';
import { Fact } from '../../../api/queries/loan-applications/useUnderwritingFacts';
import MonthlyIncomeFact, { MonthlyIncomeFactData } from './facts/MonthlyIncomeFact';
import ManualVerificationFact from './facts/ManualVerificationFact';
import ExtendedUnsecuredDebtFact, { ExtendedUnsecuredDebtFactData } from './facts/ExtendedUnsecuredDebtFact';
import PropertyOwnedByFact from './facts/PropertyOwnedByFact';
import PropertyListingPriceFact, { PropertyListingPriceFactData } from './facts/PropertyListingPriceFact';
import PropertyDebtFact, { PropertyDebtFactData } from './facts/PropertyDebtFact';
import PropertyListingAddressFact, { PropertyListingAddressFactData } from './facts/PropertyListingAddressFact';
import AgentAuthorizationFact, { AgentAuthorizationFactData } from './facts/AgentAuthorizationFact';
import RequestedPrincipalFact, { RequestedPrincipalFactData } from './facts/RequestedPrincipalFact';
import FicoFact, { FicoFactData } from './facts/FicoFact';
import { useExpandableState } from '../../../utils';

export type FactProps = {
  fact: Fact;
  onUpdate: () => Promise<void>;
  showEditButton?: boolean;
}

export type FactEditModalProps = {
  onSubmit: () => Promise<void>;
  expand: ReturnType<typeof useExpandableState>;
  submitUrl: string;
}

function FactRow ({ fact, onUpdate, showEditButton }: FactProps) {
  if (fact.type === 'agent_authorization') {
    return <AgentAuthorizationFact fact={fact as Fact<AgentAuthorizationFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />;
  } else if (fact.type === 'monthly_debt') {
    return <MonthlyDebtFact fact={fact as Fact<MonthlyDebtFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'monthly_income') {
    return <MonthlyIncomeFact fact={fact as Fact<MonthlyIncomeFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'better_home_forced_manual_review') {
    return <ManualVerificationFact fact={fact} showEditButton={!!showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'extended_unsecured_debt') {
    return <ExtendedUnsecuredDebtFact fact={fact as Fact<ExtendedUnsecuredDebtFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'property_owned_by') {
    return <PropertyOwnedByFact fact={fact} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'property_listing_price') {
    return <PropertyListingPriceFact fact={fact as Fact<PropertyListingPriceFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'property_debt') {
    return <PropertyDebtFact fact={fact as Fact<PropertyDebtFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'property_listing_address') {
    return <PropertyListingAddressFact fact={fact as Fact<PropertyListingAddressFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'requested_principal') {
    return <RequestedPrincipalFact fact={fact as Fact<RequestedPrincipalFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else if (fact.type === 'fico') {
    return <FicoFact fact={fact as Fact<FicoFactData>} showEditButton={showEditButton} onUpdate={onUpdate} />
  } else {
    return <BaseFact fact={fact} />;
  }
}

function FactsTable ({ title, description, facts, allowFactsEdit, onUpdate }: { title: string, description?: string, facts: Array<Fact>, onUpdate: () => Promise<void>, allowFactsEdit?: boolean }) {
  return (
    <Card className='mb-5'>
      <Card.Header>
        {title}
      </Card.Header>
      <Card.Body>
        { description && <p className={style.description}>{description}</p> }
        <div className='pt-2 px-3'>
          <Row className='pb-3'>
            <Col sm={4} className='font-weight-bold pl-0'>Fact</Col>
            <Col sm={4} className='font-weight-bold'>Value</Col>
            <Col sm={4} className='font-weight-bold'>Verification Status</Col>
          </Row>
          { facts.map(fact => <FactRow key={fact.type} fact={fact} showEditButton={allowFactsEdit} onUpdate={onUpdate} />) }
        </div>
      </Card.Body>
    </Card>
  );
}

export default FactsTable;
