import useQuery from '../../shared/useQuery';
import { FLAG_STATUS } from './useUnderwritingFlags';

export type Fact<TData = unknown> = {
  _id: string;
  type: string;
  human_name: string;
  category: string;
  loan_application_id: string;
  value: string;
  verified: boolean;
  unverified_reasons: Array<string>;
  data: TData;
  flag_status: FLAG_STATUS;
  is_flag: boolean;
};

export default function useUnderwritingFacts (loanApplicationId?: string) {
  return useQuery<Array<Fact>>(loanApplicationId ? `/notebook/loan-applications/${ loanApplicationId }/underwriting-facts` : null);
}
